import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe define the size of the words in the topicloud component
 */

@Pipe({ name: 'size' })
export class SizePipe implements PipeTransform {
  constructor(
    public routing: RoutingService,
  ) { }
  transform(value: any, min: number, max: number) {
    const sizeFont = d3.scaleLinear().domain([min, max]).range([12, 20]);
    return sizeFont(value);
  }
}
