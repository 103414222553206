import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../service/routing.service';
import { YukkApi } from '../../service/yukkapi.service';
import { AuthService } from '../../service/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  hierarchy: boolean;
  entity: boolean;
  folios: any;
  params: any;
  user: any;
  listsignal: any;
  sentiment: any;
  promptEvent: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    private yukkApi: YukkApi,
    private auth: AuthService,
    public dialog: MatDialog,
  ) {

    // window.addEventListener('beforeinstallprompt', (e) => {
    //   e.preventDefault();
    //   this.promptEvent = e;
    // });

    this.route.queryParams.subscribe(params => {
      this.hierarchy = params.type === 'hierarchy';
      this.entity = params.type && params.type !== 'hierarchy';
      this.params = params;
    });

    this.folios = this.auth.portFolios();

    this.yukkApi.getSignalsList().subscribe(result => {
      this.listsignal = result.entities;
    });
    // this.yukkApi.index_etf().subscribe(result => { this.sentiment = result.entities; });

  }

  ngOnInit() {
  }

  installPwa() {
    // this.promptEvent.prompt();
  }

  logOut() {
    this.auth.authLogout();
  }

  // inParams(folio) {
  //   const entities = folio.content.length ? folio.content.join() : null;
  //   const params = {
  //     id: null,
  //     type: null,
  //     portfolio: folio.name,
  //     entities: entities
  //   };
  //   return params;
  // }

}
