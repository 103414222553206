import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-news-chartmap',
  templateUrl: './news-chartmap.component.html',
  styleUrls: ['./news-chartmap.component.scss']
})
export class NewsChartmapComponent implements OnInit {

  params: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

  }

  ngOnInit() {
  }

}
