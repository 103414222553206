<div class="news-alerts">
  <!--<ng-container *ngIf="$queryCombinedList | async;">-->
    <div class="alerts-side">
      <!--<img class="navlogo" src="assets/logo/{{project}}_menu.svg" alt="yukkalab"
        routerLink="/">-->
      <div class="side-header">Queries</div>
      <div class="side-main">
        <!--<ng-container *ngIf="$queryCombinedList | async;">-->
          <div class="side-alert" *ngFor="let item of queryCombinedList;" [class.active]="item.queryId===params.id">
            <div class="alert-activation">
              <mat-label>{{item.isActive ? 'Active' : 'Inactive'}}</mat-label>
              <mat-slide-toggle [(ngModel)]="item.isActive" (change)="setAlert(item)"></mat-slide-toggle>
            </div>
            <div class="pointer" routerLink="/cockpit/alerts" routerLinkActive="active" [queryParams]="{id: item.queryId, article: null, alert: null}" queryParamsHandling="merge" (click)="onQuerySelect(params.id, item)">
              <div class="txt">
              <span [matBadge]="item.howMany" [matBadgeHidden]="item.howMany === 0" matBadgePosition="before above" matBadgeOverlap="false">
                {{item.queryName}}
              </span>
              </div>
            </div>
          </div>
        <!--</ng-container>-->
      </div>
      <div class="side-acto">
        <button mat-raised-button (click)="onMarkAllAsSeen()">MARK ALL AS SEEN</button>
      </div>
    </div>
    <div class="alerts-main">
      <div class="alerts-main-header">Articles</div>
      <div class="alerts-main-container">
        <div class="alerts-main-article" *ngFor="let item of articlesList;" [class.active]="item.id===params.article">
          <div class="pointer" routerLink="/cockpit/alerts" routerLinkActive="active" [queryParams]="{article: item.id, alert: null}" queryParamsHandling="merge" (click)="onArticleSelect(params.article, item)">
            <div class="txt">
              <div class="title" [matTooltip]="item.title" matTooltipPosition="right">
                <span>{{item.title}}</span>
              </div>
              <div class="info">
                <span>{{item.timestamp|date:'dd.MM.yyyy HH:mm':'GMT'}} GMT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alerts-page">
      <div class="page-header">View</div>
      <div class="page-main">
        <app-news-view2 *ngIf="articleSelected && articleSelected.id" [apicall]="{apicall: articleSelected, match: null, tag: null}"></app-news-view2>
      </div>
    </div>
  <!--</ng-container>-->
</div>
