<mat-sidenav-container class="yukkacont" [class.fullscreen]="params.fullscreen">
  <mat-sidenav #sidenav mode="side" [opened]="params.sidenav" (closedStart)="closeSidenav()">
    <app-menu-account></app-menu-account>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="status" *ngIf="impersonate && auth.userdata.email" [style.background-color]="'blue'">
      <div class="message">{{'You are currently impersonating ' + auth.userdata.email}}</div>
    </div>
    <div class="status" *ngIf="status" [style.background-color]="(statusObject['background-colour']) ? statusObject['background-colour'] : 'transparent'">
      <div class="message">{{statusObject.message}}</div>
      <div class="action" (click)="closeStatus()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="yukkapp">
      <app-menu-above></app-menu-above>
      <div class="dashboard">
        <div class="dash-left" *ngIf="!routing.isStories()">
          <app-menu-aside></app-menu-aside>
        </div>
        <div class="dash-right">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-main-steps *ngIf="params.tutorial"></app-main-steps>
  </mat-sidenav-content>
</mat-sidenav-container>
