<div class="treemap-home">
  <nav mat-tab-nav-bar *ngIf="hierarchies.length>1">
    <a mat-tab-link *ngFor="let link of hierarchies" [routerLink]="[]"
      [queryParams]="{type:link.type,id:link.id}" queryParamsHandling="merge"
      routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
      <div class="tab-name">
        <span>{{link.label|isbeta}}</span>
      </div>
      <span class="tab-divider" [class.tab-divider-transparent]="(checkCurrentTabIndex(link, hierarchies|orderBy:'name'))"></span>
    </a>
  </nav>
  <div class="contipo">
    <app-board-entity *ngIf="params.type!='event'"></app-board-entity>
    <app-board-event *ngIf="params.type=='event'"></app-board-event>
  </div>
</div>
