<div class="admin-feedback">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon (click)="inDelete(element)">delete</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.project}} </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
      <td mat-cell *matCellDef="let element"> {{element.time|date}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
      <td mat-cell *matCellDef="let element"> {{element.message}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>