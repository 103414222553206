import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This pipe create a paragraph when it find \n in the text
 */

@Pipe({ name: 'paragraph' })
export class ParagraphPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) { }

  transform(text: string) {
    let textone = '';
    text.split('\n').forEach((element, index) => {
      textone += (index !== 0) ? '<br><br>' : '';
      textone += element;
    });
    return textone;
  }
}
