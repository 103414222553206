<div class="menu-search" *ngIf="auth.featureFlags.showSearchMenu">
  <div class="history" [class.mobile]="routing.isMobile()" *ngIf="auth.featureFlags.showNavigation">
    <button mat-button class="btnback" (click)="location.back()" matTooltip="Back" matTooltipPosition="above">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-button class="btnforw" (click)="location.forward()" matTooltip="Next" matTooltipPosition="above">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <div class="inpsearch" *ngIf="auth.featureFlags.showSearchBar" [class.mobile]="routing.isMobile()">
    <mat-icon class="iconsearch srcicon">search</mat-icon>
    <mat-form-field *ngIf="auth.featureFlags.showSearchBar" floatLabel="never">
      <!-- <mat-label>{{ 'MENU_ABOVE.SEARCH' | translate }}...</mat-label> -->
      <mat-label>{{label}}</mat-label>
      <input matInput #inputAutoComplete type="text" [formControl]="mysearch" [matAutocomplete]="menuSearch" (focus)="onFocus()" name="topSearchInput" autocomplete="off">
      <div class="iresult" *ngIf="!loading && mystart">{{(items$|async)?.entity_prefix_search.hits.length}} results
      </div>
      <mat-autocomplete #menuSearch="matAutocomplete" (optionSelected)="goSelect($event.option.value)" [autoActiveFirstOption]="true" class="filterSearch search-panel" panelWidth="auto">
        <div *ngIf="(!emptyInput || routing.isMobile() || routing.isIframe()) && items$|async as search">
          <div *ngFor="let items of search.entity_prefix_search?.hits | groupBy:'entity.type' | orderByType | pairs; let i = index;">
            <mat-optgroup *ngIf="(items[0] !== 'product') && (items[0] !== 'product_model') && (items[0] !== 'topic')">
              <div class="optgroup-search">
                <div class="indexo-left">{{items[0] | nicetxt}}</div>
                <div class="indexo-right">
                  <div class="optgroup-column column-0"></div>
                  <div class="optgroup-column column-1">Country</div>
                  <div class="optgroup-column"># Articles</div>
                </div>
              </div>
              <ng-container *ngFor="let option of items[1] | orderBy: '-count' | slice:0:(checkShowMoreEntities(i) ? 1000 : 5)">
                <mat-option *ngIf="!option.entity.hasParent" [value]="option">
                  <div class="search-indexo">
                    <div class="indexo-left">
                      <span>{{option.entity.name}}</span>
                    </div>
                    <div class="indexo-right">
                      <div class="optgroup-column column-0">
                        <div class="alert-container" *ngIf="auth.featureFlags.showSanctions && option.entity.is_sanctioned">
                          <mat-icon svgIcon="alert" matTooltip="Sanctioned Entity. See Entity Page for Details." [class.theme-light]="theme === 'light'"></mat-icon>
                          <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                        </div>
                      </div>
                      <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : '\u2014'}}</div>
                      <div class="optgroup-column">{{(option.count || (option.count === 0)) ? numberFormat(option.count) : ''}}</div>
                    </div>
                  </div>
                </mat-option>
                <mat-option *ngFor="let optionChild of option.entity.children | orderBy: '-count' | slice:0:(checkShowMoreEntities(i) ? 1000 : 5)" [value]="optionChild">
                  <div class="search-indexo">
                    <div class="indexo-left">
                      <span class="subsidiary">{{optionChild.entity.name}}</span>
                    </div>
                    <div class="indexo-right">
                      <div class="optgroup-column column-0">
                        <div class="alert-container" *ngIf="auth.featureFlags.showSanctions && optionChild.entity.is_sanctioned">
                          <mat-icon svgIcon="alert" matTooltip="Sanctioned Entity. See Entity Page for Details." [class.theme-light]="theme === 'light'"></mat-icon>
                          <span class="match">{{(optionChild.entity.matched_form) ? optionChild.entity.matched_form : ''}}</span>
                        </div>
                      </div>
                      <div class="optgroup-column column-1">{{(optionChild.entity && optionChild.entity.country && optionChild.entity.country.alpha_id) ? optionChild.entity.country.alpha_id.toUpperCase() : '\u2014'}}</div>
                      <div class="optgroup-column">{{(optionChild.count || (optionChild.count === 0)) ? numberFormat(optionChild.count) : ''}}</div>
                    </div>
                  </div>
                </mat-option>
              </ng-container>
              <mat-option class="expand-search-results" [disabled]="true" *ngIf="items[1] && (items[1].length > 5)">
                <mat-icon *ngIf="!checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">arrow_drop_down</mat-icon>
                <span *ngIf="!checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">Show more {{(items[0] | nicetxt:true).toLowerCase()}}</span>
                <mat-icon *ngIf="checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">arrow_drop_up</mat-icon>
                <span *ngIf="checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">Show less {{(items[0] | nicetxt:true).toLowerCase()}}</span>
              </mat-option>
            </mat-optgroup>
          </div>
          <mat-optgroup *ngIf="!loadingPNE && itemsPNE.length">
            <div class="optgroup-search">
              <div class="indexo-left">Other Potential Entity</div>
              <div class="indexo-right"></div>
            </div>
            <mat-option *ngFor="let option of itemsPNE | orderBy: '-count' | slice:0:checkShowMorePNEs()" [value]="{type:'pne',pne:option}">
              <div class="search-indexo">
                <div class="indexo-left">
                  <span>{{option.surface}}</span>
                  <span class="match">{{(option.type) ? option.type : ''}}</span>
                </div>
                <div class="indexo-right">
                  <div class="optgroup-column column-0"></div>
                  <div class="optgroup-column column-1"></div>
                  <div class="optgroup-column">{{(option.count || (option.count === 0)) ? numberFormat(option.count) : ''}}</div>
                </div>
              </div>
            </mat-option>
            <mat-option class="expand-search-results" [disabled]="true" *ngIf="itemsPNE && (itemsPNE.length > 5)">
              <mat-icon *ngIf="!showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">arrow_drop_down</mat-icon>
              <span *ngIf="!showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">Show more potential entities</span>
              <mat-icon *ngIf="showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">arrow_drop_up</mat-icon>
              <span *ngIf="showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">Show less potential entities</span>
            </mat-option>
          </mat-optgroup>
          <mat-optgroup *ngIf="loadingPNE">
            <div class="optgroup-search">
              <div class="indexo-left">Other Potential Entity</div>
              <div class="indexo-right" style="justify-content: end;">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </mat-optgroup>
          <mat-optgroup label="Can't find what you're looking for?" *ngIf="!routing.isMobile() && !routing.isIframe()">
            <mat-option *ngIf="mysearch.value==''" value="search" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search</mat-option>
            <mat-option *ngIf="mysearch.value!='' && !search.parse_boolean_query" [value]="{type:'searchValue',value:mysearch.value}" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search {{mysearch.value}}</mat-option>
            <mat-option *ngIf="mysearch.value!='' && search.parse_boolean_query" [value]="{type:'searchQuery',query:search.parse_boolean_query}" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search {{mysearch.value}}</mat-option>
            <mat-option *ngIf="mysearch.value=='' || search.entity_prefix_search?.hits.length!=0" value="request" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request New Entity</mat-option>
            <mat-option *ngIf="mysearch.value!='' && search.entity_prefix_search?.hits.length==0" [value]="{type:'ask4', value:mysearch.value}" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request Entity {{mysearch.value}}</mat-option>
          </mat-optgroup>
        </div>
        <mat-optgroup label="Can't find what you're looking for?" *ngIf="emptyInput && !routing.isMobile() && !routing.isIframe()">
          <mat-option value="search" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search</mat-option>
          <mat-option value="request" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request New Entity</mat-option>
        </mat-optgroup>
        <mat-optgroup label="Previous Entity Searches" *ngIf="emptyInput">
          <mat-option *ngFor="let option of previousSearches" [value]="option">
            <div class="search-indexo">
              <div class="indexo-left">
                <span>{{(option.entity) ? option.entity.name : ''}}</span>
              </div>
              <div class="indexo-right"></div>
            </div>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-spinner [diameter]="15" *ngIf="loading"></mat-spinner>
    </mat-form-field>
  </div>
  <div *ngIf="auth.featureFlags.showFilter && !(routing.isInvest() || routing.isHot() || routing.isChat())" class="filters" [class.mobile]="routing.isMobile()">
    <app-menu-filters></app-menu-filters>
  </div>
</div>
