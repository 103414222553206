import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * This is the menu of the admin area where you can create query search
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent {

  reports: any;
  query: any;
  reportType: any;
  databaseType: any;
  nresults: number;
  queryForm: any;
  newsid: any;
  startload: boolean;
  mydb: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.route.queryParams.subscribe(params => {

      this.databaseType = location.pathname.split('/')[2];

      if (params.type) {
        this.reportType = params.type.split(':')[2];
      } else {
        this.reportType = null;
      }
      if (params.query) {
        this.query = params.query.split(':');
        this.queryForm = this.fb.group({
          query0: ['report', Validators.required],
          reportType: [this.reportType],
          query1: [this.query[0], Validators.required],
          query2: [this.query[1], Validators.required],
          query3: [this.query[2], Validators.required]
        });
      } else {
        this.nresults = 0;
        this.queryForm = this.fb.group({
          query0: [this.databaseType, Validators.required],
          reportType: [this.reportType],
          query1: ['', Validators.required],
          query2: ['', Validators.required],
          query3: ['', Validators.required]
        });
      }
    });
  }

  goQuery() {
    if (this.queryForm.valid) {
      this.router.navigate([], {
        queryParams: {
          query: this.queryForm.controls['query1'].value + ':' +
            this.queryForm.controls['query2'].value + ':' +
            this.queryForm.controls['query3'].value
        }, queryParamsHandling: 'merge'
      });
    }
  }

  goDB(value) {
    this.databaseType = value;
    this.router.navigate([value], {
      queryParams: {
        query: null,
      },
      relativeTo: this.route
    });
  }

  selectReportType(type) {
    if (type === 'all') {
      this.router.navigate([], {
        queryParams: {
          type: null,
          query: null
        }, queryParamsHandling: 'merge'
      });
    } else if ((type === 'sentiment') || (type === 'entity') || (type === 'event') || (type === 'other')) {
      this.router.navigate([], {
        queryParams: {
          type: 'reportInfo.type:equal:' + type,
          query: null
        }, queryParamsHandling: 'merge'
      });
    }/* else {
      this.router.navigate([], {
        queryParams: {
          type: 'report:equal:' + type
        }, queryParamsHandling: 'merge'
      });
    }*/
  }


}
