import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent implements OnInit {

  scoreName: string;
  scoreDefinition: string;
  yAxisDefinition: string;

  constructor(
    public auth: AuthService,
  ) {
    const currentScore = this.auth.getScoreName();
    if (currentScore.name !== 'Sentiment') {
      if (currentScore.name === 'Risk') {
        this.scoreName = 'Risk Score';
        this.scoreDefinition = 'Yukka Lab’s default Risk Score which measures the riskiness of a company. The score is on a scale from 0 to 10 where 10 is very risky and 0 is not risky.';
        this.yAxisDefinition = 'risk';
      } else if (currentScore.name === 'ESG') {
        this.scoreName = 'ESG Score';
        this.scoreDefinition = 'Yukka Lab’s default ESG Score which measures a company’s ESG impact. The score is on a scale from 0 to 10 where 10 is a positive contribution to ESG and 0 is a negative contribution.';
        this.yAxisDefinition = 'ESG';
      } else {
        this.scoreName = currentScore.short + ' Score';
        this.scoreDefinition = 'The custom score created by you in the custom score set-up.';
        this.yAxisDefinition = 'custom';
      }
    } else {
      this.scoreName = 'Sentiment';
      this.scoreDefinition = 'Sentiment score based on the selected timeframe with the trend relative to the previous timeframe. The score is from 0-100% and the neutral score is the average of 50%.';
      this.yAxisDefinition = 'sentiment';
    }
  }

  ngOnInit() {
  }

}
