import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/service/auth.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-portfolio-share',
  templateUrl: './portfolio-share.component.html',
  styleUrls: ['./portfolio-share.component.scss']
})
export class PortfolioShareComponent implements OnInit {

  currentPortfolio: any;
  isActive: boolean;
  isMine: boolean;
  accessMode: string;

  email: string;

  users = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.isMine = false;
    this.isActive = false;
    this.accessMode = 'READ';
    this.currentPortfolio = JSON.parse(JSON.stringify(this.auth.folio));
    this.auth.getSharedPortfolios().subscribe(result => {
      result.forEach(item => {
        if (item.portfolio_id === this.currentPortfolio.uid) {
          this.isMine = true;
          this.isActive = item.active;
          this.email = item.email;
          this.accessMode = item.payload.mode;
        }
      });
      if (this.isMine) {
        this.auth.getSharedPortfolioUsers(this.currentPortfolio.uid).subscribe(result2 => {
          this.users = result2.users;
        });
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const commaSeparated = value.split(',');
      const semicolonSeparated = value.split(';');
      if (commaSeparated.length > 1) {
        commaSeparated.forEach(item => {
          const isValid = this.validateEmail(item.trim());
          if (isValid && (item.trim() !== this.email)) {
            this.users.push(item.trim());
          } else if (isValid && (item.trim() === this.email)) {
            alert('Provided email ' + this.email + ' is already added to the users list.');
          } else {
            alert('Error: provided email ' + item.trim() + ' is invalid.');
          }
        });
      } else if (semicolonSeparated.length > 1) {
        semicolonSeparated.forEach(item => {
          const isValid = this.validateEmail(item.trim());
          if (isValid && (item.trim() !== this.email)) {
            this.users.push(item.trim());
          } else if (isValid && (item.trim() === this.email)) {
            alert('Provided email ' + this.email + ' is already added to the users list.');
          } else {
            alert('Error: provided email ' + item.trim() + ' is invalid.');
          }
        });
      } else {
        const isValid = this.validateEmail(value.trim());
        if (isValid && (value.trim() !== this.email)) {
          this.users.push(value.trim());
        } else if (isValid && (value.trim() === this.email)) {
          alert('Provided email ' + this.email + ' is already added to the users list.');
        } else {
          alert('Error: provided email ' + value.trim() + ' is invalid.');
        }
      }
    }
    if (input) {
      input.value = '';
    }
  }

  remove(user): void {
    const index = this.users.indexOf(user);
    if (index >= 0) {
      this.users.splice(index, 1);
    }
    if (this.isMine) {
      this.auth.unsharePortfolio(this.currentPortfolio.uid, user).subscribe(() => {
        this.snackBar.open('Portfolio unshared from the user: ' + user + '.', 'OK', { duration: 5000 });
      });
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  close() {
    this.dialog.closeAll();
  }

  activationTooltip() {
    if (this.isActive) {
      return 'Deactivate sharing portfolio (temporarily stop sharing your portfolio with users listed below)';
    } else if (this.isMine) {
      return 'Activate sharing portfolio (start sharing again your portfolio with users listed below)';
    }
  }

  setActivation(checked) {
    this.isActive = checked;
    if (checked) {
      this.auth.activateSharedPortfolio(this.currentPortfolio.uid).subscribe(() => {
        this.snackBar.open('Portfolio sharing activated.', 'OK', { duration: 5000 });
      });
    } else {
      this.auth.deactivateSharedPortfolio(this.currentPortfolio.uid).subscribe(() => {
        this.snackBar.open('Portfolio sharing deactivated.', 'OK', { duration: 5000 });
      });
    }
  }

  onAccessModeChange(event) {
    if (this.isMine && event.value) {
      const mode = event.value.toLowerCase();
      this.auth.createSharedPortfolio(this.currentPortfolio.uid, mode).subscribe(() => {
        this.snackBar.open('Access mode changed successfully.', 'OK', { duration: 5000 });
      });
    }
  }

  isValid() {
    if (!(this.users.length > 0)) {
      alert('Error: users list is empty.');
      return false;
    }
    return true;
  }

  share() {
    if (this.isValid()) {
      if (!this.isMine) {
        let mode = 'read';
        if (this.accessMode) {
          mode = this.accessMode.toLowerCase();
        }
        this.auth.createSharedPortfolio(this.currentPortfolio.uid, mode).subscribe(() => {
          this.auth.sharePortfolio(this.currentPortfolio.uid, this.users).subscribe(() => {
            this.isMine = true;
            this.isActive = true;
            this.snackBar.open('Portfolio shared with provided list of users.', 'OK', { duration: 5000 });
          }, error => {
            this.snackBar.open('Portfolio cannot be shared with provided list of users.', 'OK', { duration: 5000, panelClass: ['red-snackbar'] });
          });
        });
      } else {
        this.auth.sharePortfolio(this.currentPortfolio.uid, this.users).subscribe(() => {
          this.snackBar.open('Portfolio shared with provided list of users.', 'OK', { duration: 5000 });
        }, error => {
          this.snackBar.open('Portfolio cannot be shared with provided list of users.', 'OK', { duration: 5000, panelClass: ['red-snackbar'] });
        });
      }
    }
  }

  delete() {
    this.auth.removeSharedPortfolio(this.currentPortfolio.uid).subscribe(() => {
      this.isMine = false;
      this.isActive = false;
      this.accessMode = 'READ';
      this.users = [];
      this.snackBar.open('Portfolio deleted from shared portfolios.', 'OK', { duration: 5000 });
    });
  }

}
