<div class="chartmap-legend">
  <div class="legend-block">
    <p>
      <b>Entity : </b>Name of the main entity and the type.
    </p>
  </div>
  <div class="legend-block">
    <p>
      <b>{{scoreName}} : </b>{{scoreDefinition}}
    </p>
  </div>
  <div class="legend-block">
    <div class="legend">
      <div class="label">Volume :</div>
      <div class="serie vol"></div>
    </div>
    <p>Volume of related articles based on timeframe.</p>
  </div>
  <div class="legend-block">
    <div class="legend">
      <div class="label">Sentiment :</div>
      <div class="serie ave"></div>
    </div>
    <p>Simple moving average based on timeframe.</p>
  </div>
  <div class="legend-block">
    <div class="legend">
      <div class="label">Price :</div>
      <div class="serie pri"></div>
    </div>
    <p>History of stock price data (end of day).</p>
  </div>
  <div class="legend-block">
    <p>
      <b>Y-axis : </b>The y-axis displays the {{yAxisDefinition}} score.
    </p>
  </div>
  <div class="legend-block">
    <p>
      <b>X-axis : </b>The x-axis display historical timeframe.
    </p>
  </div>
</div>
