import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigService } from 'src/app/service/config.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-steps-welcome',
  templateUrl: './steps-welcome.component.html',
  styleUrls: ['./steps-welcome.component.scss']
})
export class StepsWelcomeComponent {

  project: string;

  constructor(
    public config: ConfigService,
  ) {
    this.project = this.config.appConfig.routing.name;
  }

}
