import { PipeTransform, Pipe } from '@angular/core';

/**
 * This pipe order the search by company and ceo
 */

@Pipe({ name: 'orderByType' })
export class OrderByTypePipe implements PipeTransform {
  private readonly TYPES = ['company', 'ceo'];
  transform(value: any): any {
    if (!value) { return {}; }
    const result = {};
    this.TYPES.forEach(key => {
      if (value[key]) {
        result[key] = value[key];
      }
    });
    Object.assign(result, value);
    return result;
  }
}
