import { Injectable } from '@angular/core';

/**
 * This service helps newsletter's components to communicate with each other
 */
@Injectable()
export class NewsletterService {

  newsletter = {};
  newsletterParamsReady: boolean;

  constructor() { }
}
