import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { RoutingService } from '../../../../service/routing.service';
import { YukkApi } from '../../../../service/yukkapi.service';
import { AuthService } from '../../../../service/auth.service';
import { ConfigService } from '../../../../service/config.service';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicher',
  templateUrl: './datepicher.component.html',
  styleUrls: ['./datepicher.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatepicherComponent {

  minStartDate: any;
  maxStartDate: any;
  minEndDate: any;
  maxEndDate: any;

  date1 = new UntypedFormControl(new Date());
  date2 = new UntypedFormControl(new Date());
  serializedDate = new UntypedFormControl((new Date()).toISOString());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<DatepicherComponent>,
    private router: Router,
    public routing: RoutingService,
    // private yukkapi: YukkApi,
    public auth: AuthService,
    public config: ConfigService
  ) {
    this.minStartDate = new Date(this.config.appConfig.setup.startDate);
    this.maxStartDate = new Date();
    this.minEndDate = new Date(this.config.appConfig.setup.startDate);
    this.maxEndDate = new Date();
    if (isNaN(this.data.time)) {
      const date = this.data.time.split('--');
      this.date1 = new UntypedFormControl(new Date(date[0]));
      this.date2 = new UntypedFormControl(new Date(date[1]));
    } else {
      const date1 = moment().subtract(this.data.time, 'days').format('YYYY-MM-DD');
      this.date1 = new UntypedFormControl(new Date(date1));
    }
  }

  goDate() {
    const startDate = moment(this.config.appConfig.setup.startDate).format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const maxDays = this.auth.internalCockpitSettings.maxTimeframe;
    const date1 = moment(this.date1.value).format('YYYY-MM-DD');
    const date2 = moment(this.date2.value).format('YYYY-MM-DD');
    const days = moment(this.date2.value).diff(moment(this.date1.value), 'days');
    if (date1 > date2) {
      alert('The start date must come before the end date.');
    } else if (date1 < startDate) {
      alert('The min start date is ' + startDate + ' .');
    } else if (date2 > endDate) {
      alert('The max end date is ' + endDate + ' .');
    } else if ((!this.routing.isDev()) && (days > maxDays)) {
      alert('The date range cannot exceed ' + maxDays + ' days.');
    } else {
      const time = date1 + '--' + date2;
      const period = (days > 30) ? '14' : '7';
      this.dialogRef.close({
        time: time,
        period: period
      });
    }
  }

  inDate1() {
    const date1 = moment(this.date1.value).format('YYYY-MM-DD');
    const time1 = moment(this.config.appConfig.setup.startDate).format('YYYY-MM-DD');
    if (date1 < time1) {
      this.date1.setValue(this.config.appConfig.setup.startDate);
    }
  }

}
