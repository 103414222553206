<div class="query-import">
  <mat-form-field appearance="outline" class="collection-name">
    <mat-label>Collection</mat-label>
    <input matInput [value]="data.collection.name" readonly>
  </mat-form-field>
  <form class="query-search-form" (click)="$event.stopPropagation()">
    <mat-form-field class="query-search-field">
      <input type="text"
             matInput
             placeholder="Search..."
             [formControl]="querySearchForm"
             [matAutocomplete]="autoQuerySearch">
      <mat-autocomplete class="distinct-panel" #autoQuerySearch="matAutocomplete" [displayWith]="displayQuery">
        <mat-option *ngFor="let queryOption of filteredQueryList|async|orderBy:'label'" [value]="queryOption" (click)="handleSearch(queryOption)">
          {{queryOption.label}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <div mat-dialog-content>
    <div class="list-container">
      <mat-selection-list disableRipple="true" class="listQueries" [formControl]="queryForm">
        <ng-container *ngFor="let query of queryList|orderBy:'label'">
          <mat-list-option *ngIf="query.selected" [class.active]="query.selected" [value]="query.value" (click)="$event.stopPropagation(); handleSelection(query);">
            <div class="query-text">
              <span>{{query.label}}</span>
            </div>
          </mat-list-option>
        </ng-container>
        <ng-container *ngFor="let query of queryList|orderBy:'label'">
          <mat-list-option *ngIf="!query.selected" [class.active]="query.selected" [value]="query.value" (click)="$event.stopPropagation(); handleSelection(query);">
            <div class="query-text">
              <span>{{query.label}}</span>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" class="btnsave" (click)="iImport()" [disabled]="!queryForm.value || (queryForm.value.length === 0)">
      <span>import</span>
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
</div>
