<div class="homepage">
    <div class="homepage-container" *ngIf="auth.folio">
        <app-homepage-main></app-homepage-main>
    </div>
    <div class="homepage-message" *ngIf="auth.folio === undefined">
        <p>CREATE A PORTFOLIO TO START SEEING YOUR INSIGHTS</p>
        <button mat-stroked-button (click)="openPortfolioBoard()">CREATE PORTFOLIO</button>
    </div>
    <div class="loading" *ngIf="auth.folio === null">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</div>
