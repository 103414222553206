import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QueryDialogComponent } from '../query-dialog.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query2-group',
  templateUrl: './query2-group.component.html',
  styleUrls: ['./query2-group.component.scss']
})
export class Query2GroupComponent implements OnInit, OnChanges {

  groupForm: UntypedFormGroup;
  @Input('data') data: any;

  constructor(
    private query: QueryDialogComponent,
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.groupForm = this.data.form;
  }

  addGroup() {
    this.query.addGroup();
  }

  duplicateGroup() {
    this.query.duplicateGroup(this.data.index);
  }

  delGroup() {
    this.query.delGroup(this.data.index);
  }

  addRule() {
    this.query.addRule(this.data.index, this.data.level);
  }

  delRule(index) {
    if (this.data.form.controls.filters.controls.length === 1 && this.data.level === 1) {
      this.delGroup();
    } else {
      if (this.data.form.controls.filters.controls.length === 2 && this.data.level === 1) {
        this.groupForm.controls.operator.setValue('and');
        if (this.data.form.get('filters')['controls'][0].status === 'INVALID') {
          this.data.form.get('filters')['controls'][0].controls.field.setValue('text');
        }
        if (this.data.form.get('filters')['controls'][1].status === 'INVALID') {
          this.data.form.get('filters')['controls'][1].controls.field.setValue('text');
        }
      }
      this.query.delRule(this.data.level, this.data.index, index);
    }
  }

  inOperator(value) {
    this.groupForm.controls.operator.setValue(value);
    if (value === 'near') {
      this.data.form.get('filters')['controls'].forEach((rule, index) => {
        this.data.form.get('filters')['controls'][index].controls.field.setValue('word');
        const nearInput = this.data.form.get('filters')['controls'][index].controls.input.value.trim();
        this.data.form.get('filters')['controls'][index].controls.name.setValue(nearInput);
        this.data.form.get('filters')['controls'][index].controls.value.setValue(nearInput);
      });
    }
  }

  inOperatorNear(value) {
    this.groupForm.controls.operator.setValue(value);
    this.data.form.get('filters')['controls'].forEach((rule, index) => {
      this.data.form.get('filters')['controls'][index].controls.negated.setValue(false);
      this.data.form.get('filters')['controls'][index].controls.field.setValue('text');
      const nearInput = this.data.form.get('filters')['controls'][index].controls.input.value.trim();
      this.data.form.get('filters')['controls'][index].controls.name.setValue(nearInput);
      this.data.form.get('filters')['controls'][index].controls.value.setValue(nearInput);
    });
  }

  inReset(value) {
    // this.gopera = value
  }

  showOperator() {
    if (this.data.level === 0) {
      if (this.data.form.controls.groups.controls.length > 1) {
        return true;
      }
    }
    if (this.data.level === 1) {
      if (this.data.form.controls.filters.controls.length > 1) {
        return true;
      }
    }
    return false;
  }

}
