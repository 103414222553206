import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { tap, map, take } from 'rxjs/operators';
/**
* this guard check if you subscribe to the trend area
*/
@Injectable()
export class TrendGuard  {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.auth.authToken();
    if (user?.groups?.includes('SUBSCRIPTION_ALL') || user?.roles?.includes('SUBSCRIPTION_ALL') || user?.groups?.includes('SUBSCRIPTION_TREND') || user?.roles?.includes('SUBSCRIPTION_TREND')) {
      return true;
    } else {
      this.router.navigate(['/cockpit/news/board']);
    }
  }
}
