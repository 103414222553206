<div class="query-name-cont">
  <form class="query-name" *ngIf="!data || (data && (data.action !== 'create') && (data.action !== 'add'))" (ngSubmit)="iSave()">
    <mat-form-field appearance="outline">
      <mat-label>Collection name</mat-label>
      <input matInput [formControl]="namefolio" autocomplete="off">
      <mat-error *ngIf="namefolio.invalid">Min length 3</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Save</button>
  </form>

  <div class="query-name" *ngIf="data && ((data.action === 'create') || (data.action === 'add'))">

  </div>

  <div class="query-name" *ngIf="data && (data.action === 'create')">
    <mat-form-field appearance="outline">
      <mat-label>Query name</mat-label>
      <input matInput [formControl]="namequery" autocomplete="off">
      <mat-error *ngIf="namequery.invalid">Min length 3</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Collection name</mat-label>
      <input matInput [formControl]="namefolio" autocomplete="off">
      <mat-error *ngIf="namefolio.invalid">Min length 3</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="iCreate(false)">Create</button>
    <button mat-raised-button color="primary" (click)="iCreate(true)">Open</button>
  </div>

  <div class="query-name" *ngIf="data && (data.action === 'add')">

    <mat-form-field appearance="outline">
      <mat-label>Query name</mat-label>
      <input matInput [formControl]="namequery" autocomplete="off">
      <mat-error *ngIf="namequery.invalid">Min length 3</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Collection</mat-label>
      <mat-select [(ngModel)]="selectedCollections" multiple>
        <mat-option *ngFor="let collection of collectionsList" [value]="collection.uid" [disabled]="collection.content.length >= auth.featureFlags.queriesPerCollectionsLimit">
          {{collection.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="iAdd(false)">Add</button>
    <button mat-raised-button color="primary" *ngIf="selectedCollections.length < 2" (click)="iAdd(true)">Open</button>

  </div>

  <button mat-raised-button color="warn" (click)="iDelete()" *ngIf="data && (data.action !== 'create') && (data.action !== 'add')">Delete</button>
</div>
<div class="delete-queries" *ngIf="data && (data.action !== 'create') && (data.action !== 'add')">
  <mat-checkbox [(ngModel)]="deleteQueries">Delete All Queries</mat-checkbox>
</div>
