<h1 mat-dialog-title class="sanctions-title">{{data.title}}</h1>
<div mat-dialog-content>
    <table mat-table [dataSource]="data.table" class="demo-table">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef>
                {{column.header}}
            </th>
            <td mat-cell *matCellDef="let row" [class.highlight-entity]="(currentEntity === row.authority) && (column.columnDef === 'authority')">
                {{column.cell(row)}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" (mouseenter)="onMouseEnterName(row.authority)" (mouseleave)="onMouseLeaveName()" (click)="$event.stopPropagation(); navEntity(row.authority);"></tr>
    </table>
</div>
