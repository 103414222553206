import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ConfigService } from '../service/config.service';

@Injectable()
export class LoginRedirectGuard  {
  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    // const params = state.root.queryParams;
    if (this.config.appConfig.urlLoginRedirect) {
      const paramsString = state.url.split('?')[1];
      window.location.href = this.config.appConfig.urlLoginRedirect + '?' + paramsString;
      return false;
    } else {
      return true;
    }
  }
}
