/**
 * mother company data
 */

export const motherCompany = {
  entity: {
    type: 'company',
    alpha_id: 'vitesco_technologies_group_aktiengesellschaft',
    name: 'Vitesco Technologies Group AG',
    shortName: 'Vitesco',
    compound_key: 'company:vitesco_technologies_group_aktiengesellschaft'
  },
  num_employees: 40000,
  countries: ['Germany'],
  cities: ['Regensburg'],
  industries: ['Industrials'],
  supersectors: ['Automobiles & Parts'],
  sectors: ['Industrial Engineering', 'Industrial Transportation'],
  subsectors: [],
  persons: ['Andreas Wolf']
};

/**
 * suppliers data
 */

export const suppliers = [
  {
    entity: {
      type: 'company',
      alpha_id: 'stx_group_bv',
      name: 'STX Group BV',
      shortName: 'STX',
      compound_key: 'company:stx_group_bv'
    },
    num_employees: 350,
    supplied_products: ['sustainability certificate'],
    countries: ['Netherlands'],
    cities: ['Amsterdam'],
    industries: ['Financials'],
    supersectors: ['Financial Services'],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'on_semiconductor_corp',
      name: 'ON Semiconductor Corporation',
      shortName: 'ON',
      compound_key: 'company:on_semiconductor_corp'
    },
    num_employees: 35000,
    supplied_products: ['semiconductor'],
    countries: ['United States of America'],
    cities: ['Scottsdale'],
    industries: ['Technology'],
    supersectors: ['Technology'],
    sectors: ['Technology Hardware & Equipment'],
    subsectors: ['Semiconductors'],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'asimco_sealing_technologies',
      name: 'ASIMCO Sealing Technologies',
      shortName: 'ASIMCO',
      compound_key: 'company:asimco_sealing_technologies'
    },
    num_employees: 1800,
    supplied_products: ['rubber seal'],
    countries: ['China'],
    cities: ['Nanjing'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'baud_industries',
      name: 'Baud Industries Services SA',
      shortName: 'Baud',
      compound_key: 'company:baud_industries'
    },
    num_employees: 700,
    supplied_products: ['turned part'],
    countries: ['Switzerland'],
    cities: ['Thônex'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'lth_castings',
      name: 'LTH Castings doo',
      shortName: 'LTH Castings',
      compound_key: 'company:lth_castings'
    },
    num_employees: 400,
    supplied_products: ['die casting'],
    countries: ['Slovenia'],
    cities: ['Skofja Loka'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'ningbo_yunsheng_co_ltd',
      name: 'Ningbo Yunsheng Co Ltd',
      shortName: 'Ningbo Yunsheng',
      compound_key: 'company:ningbo_yunsheng_co_ltd'
    },
    num_employees: 3000,
    supplied_products: ['magnet'],
    countries: ['China'],
    cities: ['Ningbo'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'rohm_co_ltd',
      name: 'Rohm Co Ltd',
      shortName: 'Rohm',
      compound_key: 'company:rohm_co_ltd'
    },
    num_employees: 23000,
    supplied_products: ['semiconductor'],
    countries: ['Japan'],
    cities: ['Kyoto'],
    industries: ['Technology'],
    supersectors: ['Technology'],
    sectors: ['Technology Hardware & Equipment'],
    subsectors: ['Semiconductors'],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'ruhlamat_gmbh',
      name: 'ruhlamat GmbH',
      shortName: 'ruhlamat',
      compound_key: 'company:ruhlamat_gmbh'
    },
    num_employees: 300,
    supplied_products: ['customized machinery'],
    countries: ['Germany'],
    cities: ['Gerstungen'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'tdk_electronics_ag_324449461',
      name: 'TDK Electronics AG',
      shortName: 'TDK Electronics',
      compound_key: 'company:tdk_electronics_ag_324449461'
    },
    num_employees: 23600,
    supplied_products: ['sensor'],
    countries: ['Germany'],
    cities: ['Munich'],
    industries: ['Industrials'],
    supersectors: ['Industrial Goods & Services'],
    sectors: ['Electronic & Electrical Equipment'],
    subsectors: [],
    persons: ['Joachim Zichlarz']
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'texas_instruments',
      name: 'Texas Instruments Incorporated',
      shortName: 'Texas Instruments',
      compound_key: 'company:texas_instruments'
    },
    num_employees: 33000,
    supplied_products: ['semiconductor'],
    countries: ['United States of America'],
    cities: ['Dallas'],
    industries: ['Technology'],
    supersectors: ['Technology'],
    sectors: ['Technology Hardware & Equipment'],
    subsectors: ['Semiconductors'],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'volaplast_gmbh___co__kg',
      name: 'VolaPlast GmbH & Co KG',
      shortName: 'VolaPlast',
      compound_key: 'company:volaplast_gmbh___co__kg'
    },
    num_employees: 250,
    supplied_products: ['plastic component'],
    countries: ['Germany'],
    cities: ['Spangenberg'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'wus_printed_circuit__kushan__company_ltd',
      name: 'WUS Printed Circuit (Kunshan) Co Ltd',
      shortName: 'WUS Printed Circuit',
      compound_key: 'company:wus_printed_circuit__kushan__company_ltd'
    },
    num_employees: 450,
    supplied_products: ['circut board'],
    countries: ['China'],
    cities: ['Kunshan'],
    industries: ['Technology'],
    supersectors: ['Technology'],
    sectors: ['Technology Hardware & Equipment'],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'mansfield_manufacturing_co_ltd',
      name: 'Mansfield Manufacturing Co Ltd',
      shortName: 'Mansfield Manufacturing',
      compound_key: 'company:mansfield_manufacturing_co_ltd'
    },
    num_employees: 12,
    supplied_products: ['stamped part'],
    countries: ['Hong Kong'],
    cities: ['Hong Kong'],
    industries: [],
    supersectors: [],
    sectors: [],
    subsectors: [],
    persons: []
  },
  {
    entity: {
      type: 'company',
      alpha_id: 'infineon',
      name: 'Infineon Technologies AG',
      shortName: 'Infineon',
      compound_key: 'company:infineon'
    },
    num_employees: 56000,
    supplied_products: ['semiconductor'],
    countries: ['Germany'],
    cities: ['Munich', 'Neubiberg'],
    industries: ['Technology', 'Industrials'],
    supersectors: ['Technology'],
    sectors: ['Technology Hardware & Equipment', 'Electronic & Electrical Equipment'],
    subsectors: ['Semiconductors'],
    persons: ['Reinhard Ploss', 'Ulrich Schumacher']
  }
];
