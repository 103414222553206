import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
/**
* check if your subscribtion is NEWS or ALL
*/
@Injectable()
export class NewsGuard  {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.auth.authToken();
    if (user?.groups?.includes('SUBSCRIPTION_ALL') || user?.roles?.includes('SUBSCRIPTION_ALL') || user?.groups?.includes('SUBSCRIPTION_NEWS') || user?.roles?.includes('SUBSCRIPTION_NEWS')) {
      return true;
    } else {
      this.router.navigate(['/cockpit/trend/secsignal/stoxx_europe_600_auto_parts_trn']);
    }
  }
}
