<div class="flags-main">
    <div class="flags-list">
        <div class="flags-list-header">
            <div class="flags-list-title">Flags</div>
            <div class="flags-list-info">
                Setup flags to be displayed in the tool if scores meet a certain criteria. Please note only one flag can be active at a time.
            </div>
        </div>
        <div class="flags-list-container">
            <div class="list-container-contents">
                <div class="custom-flags">
                    <div class="option" [class.active]="auth.scorelabSettings.defaultFlag && (auth.scorelabSettings.defaultFlag.uid === flag.uid)" *ngFor="let flag of customFlags">
                        <mat-icon *ngIf="!auth.scorelabSettings.defaultFlag || (auth.scorelabSettings.defaultFlag.uid !== flag.uid)" (click)="setAsDefault(flag)" matTooltip="Set as Tool’s Default Flag">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultFlag && (auth.scorelabSettings.defaultFlag.uid === flag.uid)" (click)="unsetDefault(flag)" matTooltip="Unset Tool’s Default Flag">check_circle_outlined</mat-icon>
                        <div class="flag" [class.current]="currentFlag.uid === flag.uid" (click)="selectFlag(flag)">{{flag.name}}</div>
                    </div>
                </div>
            </div>
            <div class="list-container-actions">
                <div class="option" (click)="createFlag()">
                    <mat-icon>add_circle_outline</mat-icon>
                    <div class="flag" [class.current]="currentFlag && (currentFlag.uid === 'new')">Create Flag</div>
                </div>
            </div>
        </div>
    </div>
    <div class="flags-settings">
        <div class="flags-settings-header">
            <div class="flags-settings-title">
                <div class="title-contents">
                    <div class="title-dyn" [class.notitle]="notitle">
                        <input matInput placeholder="Create flag's name *" required [(ngModel)]="currentFlag.name">
                    </div>
                </div>
                <div class="title-actions">
                    <mat-icon matTooltip="Close" matTooltipPosition="below" (click)="iClose()">close</mat-icon>
                </div>
            </div>
        </div>
        <div class="flags-settings-container">
            <div class="settings-container-contents">

                <mat-form-field appearance="outline" class="field-type">
                    <mat-label>Entity Type</mat-label>
                    <mat-select #selectorFlagEntityType [(ngModel)]="currentFlag.entity_type" multiple required>
                        <mat-option value="pne">Potential Named Entity</mat-option>
                        <mat-option *ngFor="let entity of entityTypes" [value]="entity.alpha_id">{{entity.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-type">
                    <mat-label>Score Type</mat-label>
                    <mat-select #selectorFlagScoreType [(ngModel)]="currentFlag.score_type" (selectionChange)="onScoreTypeChange()" required>
                        <mat-option *ngFor="let score of scoreTypes" [value]="score.value">{{score.label}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="threshold-container">

                    <div class="field-threshold text">Reached Threshold:</div>

                    <mat-form-field class="field-threshold setting">
                        <mat-select #selectorFlagThresholdSetting [(ngModel)]="currentFlag.threshold_setting" required>
                            <mat-option value="ABOVE_EQUAL">&#8805;</mat-option>
                            <mat-option value="BELOW_EQUAL">&#8804;</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="field-threshold value" *ngIf="((currentFlag.score_type !== 'sentiment') && (currentFlag.score_type !== 'volume'))">
                        <input #inputFlagThreshold type="number"
                               maxlength="2"
                               min="0"
                               max="10"
                               required
                               matInput
                               [(ngModel)]="currentFlag.threshold"
                               (change)= "currentFlag.threshold < 0 ? currentFlag.threshold = 0 : currentFlag.threshold; currentFlag.threshold > 10 ? currentFlag.threshold = 0 : currentFlag.threshold;"
                               name="threshold">
                        <mat-error>Please enter a value between 0 and 10</mat-error>
                    </mat-form-field>

                    <mat-form-field class="field-threshold value" *ngIf="currentFlag.score_type === 'sentiment'">
                        <input #inputFlagThreshold type="number"
                               maxlength="3"
                               min="0"
                               max="100"
                               required
                               matInput
                               [(ngModel)]="currentFlag.threshold"
                               (change)= "currentFlag.threshold < 0 ? currentFlag.threshold = 0 : currentFlag.threshold; currentFlag.threshold > 100 ? currentFlag.threshold = 0 : currentFlag.threshold;"
                               name="threshold">
                        <mat-error>Please enter a value between 0 and 100</mat-error>
                    </mat-form-field>

                    <!--<mat-form-field class="field-threshold value" *ngIf="currentFlag.score_type === 'volume'">
                        <input #inputFlagThreshold type="number"
                               min="0"
                               step="1"
                               required
                               matInput
                               [(ngModel)]="currentFlag.threshold"
                               (change)= "currentFlag.threshold < 0 ? currentFlag.threshold = 0 : currentFlag.threshold;"
                               name="threshold">
                        <mat-error>Please enter an integer value</mat-error>
                    </mat-form-field>-->

                </div>

            </div>

        </div>
        <div class="flags-settings-footer">
            <button class="delete" mat-button *ngIf="currentFlag.uid && (currentFlag.uid !== 'new')" (click)="deleteFlag()" [class.delete-warning]="isButtonActive" (mouseover)="isButtonActive = true" (mouseout)="isButtonActive = false">
                <mat-icon>delete</mat-icon>
                <span>DELETE</span>
            </button>
            <button class="save" mat-button (click)="saveFlag()">
                <mat-icon>save</mat-icon>
                <span>SAVE</span>
            </button>
        </div>
    </div>
</div>
