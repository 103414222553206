<div class="portfolio-name-cont">

  <div class="portfolio-type" *ngIf="data.action === 'new'">
    <mat-radio-group [(ngModel)]="portfolioType">
      <mat-radio-button value="regular">Regular</mat-radio-button>
      <mat-radio-button value="aggregated">Aggregated</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="portfolio-name">

    <mat-form-field appearance="outline" *ngIf="(data.action !== 'add')">
      <mat-label>Portfolio name</mat-label>
      <input matInput [formControl]="namefolio" autocomplete="off">
      <mat-error *ngIf="namefolio.invalid">Min length 3</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="(data.action === 'add')">
      <mat-label>Portfolio</mat-label>
      <mat-select [(ngModel)]="selectedPortfolios" multiple required>
        <mat-option *ngFor="let portfolio of portfoliosList" [value]="portfolio.uid" [disabled]="portfolio.is_aggregated || (portfolio.sharing_mode === 'read')">
          {{portfolio.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button *ngIf="auth.folio && !auth.folio.sharing_mode && (data.action == 'edit')" color="primary" (click)="iShare()">Share</button>

    <ng-container *ngIf="(data.action !== 'create') && (data.action !== 'add') && (idelete==0)">
      <button mat-raised-button color="primary" (click)="iSave()">Save</button>
      <button mat-raised-button color="warn" (click)="iDelete()" *ngIf="data.action=='edit' && !((auth.folio && auth.folio.sharing_mode === 'read') || (auth.folio && auth.folio.sharing_mode === 'write'))">Delete</button>
    </ng-container>

    <ng-container *ngIf="(data.action !== 'create') && (data.action !== 'add') && (idelete==1)">
      <button mat-raised-button color="primary" (click)="idelete=0">Abort</button>
      <button mat-raised-button color="warn" (click)="delete()">Confirm</button>
    </ng-container>

    <ng-container *ngIf="(data.action === 'create')">
      <button mat-raised-button color="primary" (click)="iCreate(false)">Create</button>
      <button mat-raised-button color="primary" (click)="iCreate(true)">Open</button>
    </ng-container>

    <ng-container *ngIf="(data.action === 'add')">
      <button mat-raised-button color="primary" (click)="iAdd(false)">Add</button>
      <button mat-raised-button color="primary" *ngIf="selectedPortfolios.length < 2" (click)="iAdd(true)">Open</button>
    </ng-container>

  </div>

  <ng-container *ngIf="(data.action !== 'create') && (data.action !== 'add') && (idelete==1)">
    <div class="portfolio-message">
      <span>
        The newsletter {{newsletterTitle}} is based on this portfolio. You won't receive that newsletter any more.
      </span>
    </div>
  </ng-container>

</div>
