export const steps = [


  // ASIDE -----------------------------------------

  {
    urlo: '/cockpit/news/market',
    myid: 'menuaside',
    icon: '',
    tito: 'Menu',
    desk: 'On the left hand side, you can see the main menu. Use the arrow icon to extend the menu bar. This menu bar contains the three elements of the Cockpit.',
    menu: [
      {
        icon: '',
        tito: 'News',
        desk: 'News Lab aggregates, structures and evaluates the newsflow by entities. Thereby providing an overview of the sentiments trends, topics and articles. There are four main boards:',
        menu: [
          {
            icon: 'collections_bookmark',
            tito: 'Portfolio',
            desk: '"Portfolio” allows you to create customized Boards with entities relevant to you.'
          },
          {
            icon: 'view_module',
            tito: 'Collection',
            desk: '“Collection” allows you to leverage Boolean Query to create advanced boards with specific criteria and user-defined entities.'
          },
          {
            icon: 'public',
            tito: 'Market',
            desk: '“Market” provides an overview structured by country and indices as well as by industries and sectors.'
          },
          {
            icon: 'whatshot',
            tito: 'Trending',
            desk: '“Trending” outlines the major movers based on sentiment and news volume out of all entities tracked.'
          },
        ]
      },
      {
        icon: '',
        tito: 'Trend',
        desk: 'Trend Lab is an early warning system based on the aggregated sentiment. Based on a sector model with 19 sectors you can chose between different overlay strategies to get an equity investment ratio based on the sentiment development for the sector´s allowing you to get a neutral sparrings partner on the market development and to improve your timing on the bigger market cycles.',
        menu: [
          {
            icon: 'timeline',
            tito: 'Signals',
            desk: 'The Signals Tab provides an overview of the sentiment status of the 19 different sectors, which can be either positive, positive with a warning or negative.'
          },
          {
            icon: 'graphic_eq',
            tito: 'Indicators',
            desk: 'The Indicators Tab shows the performance of the four different sentiment overlay strategies versus different benchmarks. Based on the sector secsignal four different sentiment overlay strategies have been formulated: Low Risk, Balanced Risk, Optimised Risk & Return and Binary. You can chose on of the overlay strategies as well as your preferred benchmark.'
          },
        ]
      },
      {
        icon: '',
        tito: 'User',
        desk: 'User related settings can be set up and tutorial´s help is provided here.',
        menu: [
          {
            icon: 'person',
            tito: 'Account',
            desk: 'Here, you can find all information about your account like email, subscription and the logout function.',
            menu: [
              {
                icon: 'account_circle',
                tito: 'Email',
                desk: 'Logged in user´s email.'
              },
              {
                icon: 'feedback',
                tito: 'Feedback',
                desk: 'We look forward to receiving your feedback on our services.'
              },
              {
                icon: 'help',
                tito: 'Tutorial',
                desk: 'The entire tutorial can be accessed at any time to help you better understand the diverse features of YUKKA Lab´s News and Trend Lab.'
              },
              // {
              //   icon: 'settings',
              //   tito: 'Admin',
              //   desk: ''
              // },
              {
                icon: 'account_box',
                tito: 'Password',
                desk: 'You can change your password here.',
              },
              {
                icon: 'exit_to_app',
                tito: 'Logout',
                desk: '',
              },
            ]
          },
          {
            icon: 'email',
            tito: 'Newsletter',
            desk: ''
          },
          {
            icon: 'notifications',
            tito: 'Notifications',
            desk: ''
          },
          {
            icon: 'help',
            tito: 'Tutorial',
            desk: 'Tutorial for the current page can be accessed through this shortcut button. The full tutorial is also always accessible from the user´s account menu.'
          }
        ]
      },
    ]
  },

  // MENU -----------------------------------------

  {
    urlo: '/cockpit/news/market',
    myid: 'menuabove',
    icon: '',
    tito: 'Search & Navigation',
    desk: 'Search, filter and general navigation functions are arranged at the top.',
    menu: [
      {
        icon: 'settings_ethernet',
        tito: 'View Options',
        desk: 'Back/Forward Navigation & Fullscreen Mode for quick access of previous views through the back and forward arrow buttons as well as the fullscreen mode through the enlarged view button.',
        menu: [
          // {
          //   icon: 'invert_colors',
          //   tito: 'Theme',
          //   desk: 'Choose from two theme dark or light and change the color scheme of the sentiment',
          // },
          {
            icon: 'fullscreen',
            tito: 'Fullscreen',
            desk: 'Click the icon to switch between full screen and normal modes.',
          },
          {
            icon: 'compare_arrows',
            tito: 'Navigation',
            desk: 'With the back and forward arrows you can navigate through the history of your previous views.',
          },
        ]
      },
      {
        icon: 'filter_list',
        tito: 'Data Filter',
        desk: 'Data filter allows you to prepare a curated content panel that is applied throughout the cockpit, in all news based and analytical views. The selected data filter options will always be visible in the filter bar. Filter options can also be saved as custom presets to allow for quick switching between content lenses. The filters options currently include:',
        menu: [
          {
            icon: '',
            tito: 'Presets',
            desk: 'Save your data filter into specific presets that can be applied through one click.'
          },
          {
            icon: '',
            tito: 'Timeframe',
            desk: 'Filter data by last week, month, quarter or custom range.',
          },
          {
            icon: '',
            tito: 'Sentiment',
            desk: 'Filter data by overall negative, positive or neutral sentiment.'
          },
          {
            icon: '',
            tito: 'Language',
            desk: 'Filter by language: English, German or both.'
          },
          {
            icon: '',
            tito: 'Source Rank',
            desk: 'Filter by source ranking. Individual sources are ranked based on quality of feed and coverage ranging from the highest quality global sources at Rank 1 to lower quality regional sources at Rank 5.'
          },
          {
            icon: '',
            tito: 'News Category',
            desk: 'Filter data by document type(s), broadly classified into 13 types based on the primary theme of the article.'
          },
          {
            icon: '',
            tito: 'Geography',
            desk: 'Filter data by selecting the country or continent from which the news source producing the article is based.'
          },
          {
            icon: '',
            tito: 'Events',
            desk: 'Filter data based on the mentions of specific types of events in the news articles.'
          },
          {
            icon: '',
            tito: 'Feed Type',
            desk: ''
          },
        ]
      },
      {
        icon: 'search',
        tito: 'Search',
        desk: 'Search by Name or ISIN for entities of interest. As soon as you start typing the system will suggest matching entities by category (company, index, sector, industry, product model, person, topic or source).',
        menu: [
          // {
          //   icon: 'search',
          //   tito: 'Normal',
          //   desk: '',
          // },
          {
            icon: 'playlist_add',
            tito: 'Advanced Search',
            desk: 'Advanced Search allows you to build boolean queries by connecting free text and pretrained entities (companies, sources, sectors) by boolean operators such as AND, OR, NOT, NEAR.',
          },
        ]
      }
    ]
  },

  // BSIDE -----------------------------------------

  {
    urlo: '/cockpit/news/market',
    myid: 'menubside',
    icon: '',
    tito: 'View and Sort Options',
    desk: 'The menu on the right hand side offers different ways of presenting current board´s data and different data sorting options - by sentiment, by trend or alphabetically.',
    menu: [
      {
        icon: '',
        tito: 'View',
        desk: 'Choosing view allows you to select the different visualization options available, each displaying the selected analytics with a different focus.',
        menu: [
          {
            icon: 'insert_chart',
            tito: 'Chart',
            desk: 'In the Chart view, you can get an overview of the related news volume, events mentions count, alongside the sentiment (and stock price for companies) development over time.'
          },
          {
            icon: 'assignment',
            tito: 'News View',
            desk: 'In the News View page, you can go through a list of all the news articles in a structured manner to asses risk situations for compliance, sales opportunities contained within the text of the articles. You can then dismiss or flag these articles for review. All activities in the News View page can also be stored and exported for compliance checks.'
          },
          {
            icon: 'multiline_chart',
            tito: 'Benchmark',
            desk: 'With the Benchmark visualization you benchmark the sentiment development of the overall portfolio/collection/query against chosen related entities.'
          },
          {
            icon: 'my_location',
            tito: 'Radar',
            desk: 'In the Radar view you can get another visual representation of the volume of news and the sentiment serving the purpose of the chosen board´s elements comparison.'
          },
          {
            icon: 'bubble_chart',
            tito: 'Network',
            desk: 'In the Networks visualizations you can understand how companies, persons and topics are interlinked and connected in the underlying articles. The network outlines different topic clusters in the data.'
          },
        ]
      },
      {
        icon: '',
        tito: 'Sort',
        desk: 'Allows you to reorganize the order within data visualizations or the tag cloud by:',
        menu: [
          {
            icon: 'sentiment_very_satisfied',
            tito: 'Sentiment',
            desk: 'Sorted by absolute sentiment.'
          },
          {
            icon: 'sort_by_alpha',
            tito: 'Alphabetic',
            desk: 'Sorted from A-Z or Z-A.'
          },
          {
            icon: 'trending_up',
            tito: 'Trending',
            desk: 'Sorted by the rate of change in sentiment compared to the previous time interval selected.'
          },
        ]
      }
    ]
  },

  // NEWS -----------------------------------------

  {
    urlo: '/cockpit/news/market/chart?type=company&id=tesla_motors',
    myid: 'news',
    icon: '',
    tito: 'News Lab Tutorial',
    desk: '<p>Welcome to the YUKKA Lab´s News Lab tutorial.</p><p>The News Lab provides an overview of the absolute sentiments, the sentiment trends as well as the topics and the underlying articles in four distinct boards – Portfolio Board, Collection Board, Market Board and Trending Board. These boards are displayed in conjunction with the News Ticker (on the left) and the Tag Cloud (at the bottom) components as well as unique data visualization options such as Chart, Benchmark, Radar and Network.</p>'
  },

  // PORTFOLIO -----------------------------------------

  {
    urlo: '/cockpit/news/portfolio',
    myid: 'portfolio',
    icon: 'collections_bookmark',
    tito: 'Portfolio Board',
    desk: 'The Portfolio Board enables you to create and customize personalized portfolios, to include entities such as companies, people, stock indices, regions etc. that are relevant for you. In addition, there are also pre-loaded examples that you can choose from. When you open the Portfolio Board for the first time, you will be greeted with the options to create a portfolio or load one of the examples. Once you have created your first Portfolio, note the toolbar at the bottom. Here, you can click:',
    menu: [
      {
        icon: 'cloud_download',
        tito: 'IMPORT',
        desk: 'IMPORT to create a portfolio based on ISINs list.',
      },
      {
        icon: 'library_add',
        tito: 'ADD',
        desk: 'ADD to search for companies/indices/countries you would like to add to your existing portfolio.',
      },
      {
        icon: 'border_color',
        tito: 'EDIT',
        desk: 'EDIT to change the name of the portfolio/board or delete it. ',
      },
      {
        icon: 'create_new_folder',
        tito: 'NEW',
        desk: 'NEW and enter a name to create another portfolio.',
      },
      {
        icon: 'mode_edit',
        tito: 'MODIFY',
        desk: 'In addition you can click on the small pencil icon in the tiles to delete or change an entity from the portfolio.',
      },
    ]
  },

  // ENTITY -----------------------------------------

  {
    urlo: '/cockpit/news/market/chart?type=company&id=tesla_motors&period=90&time=180',
    myid: 'entity',
    icon: '',
    tito: 'Company',
    desk: 'The Company Analytics View is divided into two main sections, Chart & Mosaics. The Chart provides an overview of the sentiment (the black line), stock price (semi-transparent line), news volume (gray bars) and events volume (blue bars) in the time period selected.',
    menu: [
      {
        icon: 'insert_chart',
        tito: 'Chart',
        desk: '<p>By hovering over the bars and the sentiment line you will get detailed numbers for the specific day.</p><p>By clicking on the bar of a certain day you will activate a filter and the topic cloud as well as the news feed will adapt accordingly.</p><p>If you want to drill-down on a specific area of the charting. Press the mouse and swipe over the bars. By releasing the mouse the drill-down will be triggered and the topic cloud as well as the news feed will adapt accordingly.</p><p>The Chart component will also be covered in the data visualization section.</p>',
      },
      {
        icon: 'view_compact',
        tito: 'Mosaics',
        desk: 'The Mosaics are concise analytical visualizations, just below the chart, that can be configured accord to the user’s preference. The default mosaics are set to Events, Peergroup Comparison & Topic List. You can customize which ones are shown by three-dot icon on the top-right of the right-most mosaic. The Mosaics available are:',
        menu: [
          {
            icon: '',
            tito: 'Events',
            desk: 'Shows the Top 7 major events and volume of those events currently affecting the company.'
          },
          {
            icon: '',
            tito: 'Peergroup Comparison',
            desk: 'Shows how the company is performing among a group of its peers. The peergroup is selected per the Industry, Sector, Sub-Sector of Supersector that the company is in per ICB Classification.'
          },
          {
            icon: '',
            tito: 'Topic List',
            desk: 'Shows the top topics associated with the company in the specified time period.'
          },
          {
            icon: '',
            tito: 'Entity',
            desk: 'Shows an overview of the company’s structured information and stock price.'
          },
        ]
      },
    ]
  },

  // FEED -----------------------------------------

  {
    urlo: '/cockpit/news/market?type=company&id=tesla_motors',
    myid: 'newsfeed',
    icon: '',
    tito: 'News Ticker',
    desk: 'The news feeds are streamed in real time into the ticker, refined by the time period, language and news sources that were defined through the filter function. Messages are evaluated immediately as positive, negative or neutral by YUKKA Lab’s proprietary Augmented Language Intelligence engine. News articles are coloured gray if no sentiment for the selected entity was assigned and which are therefore not considered for the sentiment score calculation.',
    menu: [
      {
        icon: '',
        tito: 'News Article Counter',
        desk: 'News Article Counter shows the number of positive, neutral and negative articles. Clicking on either of the sentiment types will filter the news feed to that particular sentiment.'
      },
      {
        icon: '',
        tito: 'Relevant News',
        desk: 'Relevant News tab provides you with the news stories based on an algorithm that calculates the significance of the news and source of the news for the entity and filter selected. You can click on a particular article tile to view the full-text.',
        menu: []
      },
      {
        icon: '',
        tito: 'Chronological News',
        desk: 'Chronological News tab displays the articles for the given time period and the selected entity in chronological order. You can click on a particular article tile to view the full-text.',
        menu: []
      },
      {
        icon: '',
        tito: 'Bookmarked News',
        desk: 'Bookmarked News tab displays the list of all current page related articles bookmarked previously from the particular news full-text view.',
        menu: []
      },
      {
        icon: '',
        tito: 'Full-Text View',
        desk: 'When you click a particular article in the News Feed, you open the full-text view of the news.',
        menu: [
          {
            icon: 'bookmark',
            tito: 'Bookmark',
            desk: 'Click the icon to bookmark articles for the future reference. They will show up under the Bookmarked News tab.',
          },
          {
            icon: 'add_comment',
            tito: 'Annotation',
            desk: 'Using annotation feature, you can tell the story of a particular entity by adding significant events that have affected its sentiment. You can click on a news article of significance and add a short free-text description and title. This title will then also show up on the chart component for the particular entity above the volume bar for the given date.',
          },
          {
            icon: 'report',
            tito: 'Report',
            desk: 'If you notice inconsistencies or errors in the text, please feel free to povide feedback to us via the report button on the top right corner.',
          },
          {
            icon: '',
            tito: 'Matches/Tags/Events',
            desk: 'When you click on the identified matches, tags or events below the text, the corresponding words will be highlighted in the text.'
          }
        ]
      },
    ]
  },

  // VISUAL HOME-----------------------------------------

  {
    urlo: '/cockpit/news/market?type=company&id=tesla_motors',
    myid: 'dataoptionhome',
    icon: '',
    tito: 'Data Visualization Options',
    desk: '<p>There are five different views available in the News Lab: Chart, News View, Benchmark, Trend Radar and Network. Each of them visualizes the data in a different way. They can be accessed over the “View” menu on the right hand side as described earlier. For the four main pages, the following views are available:</p><p><ol><li><b>Portfolio</b> – All Views</li><li><b>Collection</b> – All Views</li><li><b>Market</b> – Chart, News View, Trend Radar, Network</li><li><b>Trending Board</b> – Only Main View</li></ol></p>',
  },

  // CHART -----------------------------------------

  {
    urlo: '/cockpit/news/market/chart?type=company&id=tesla_motors',
    myid: 'chart',
    icon: 'insert_chart',
    tito: 'Chart',
    desk: '<p>In the Chart, you can get an overview of the sentiment development over time, represented by the black line. The number of articles per day are represented by the bars along the x-axis. The volume of events is represented as the highlighted blue portion of the grey volume bars. You will also notice certain time period with text boxes over the sentiment line. These text boxes are annotations that are available globally or can be either added privately for a specific user. The scale for the number of articles (volume) is located on the left side and the scale for the sentiment percentages is located on the right.</p><p>You can hover over the volume bars and the sentiment line to get detailed numbers for the specific day. By clicking on the bar of a particular day you will activate a timeframe filter and the topic cloud as well as the news feed will adjust accordingly. If you want to drill-down on a specific area of the charting: press the mouse and swipe over the bars for the time frame you would like to zoom in on. When you release the mouse, the zoom function will be triggered and the topic cloud as well as the news feed will refresh.</p>',
  },

  // NEWS VIEW

  {
    urlo: '/cockpit/news/market/audit?type=company&id=tesla_motors',
    myid: 'newsview',
    icon: 'assignment',
    tito: 'News View',
    desk: 'In the News View Page, you can go through a list of all the news articles in a structured manner to asses risk situations for compliance, sales opportunities contained within the text of the articles. You can then dismiss or mark these articles for review. All activities in the audit page can also be stored and exported for compliance checks. There are two mains sections in the audit page:<p><ol><li><b>News Ticker</b></li><li><b>Insights</b></li></ol></p>'
  },

  // NEWSFEED IN NEWS VIEW

  {
    urlo: '/cockpit/news/market/audit?type=company&id=tesla_motors',
    myid: 'newsviewfeeds',
    icon: '',
    tito: 'News Ticker',
    desk: '',
    menu: [
      {
        icon: '',
        tito: 'News Article Counter',
        desk: 'News Article Counter shows the number of positive, neutral and negative articles. Clicking on either of the sentiment types will filter the news feed to that particular sentiment.'
      },
      {
        icon: '',
        tito: 'Relevant News',
        desk: 'Relevant News tab provides you with the news stories based on an algorithm that calculates the significance of the news and source of the news for the entity and filter selected. You can click on a particular article tile to view the full-text.',
        menu: []
      },
      {
        icon: '',
        tito: 'Chronological News',
        desk: 'Chronological News tab displays the articles for the given time period and the selected entity in chronological order. You can click on a particular article tile to view the full-text.',
        menu: []
      },
      {
        icon: '',
        tito: 'Bookmarked News',
        desk: 'Bookmarked News tab displays the list of all current page related articles bookmarked previously from the particular news full-text view.',
        menu: []
      },
      {
        icon: '',
        tito: 'Audited News',
        desk: 'All audited articles are available in this overview.',
        menu: []
      },
      {
        icon: '',
        tito: 'Dismissed News',
        desk: 'All dismissed articles are available in this overview.',
        menu: []
      },
      {
        icon: '',
        tito: 'Full-Text View',
        desk: 'When you click a particular article in the News Feed, you open the full-text view of the news.',
        menu: [
          {
            icon: 'flag',
            tito: 'Flag Article for Action',
            desk: 'Clicking the flag icon will open the Flag for Action Dialog Box. This dialog box will allow you to mark the article for review, pick the related entity for review, add a comment and notify another party about the article.',
            menu: []
          },
          {
            icon: 'remove_circle',
            tito: 'Dismiss Article',
            desk: 'Dismiss article simply removes the article from the list, so that you can work the list more efficiently.',
            menu: []
          },
          {
            icon: 'bookmark',
            tito: 'Bookmark',
            desk: 'Click the icon to bookmark articles for the future reference. They will show up under the Bookmarked News tab.',
          },
          {
            icon: 'add_comment',
            tito: 'Annotation',
            desk: 'Using annotation feature, you can tell the story of a particular entity by adding significant events that have affected its sentiment. You can click on a news article of significance and add a short free-text description and title. This title will then also show up on the chart component for the particular entity above the volume bar for the given date.',
          },
          {
            icon: 'report',
            tito: 'Report',
            desk: 'If you notice inconsistencies or errors in the text, please feel free to povide feedback to us via the report button on the top right corner.',
          },
          {
            icon: '',
            tito: 'Matches/Tags/Events',
            desk: 'When you click on the identified matches, tags or events below the text, the corresponding words will be highlighted in the text.'
          }
        ]
      },
      {
        icon: 'outlined_flag',
        tito: 'Flag Article for Action',
        desk: 'Clicking the flag icon for an article will open the Flag for Action Dialog Box. This dialog box will allow you to mark the article for review, pick the related entity for review, add a comment and notify another party about the article.',
        menu: []
      },
      {
        icon: 'remove_circle_outline',
        tito: 'Dismiss Article',
        desk: 'Dismiss article simply removes the article from the list, so that you can work the list more efficiently.',
        menu: []
      }
    ]
  },

  // INSIGHTS IN NEWS VIEW

  {
    urlo: '/cockpit/news/market/audit?type=company&id=tesla_motors',
    myid: 'newsviewinsights',
    icon: '',
    tito: 'Insights',
    desk: '',
    menu: [
      {
        icon: '',
        tito: 'Chart',
        desk: 'The chart in this view shows the volume of news over the time period specified, the color is indicative of the current sentiment.'
      },
      {
        icon: '',
        tito: 'Mentioned Entities',
        desk: 'Here you can see a list of the entities mentioned in the articles, the count is the number of mentioned over the total number of articles in the time period selected.'
      }
    ]
  },

  // MULTICHART -----------------------------------------

  {
    urlo: '/cockpit/news/market/chart2?type=company&id=tesla_motors',
    myid: 'multichart',
    icon: 'multiline_chart',
    tito: 'Benchmark',
    desk: '<p>With the Benchmark visualization you benchmark the sentiment development against the overall portfolio or against specific companies for a Peer Group comparison. Each entity is displayed with a unique colored line and can be manually selected or de-selected from the chart. As well you can adjust the moving average depending on your perspective (short term: 7 day mid-term: 14 days long-term: 30 days).</p><p>If you want to drill-down on a specific area of the charting: Press the mouse and swipe over the bars. When you release the mouse, the zoom function will be triggered and the topic cloud as well as the news feed will refresh.</p>',
  },

  // RADAR -----------------------------------------

  {
    urlo: '/cockpit/news/market/radar?type=company&id=tesla_motors',
    myid: 'radar',
    icon: 'my_location',
    tito: 'Radar',
    desk: '<p>In the Trend Radar, you can get a visual representation of the the volume of news and the sentiment for a particular entity. This allows you to comapre between portfolio companies how intensivly and with what sentiment they have been covered by the media. Each row along with the respective title on the y-axis on the left represents one entity. The size of the bubble represents the total volume and the color represents the sentiment.</p><p>Bubbles represent the aggregated news volume (size) and sentiment (colour) for the aggregated news of: <ul><li>1 day for period under/egual to a month</li><li>3 days for period under/equal to a quarter but longer then a month</li><li>5 days for all periods greater then a quarter</li></ul></p>',
  },


  // NETWORK -----------------------------------------

  {
    urlo: '/cockpit/news/market/network?type=company&id=tesla_motors',
    myid: 'network',
    icon: 'bubble_chart',
    tito: 'Network',
    desk: '<p>In the Networks visualizations, you can understand how companies, persons and topics are interlinked and connected in the underlying articles. The network outlines different topic clusters in the data. It is connected to the topic cloud and the news feed. By clicking on a certain bubble the respective topic is highlighted in the tag cloud and the news feed is filtered accordingly.</p><p>For example, you can select a particular company (Ex. Volkswagen) and click on one of the tags associated with it (Ex. Germany, BMW, Dieselgate). The news feed will adjust accordingly and show only articles where the key word or phrase from the tag is mentioned in relation to the company.</p>',
  },

  // COLLECTION -----------------------------------------

  {
    urlo: '/cockpit/news/query',
    myid: 'collection',
    icon: 'view_module',
    tito: 'Collection',
    desk: 'The Collection Board enables you to create and store your boolean queries in a portfolio-like views. While the Portfolio Board is designed to store pre-trained entities, the Collection Board allows user to emulate their own “non-trained entities” or set up queries of entities with case-specific criteria. You can click:',
    menu: [
      {
        icon: 'library_add',
        tito: 'ADD',
        desk: 'ADD to create a new query that is then added to the collection.'
      },
      {
        icon: 'border_color',
        tito: 'EDIT',
        desk: 'EDIT to edit the name of the collection.'
      },
      {
        icon: 'create_new_folder',
        tito: 'NEW',
        desk: 'NEW to create a new collection.'
      }
    ]
  },

  // BOOLEAN QUERIES

  {
    urlo: '/cockpit/news/query',
    myid: 'queries',
    icon: '',
    tito: 'Boolean Queries',
    desk: 'The Boolean Query is a powerful tool that allows you to build customized data aggregations that combines pre-trained ontology entities with each other and free-text through AND, OR, NOT and NEAR operators.',
    menu: [
      {
        icon: '',
        tito: 'Input Types',
        desk: '',
        menu: [
          {
            icon: '',
            tito: 'Pre-Trained Entity',
            desk: 'When typing in a name you will get all the pre-trained entity options from the search; it is recommended to use these when possible to improve the accuracy of the search.'
          },
          {
            icon: '',
            tito: 'Free Text',
            desk: 'If you do not find any pre-trained entities that match the words you type in, you may select it as a “Free Text”. It is recommended that you do not have more than one word on each input line. Multiple words can be combined through the AND or NEAR operators to achieve better results.'
          }
        ]
      },
      {
        icon: '',
        tito: 'Operators',
        desk: '',
        menu: [
          {
            icon: '',
            tito: 'AND',
            desk: 'Searches for articles containing all of the specified inputs.'
          },
          {
            icon: '',
            tito: 'OR',
            desk: 'Searches for articles containing either of the specified inputs.'
          },
          {
            icon: '',
            tito: 'NOT',
            desk: 'Excludes articles containing the specified input.'
          },
          {
            icon: '',
            tito: 'NEAR',
            desk: 'Search articles containing all of the specified inputs within a certain number of words of each other. Eg: The Default is “5” so it would search for two inputs occurring within 5 words of each other.'
          }
        ]
      },
      {
        icon: '',
        tito: 'Query Groups',
        desk: '',
        menu: [
          {
            icon: '',
            tito: 'Default',
            desk: 'The query is set up by default to contain one group with one operator between all inputs Eg. [Tesla OR Cybertruck OR Elon].'
          },
          {
            icon: 'library_add',
            tito: 'Add group',
            desk: 'If you wish to specify additionally criteria with different operator, you may click “ADD GROUP” to add a new group. This group can have entities and operators of it’s own and then it can be connected to the main query. Eg. [Tesla OR Cybertruck OR Elon] AND [Durability NEAR(20) Broken].'
          }
        ]
      }
    ]
  },

  // MARKET BOARD -----------------------------------------

  {
    urlo: '/cockpit/news/market?type=hierarchy&id=icb',
    myid: 'market',
    icon: 'public',
    tito: 'Market Board',
    desk: 'The Market Board gives you an overview through a heatmap of the overall sentiment and news development structured by Country & Index and Industry & Sector. Every entity has a tile.',
    menu: [
      {
        icon: '',
        tito: 'Market Tabs',
        desk: '<p>Market Tabs are preselected by Country & Index and Industry & Sector. You can click on these tabs to see the next aggregation level.',
      },
      {
        icon: '',
        tito: 'Tiles',
        desk: '<p>Each tile consists of the name of the entity along with sub-tiles of constituent entities, the precise sentiment score and the rate of change in sentiment. Sentiment scores are displayed as percentages, ranging from negative (0-50) to positive (50-100), based on the color palette as below.</p><br/><div class="tutorial-colors"></div><br/><p>You can drill-down into a detailed view of the next aggregation level, by clicking on the tile representing the particular region, stock index, sector or sub-sector.</p>'
      },
      {
        icon: '',
        tito: 'Topic Cloud',
        desk: 'At the bottom of the screen you can find the Topic Cloud derived from the news content in the News Ticker. The Topic Cloud is a statistical feature that determines the topics and terms used more frequently in the time period selected compared to the rest of the entire data archive for the selected entity. Aiming to provide an overview of the new edges of the coverage and stories in the news about the entity. So this is not an absolute representation of the frequency but rather a relative comparison to the usual frequency of use when covering the entity. By clicking on a specific topic you can filter on it and the news ticker will be filtered accordingly.  In addition the topic will be highlighted in the full-text when you open a news article.'
      }
    ]
  },

  // ENTITY -----------------------------------------

  {
    urlo: '/cockpit/news/market/chart?type=stock_index&id=dax_30&period=7&time=7',
    myid: 'aggregation',
    icon: '',
    tito: 'Aggregated Entities',
    desk: 'The Chart provides an overview of the sentiment (black line) and volume (grey bars) for DAX 30 over the selected time frame as defined by the time filter (week in this case) referenced earlier in the step-by-step tutorial.',
    menu: [
      {
        icon: '',
        tito: 'Chart',
        desk: '<p>By hovering over the bars and the sentiment line you will get detailed numbers for the specific day.</p><p>By clicking on the bar of a certain day you will activate a filter and the topic cloud as well as the news feed will adapt accordingly.</p><p>If you want to drill-down on a specific area of the charting. Press the mouse and swipe over the bars. By releasing the mouse the drill-down will be triggered and the topic cloud as well as the news feed will adapt accordingly.</p><p>The Chart component was also covered in the data visualization section.</p>',
      },
      {
        icon: '',
        tito: 'Heatmap',
        desk: 'The heatmap shows the sentiment of all DAX 30 constitutes as well as their respective change in sentiment. Similar to the previous step, you can click into one of these companies to drill-down to the company level and see sentiment and news volume over time for it.',
      },
    ]
  },

  // TRENDING -----------------------------------------

  {
    urlo: '/cockpit/news/trending',
    myid: 'trending',
    icon: 'whatshot',
    tito: 'Trending',
    desk: '<p>The Trending Board lists different entities or groups of entities and how they’re “Trending” based on Change in Volume or Change in Sentiment over the selected time period. The entities/groups available on the Trending Board are Industries, sectors, sub-sectors, companies, stock indices, countries and regions.</p><p>The data within each tab is formatted as follows:</p><p>[ENTITY NAME]&nbsp&nbsp&nbsp<b>x</b>VolumeChange (Sentiment%) (↑ or ↓ SentimentChange)</p><p>The Volume Change Eg. <b>x50</b> indicates a 50 times increase in published articles over the previous period, while Eg. <b>↓36</b> indicates a 36 point decrease in sentiment over the previous period.</p>',
  },

  // TREND -----------------------------------------

  {
    urlo: '/cockpit/trend/secsignal?id=stoxx_europe_600_auto_parts_trn',
    myid: 'trend',
    icon: '',
    tito: 'Trend Lab Tutorial',
    desk: '<p>Welcome to the Trend Lab Tutorial.</p><p>Trend Lab is an early warning system that gives you an alert when the sentiment trend of one of the 19 covered sectors has reached a turning as well as indicating the recommended investment ratio based on sector secsignal. There are 19 underlying economic sectors (according Industry Classification Benchmark – ICB). Based on the sector secsignal you can formulate different overlay strategies that determine your insventment ratio according the sentiment development of the underlying 19 sectors, which can either have a positive, positive with a warning or negative sentiment status. Under “Indicator” you can chose a benchmark as well as select one of four example overlay strategies (low risk, balanced risk, optimised risk & return and binary) to see the performance over time against the benchmark.</p>',
  },

  // SIGNALS -----------------------------------------

  {
    urlo: '/cockpit/trend/secsignal?id=stoxx_europe_600_auto_parts_trn',
    myid: 'secsignalhome',
    icon: 'timeline',
    tito: 'Signals Tab',
    desk: 'The Signals Tab provides an overview of the sentiment status of the 19 different sectors, which can be either positive, positive with a warning or negative. It it divided into two distinct areas, Economic Sectors and Signals Chart.',
  },
  {
    urlo: '/cockpit/trend/secsignal?id=stoxx_europe_600_auto_parts_trn',
    myid: 'secsignal1',
    icon: '',
    tito: 'Economic Sectors',
    desk: '<p>Sectors are presented on the left side of the window. The name of the sectors are displayed in green for entry recommendations and red for exit recommendations. If the medium moving average goes above the long-term moving average you get an entry signal (sector name becomes green). Then a trailing stop loss is activated, which gives the market depending on the volatility of the sector 5-10% to breathe and move. As soon as the medium moving average goes below the long term moving average the sector gets a warning signal (sector name is extended by a warning sign) and the trailing stop is adjusted 5-10% with regards to the actual stock price. The stop loss is then triggered when the stock price touches the stop loss line. This constitutes an exit signal (sector name becomes red).</p>',
  },
  {
    urlo: '/cockpit/trend/secsignal',
    myid: 'secsignal2',
    icon: '',
    tito: 'Signals Chart',
    desk: '<p>Signals are presented on the right side of the window. On the bottom chart, the thicker grey curve represents the longterm moving average and the thinner blue curve represents the medium-term moving average of the sentiment. On top the grey line represents the price chart with the green exit secsignal (Green Up Arrow <font color="green">▲</font>) and the red exit secsignal (Red Down Arrow <font color="red">▼</font>)</p>'
  },

  // INDICATORS  -----------------------------------------

  {
    urlo: '/cockpit/trend/indicator?id=dax&overlay=conservative',
    myid: 'indicatorhome',
    icon: 'graphic_eq',
    tito: 'Indicator Tab',
    desk: '<p>The Indicator Tab shows the performance of the four different sentiment overlay strategies versus different benchmarks. Based on the sector secsignal four different sentiment overlay strategies have been formulated: Low Risk, Balanced Risk, Optimised Risk & Return and Binary. You can chose on of the overlay strategies as well as your preferred benchmark.</p>',
  },
  {
    urlo: '/cockpit/trend/indicator?id=dax&overlay=conservative',
    myid: 'indicator1',
    icon: '',
    tito: 'Benchmark & Overlay',
    desk: '<p>Benchmark & Overlay displays all the current benchmark indices available in the cockpit: DAX 30, EURO STOXX 50, MDAX, MSCI Emerging Markets, MSCI France, MSCI UK, MSCI World, STOXX Europe, Swiss SPI as well as the 4 sentiment overlays:</p><p><ol><li><b>Low Risk</b>(Signal Update: every 15 days (1st and 16th of each month) takes entry and exit secsignal into account, Investment Ratio: Gradual from 0-100%)<br/><br/></li><li><b>Balanced Risk</b>(Signal Update: every 15 days (1st and 16th of each month) takes entry, warning and exit secsignal into account, Investment Ratio: Gradual from 0-100%)<br/><br/></li><li><b>Optimised Risk & Return</b>(Signal Update: daily and takes entry, warning and exit secsignal into account, Investment Ratio: Gradual from 0-100%)<br/><br/></li><li><b>Binary</b>(Signal Update: every 15 days (1st and 16th of each month) takes entry and exit secsignal into account, Investment Ratio: 0% or 100%)<br/><br/></li></ol></p>',
    menu: [
      {
        icon: 'warning',
        tito: 'Investment Ratio Alert',
        desk: 'Investment Ratio Alerts are activated through the toggle “Alert Me of Changes”. The alerts need to be activated per overlay and per market, depending on your preference. The alerts are issued over email whenever the algorithm calculates a change in the recommended Investment Ratio, and scheduled to be delivered before market opening.'
      },
    ]
  },
  {
    urlo: '/cockpit/trend/indicator?id=dax&overlay=conservative',
    myid: 'indicator2',
    icon: 'graphic_eq',
    tito: 'Indicators Chart',
    desk: '<p>Indicators Chart displays the benchmark of the chosen overlay against the market index, as well as the change in investment ratio over time.</p><p><ol><li>The <b><font color="green">green</font></b> line represents the percentage performance of the overlay over time.<br/><br/></li><li>The highlighted <b><font color="red">red</font></b> zones specifically highlight timeframes where the Trend Lab had reduced drawdowns, compared against the index performance.</li></ol></p>'
  },

  // SUPPORT -----------------------------------------

  {
    urlo: '/cockpit/trend/indicator?id=dax&overlay=conservative',
    myid: 'support',
    icon: 'live_help',
    tito: 'Support',
    desk: 'We hope this tutorial helped you to learn more about the YUKKA News & Trend Lab. If you have more questions or need support do not hesitate to contact us : <a href="mailto: support@yukkalab.com" target="_blank">support@yukkalab.com</a>'
  },

];
