import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
* This pipe define the sentiment color
*/

@Pipe({ name: 'color' })
export class ColorPipe implements PipeTransform {
  constructor(
    public routing: RoutingService,
  ) { }
  transform(value: any): string {
    if (value !== undefined) {
      if (value === 'nf' || value === 'null' || value === null || value === -1) { return '#a3a3a3'; }
      if (value === 'pos' || value === 'buy') { value = 1; }
      if (value === 'neu') { value = 0.5; }
      if (value === 'neg' || value === 'sell') { value = 0; }
      if (this.routing.theme === 'light') {
        const colorScale = d3.scaleLinear()
          .domain([0, .5, 1])
          .range(['#a71717', '#f6d500', '#009e00']);
        return colorScale(value.toFixed(2));
      } else {
        const colorScale = d3.scaleLinear()
          .domain([0, .5, 1])
          .range(['#dd001a', '#f6d500', '#3dac00']);
        return colorScale(value.toFixed(2));
      }
    } else {
      return 'rgb(120,120,120)';
    }
  }
}
