<div mat-dialog-content class="report-view">
  <div class="report-info">
    <div class="report-info-main">
      <div class="info">
        <div class="main-tito">Report</div>
        <div class="cont type">{{data.id}}</div>
      </div>
      <div class="info">
        <div class="main-tito">Project</div>
        <div class="cont type">{{data.project}}</div>
      </div>
      <div class="info">
        <div class="main-tito">Time</div>
        <div class="cont type">{{data.time | date:'medium'}}</div>
      </div>
      <div class="info">
        <div class="main-tito">Account</div>
        <div class="cont link">{{data.email}}</div>
      </div>
      <div class="info" *ngIf="data.language">
        <div class="main-tito">Language</div>
        <div class="cont type">{{data.language|nicetxt}}</div>
      </div>
      <div class="info">
        <div class="main-tito">Report</div>
        <div class="cont type">{{data.report}}</div>
      </div>
      <div class="info" *ngIf="data.matching">
        <div class="main-tito">Subject</div>
        <div class="cont type">{{data.matching}}</div>
      </div>
      <div class="info" *ngIf="data.tag">
        <div class="main-tito">Tag</div>
        <div class="cont type">{{data.tag}}</div>
      </div>
      <ng-container *ngIf="data.reportInfo">
        <div class="info" *ngIf="data.reportInfo.sentiment">
          <div class="main-tito">Sentiment</div>
          <div class="cont type">{{data.reportInfo.sentiment}}</div>
        </div>
        <div class="info" *ngIf="data.reportInfo.entity">
          <div class="main-tito">Entity</div>
          <div class="cont type" *ngIf="!data.reportInfo.entityId">{{data.reportInfo.entity}}</div>
          <div class="cont type" *ngIf="data.reportInfo.entityId">{{data.reportInfo.entity + ' (' + data.reportInfo.entityType + ':' + data.reportInfo.entityId + ')'}}</div>
        </div>
        <div class="info" *ngIf="data.reportInfo.event">
          <div class="main-tito">Event</div>
          <div class="cont type" *ngIf="!data.reportInfo.eventId">{{data.reportInfo.event}}</div>
          <div class="cont type" *ngIf="data.reportInfo.eventId">{{data.reportInfo.event + ' (' + data.reportInfo.eventType + ':' + data.reportInfo.eventId + ')'}}</div>
        </div>
        <div class="info" *ngIf="data.reportInfo.participant">
          <div class="main-tito">Participant</div>
          <div class="cont type" *ngIf="!data.reportInfo.participantId">{{data.reportInfo.participant}}</div>
          <div class="cont type" *ngIf="data.reportInfo.participantId">{{data.reportInfo.participant + ' (' + data.reportInfo.participantType + ':' + data.reportInfo.participantId + ')'}}</div>
        </div>
      </ng-container>
      <!-- <div class="info" *ngIf="data.entity">
        <div class="main-tito">Entity</div>
        <div class="cont type">{{data.entity}}</div>
      </div> -->
    </div>
    <div class="report-info-text">
      <mat-form-field class="label" appearance="outline">
        <mat-label>Label</mat-label>
        <input matInput [(ngModel)]="data.label">
      </mat-form-field>
      <mat-form-field class="message" appearance="outline">
        <mat-label>Message</mat-label>
        <textarea matInput [(ngModel)]="data.message" matTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="data.reportInfo && data.reportInfo.sentence" class="message" appearance="outline">
        <mat-label>Sentence</mat-label>
        <textarea matInput [(ngModel)]="data.reportInfo.sentence" matTextareaAutosize></textarea>
      </mat-form-field>
    </div>
  </div>
  <app-news-view *ngIf="data.report!='Request Entity'"
    [apicall]="{apicall:data.apicall, match:data.matching, tag:data.tag}"></app-news-view>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="goNews(data.url)" *ngIf="data.url">
    <span>News</span>
    <mat-icon>link</mat-icon>
  </button>
  <button mat-stroked-button *ngIf="data.reportInfo && data.reportInfo.cockpitUrl" (click)="goCockpit(data.reportInfo.cockpitUrl)">
    <span>Cockpit</span>
    <mat-icon>link</mat-icon>
  </button>
  <button mat-stroked-button (click)="goReport(data.id)">
    <span>Report</span>
    <mat-icon>link</mat-icon>
  </button>
  <button mat-stroked-button [mat-dialog-close]="{remove:data.id}">
    <span>Delete</span>
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-stroked-button (click)="iSave()" [disabled]="updating">
    <span>{{updating ? 'Updating' : 'Update'}}</span>
    <mat-icon>save</mat-icon>
  </button>
</div>
