import { Component, ViewEncapsulation, Input, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewsReportComponent } from '../news-report/news-report.component';
import { NewsReportboxComponent } from '../news-reportbox/news-reportbox.component';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { Location } from '@angular/common';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { AnnotationComponent } from '../../news-annotation/news-annotation.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HelpComponent } from '../../../help/help.component';

/**
 * This is the most important component it display the news with the source, time, target, event, tag, etc....
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent implements OnInit {



  /**
   * main data of the news
   */
  newsinfo: any;

  /**
   * show matches/events/highlights
   */
  moreinfo = true;

  /**
   * main tag of the news
   */
  mytag: any;

  /**
   * main tag of the news
   */
  tag: string;

  /**
   * tag selected
   */
  btntag: number;

  /**
   * loading
   */
  loading: boolean;

  /**
   * usert auth
   */
  user: any;

  /**
   * turn on/off report view
   */
  reportView = false;

  /**
   * all data needed by report child component
   */
  reportData: any;

  /**
   * array of matched found
   */
  matches: any;

  /**
   * selected match
   */
  selectedMatch = null;

  /**
   * array of events found
   */
  events: any;

  /**
   * selected event
   */
  selectedEvent = null;

  /**
   * selected event instance in article
   */
  selectedEventInstance = null;

  /**
   * array of entities to be audited or dismissed from audit
   */

  auditEntities: any;

  /**
   * checks if at least one entity was chosen
   */

  validateAuditEntities: boolean;

  /**
   * form control for custom audit entities
   */

  customAuditEntity: UntypedFormControl;

  /**
   * form control for news audit comments
   */

  newsAuditComment: UntypedFormControl;

  /**
   * form control for news audit comments
   */

  newsResolveComment: UntypedFormControl;

  /**
   * form control for news audit content
   */

  newsAuditContent: UntypedFormControl;

  /**
   * what type of content should be send with audit
   */

  contentType: string;

  /**
   * form control for news audit emails
   */

  newsAuditNotify: UntypedFormControl;

  /**
   * toggle between audit and news views
   */

  auditView: boolean;

  /**
   * toggle between dismiss and news views
   */

  dismissView: boolean;

  /**
   * toggle between dismiss and news views
   */

  resolveView: boolean;

  /**
   * toggle between dismiss and news views
   */

  checkView: boolean;

  /**
   * array of match related
   */
  inmatches: any;

  /**
   *
   */
  instart: any;

  /**
   *
   */
  inrelated: any;

  /**
   * data from the api
   */
  @Input('apicall') apicall: any;

  /**
   * match selected
   */
  btnmatch: string;

  /**
   * query parameters
   */
  params: any;

  /**
   * query parameters directly from the url
   */
  paramsURL: any;

  /**
   * show hide api reference
   */
  apiref: boolean;

  /**
   * preferred news
   */
  mystar: boolean;

  /**
   *
   */
  stars = [];

  /**
   * event id
   */
  eventid: string;

  /**
   * event index
   */
  eventindex: number;

  /**
   * previous event index
   */
  oldEventIndex: number;

  /**
   * check if user clicked on event encounters tags
   */
  eventsEncountersChecked: boolean;

  /**
   * highlight text spans defined in the url
   */
  showHighlights: boolean;

  /**
   * highlight text spans defined in the url
   */
  showHighlightsPNE: boolean;

  /**
   * array of participants
   */
  participants: any;

  /**
   * mat-chips related parameters
   */
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  customEntitiesControl = new UntypedFormControl();
  filteredCustomEntities: Observable<any>;
  customAuditEntities = [];
  addedEntities = [];

  @ViewChild('customEntitiesInput') customEntitiesInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  /**
   * setup the user and check the news curation
   */
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthService,
    public location: Location,
    public snackBar: MatSnackBar,
    public routing: RoutingService,
    public config: ConfigService,
    private router: Router,
    private dialogRef: MatDialogRef<NewsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // this.user = this.auth.authToken();
    this.route.queryParams.subscribe(params => {
      this.paramsURL = JSON.parse(JSON.stringify(params));
      if (!(this.data && this.data.params)) {
        this.params = params;
      }
      if (!this.routing.isReader() && !this.routing.isStoryReader()) {
        this.user = this.auth.authToken();
      } else if (params.jwt) {
        this.user = jwt_decode(params.jwt);
      } else {
        this.user = this.auth.authToken();
      }
    });
    if (this.config.appConfig.setup.useStorage) {
      if (localStorage.getItem('news_curation')) {
        this.stars = localStorage.getItem('news_curation').split(',');
      }
    }
    forkJoin({
      auditedEntities: this.auth.getAuditedSubjects(),
      dismissedEntities: this.auth.getDismissedSubjects()
    }).subscribe(results => {
      const audited = results.auditedEntities;
      const dismissed = results.dismissedEntities;
      this.addedEntities = Array.from(new Set(audited.concat(dismissed)));
    });
    this.filteredCustomEntities = this.customEntitiesControl.valueChanges.pipe(
        startWith(null),
        map(entity => entity ? this.filterEntities(entity) : this.addedEntities.slice())
    );
  }

  /**
   * init the component at the start
   */
  ngOnInit() {
    this.contentType = 'article';
    this.showHighlights = false;
    this.showHighlightsPNE = false;
    if (this.apicall) {
      this.newsinfo = this.apicall.apicall;
      if (typeof this.newsinfo === 'string') { this.newsinfo = JSON.parse(this.newsinfo); }
    } else {
      this.newsinfo = this.data.newsinfo;
      this.params = this.data.params;
    }
    this.mytag = undefined;
    this.selectedMatch = null;
    this.selectedEvent = null;
    this.selectedEventInstance = null;
    this.tag = null;
    if (this.params && this.params.tag) {
      this.tag = decodeURI(this.params.tag.split('|')[0]);
    } else if (this.apicall && this.apicall.tag) {
      this.tag = decodeURI(this.apicall.tag.split('|')[0]);
    }
    this.btntag = undefined;
    this.matches = this.newsinfo.matches.filter(risp => {
      if ((this.config.appConfig.environment === 'dev') || (this.params.type && (this.params.type === 'pne'))) { return risp; }
      return risp.sentiment != null;
    });
    const uniqueEvents = [];
    this.newsinfo.events.forEach((eventArg, index) => {
      const event = JSON.parse(JSON.stringify(eventArg));
      if (uniqueEvents.filter(e => (e.id === event.id)).length === 0) {
        uniqueEvents.push({
          id: event.id,
          type: event.type,
          name: event.name,
          instances: [Object.assign({}, event, {index: index})]
        });
      } else {
        uniqueEvents.filter(e => (e.id === event.id))[0].instances.push(Object.assign({}, event, {index: index}));
      }
    });
    this.events = uniqueEvents;
    this.eventsEncountersChecked = false;
    this.instart = {
      type: undefined,
      alpha_id: undefined,
      compound_key: undefined
    };
    if (this.apicall && this.apicall.match) {
      this.newsinfo.matches.forEach(match => {
        if (match.entity.compound_key === this.apicall.match) {
          this.mytag = match;
          this.btnmatch = this.apicall.match;
          this.instart = {
            type: match.entity.type,
            alpha_id: match.entity.alpha_id,
            compound_key: match.entity.compound_key
          };
          this.inmatches = this.instart;
          this.inrelated = this.instart;
        }
      });
    } else if (this.params && this.params.type && this.params.id) {
      this.instart = {
        type: this.params.type,
        alpha_id: this.params.id,
        compound_key: this.params.type + ':' + this.params.id
      };
    }
    this.inmatches = this.instart;
    this.inrelated = this.instart;
    if (this.routing.isFolio() && this.auth.folio) {
      let entities = [];
      const portfolioEntities = this.auth.folio.content;
      entities = this.newsinfo.matches.filter(item => {
        return portfolioEntities.includes(item.entity.compound_key);
      });
      this.auditEntities = entities.map(entity => ({
        name: entity.entity.name,
        id: entity.entity.compound_key,
        selected: false
      }));
    } else if (!this.routing.isFolio()) {
      let entities = [];
      entities = this.newsinfo.matches.filter(item => {
        return item.entity.type === 'company';
      });
      if (entities.length === 0) {
        entities = this.newsinfo.matches;
      }
      this.auditEntities = entities.map(entity => ({
        name: entity.entity.name,
        id: entity.entity.compound_key,
        selected: false
      }));
    }
    this.validateAuditEntities = false;
    this.customAuditEntity = new UntypedFormControl('');
    this.newsAuditComment = new UntypedFormControl('');
    this.newsResolveComment = new UntypedFormControl('');
    this.newsAuditContent = new UntypedFormControl('');
    this.newsAuditNotify = new UntypedFormControl('');
    if (this.params.newstype === 'Flagged' || this.params.newstype === 'Dismissed') {
      if (this.data.currentnews && this.data.currentnews.auditInfo) {
        if (this.data.currentnews.subjects) {
          this.data.currentnews.subjects.forEach(item => {
            if (item.id && item.id !== '') {
              const matchingAuditEntities = this.auditEntities.filter(element => {
                return element.id === item.id;
              });
              if (matchingAuditEntities.length > 0) {
                matchingAuditEntities[0].selected = true;
              } else {
                this.customAuditEntities.push(item.subject);
              }
            } else {
              this.customAuditEntities.push(item.subject);
            }
          });
        }
        this.newsAuditContent = new UntypedFormControl({value: this.data.currentnews.auditInfo.content, disabled: true});
        if (this.params.newstype === 'Flagged') {
          this.newsAuditComment = new UntypedFormControl({value: this.data.currentnews.auditInfo.open_comment, disabled: true});
          this.newsAuditNotify = new UntypedFormControl({value: this.data.currentnews.auditInfo.notify, disabled: true});
          if (this.data.currentnews.resolved && this.data.currentnews.resolved === 'Resolved') {
            this.newsResolveComment = new UntypedFormControl({value: this.data.currentnews.auditInfo.resolve_comment, disabled: true});
          }
        }
        if (this.params.newstype === 'Dismissed') {
          this.newsAuditComment = new UntypedFormControl({value: this.data.currentnews.auditInfo.comment, disabled: true});
        }
      }
    }
    if (this.params.audit === 'true') {
      this.auditView = true;
      this.dismissView = false;
      this.resolveView = false;
      this.checkView = false;
      this.reportView = false;
    } else if (this.params.dismiss === 'true') {
      this.dismissView = true;
      this.auditView = false;
      this.resolveView = false;
      this.checkView = false;
      this.reportView = false;
    } else if (this.params.resolve === 'true') {
      this.dismissView = false;
      this.auditView = false;
      this.resolveView = true;
      this.checkView = false;
      this.reportView = false;
    } else if (this.params.check === 'true') {
      this.dismissView = false;
      this.auditView = false;
      this.resolveView = false;
      this.checkView = true;
      this.reportView = false;
    } else {
      this.dismissView = false;
      this.auditView = false;
      this.resolveView = false;
      this.checkView = false;
    }
    let pinReportTab;
    if (this.config.appConfig.setup.useStorage) {
      pinReportTab = JSON.parse(localStorage.getItem('pinReportTab'));
    }
    if (pinReportTab) {
      this.reportView = true;
      if (this.dismissView) {
        this.dismissView = false;
      }
      if (this.auditView) {
        this.auditView = false;
      }
      this.goReport();
    }
    if (this.params.highlight) {
      this.iHighlight();
    } else if (this.paramsURL.highlight) {
      this.iHighlight();
    }
    // if (this.params.type && (this.params.type === 'pne')) {
    //   this.highlightPNE();
    // }
    // if ((this.params && this.params.tag) || (this.apicall && this.apicall.tag)) {
    //   this.newsinfo.tags.forEach(item => {
    //     if (item.tag === this.tag) {
    //       this.mytag = item;
    //       this.scrollTo(this.mytag.spans, 0);
    //     }
    //   });
    // } else {
    //   this.eventindex = undefined;
    //   if (this.params && this.params.eventid) {
    //     const eventidEncounters = this.newsinfo.events.filter(event => {
    //       return (event.id === this.data.params.eventid);
    //     });
    //     if (eventidEncounters.length > 0) {
    //       this.eventid = eventidEncounters[0].id;
    //       const selectedEvent = eventidEncounters[0];
    //       this.selectedEvent = selectedEvent.id;
    //       this.onEventSelection({value: selectedEvent.id});
    //     }
    //   } else {
    //     this.newsinfo.matches.forEach(match => {
    //       if (match.entity.compound_key === this.inmatches.compound_key) {
    //         this.mytag = match;
    //         this.scrollTo(this.mytag.spans, 0);
    //         this.selectedMatch = match.entity.alpha_id;
    //         this.inrelated = this.instart;
    //         this.btnmatch = this.inmatches.compound_key;
    //       }
    //     });
    //   }
    // }
    this.eventindex = undefined;
    if (this.params && this.params.eventid) {
      const eventidEncounters = this.newsinfo.events.filter(event => {
        return (event.id === this.data.params.eventid);
      });
      if (eventidEncounters.length > 0) {
        this.eventid = eventidEncounters[0].id;
        const selectedEvent = eventidEncounters[0];
        this.selectedEvent = selectedEvent.id;
        this.onEventSelection({value: selectedEvent.id});
      }
    } else {
      if (this.params && this.params.tag) {
        const tag = this.params.tag.split(':');
        this.inmatches = {
          type: tag[1],
          alpha_id: tag[2],
          compound_key: tag[1] + ':' + tag[2]
        };
      }
      if (this.apicall && this.apicall.tag) {
        const tag = this.apicall.tag.split(':');
        this.inmatches = {
          type: tag[1],
          alpha_id: tag[2],
          compound_key: tag[1] + ':' + tag[2]
        };
      }
      this.newsinfo.matches.forEach(match => {
        if (match.entity.compound_key === this.inmatches.compound_key) {
          this.mytag = match;
          this.scrollTo(this.mytag.spans, 0);
          this.selectedMatch = match.entity.alpha_id;
          this.inrelated = this.instart;
          this.btnmatch = this.inmatches.compound_key;
        }
      });
    }
  }

  /**
   * toggling between chosen entities for news audit or dismiss
   */

  chooseEntity(index) {
    this.auditEntities[index].selected = !this.auditEntities[index].selected;
    const entities = this.auditEntities.filter(el => {
      return el.selected === true;
    });
    this.validateAuditEntities = entities.length !== 0;
  }

  /**
   * submit news for audit or dismiss
   */

  newsSubmit() {
    let entities = this.auditEntities.filter(el => {
      return el.selected === true;
    }).map(el => el.name);
    let entitiesIds = this.auditEntities.filter(el => {
      return el.selected === true;
    }).map(el => el.id);
    const customEntities = this.customAuditEntities;
    const customEntitiesIds = customEntities.map(item => {
      return '';
    });
    if (customEntities) {
      entities = entities.concat(customEntities);
      entitiesIds = entitiesIds.concat(customEntitiesIds);
    }
    const comment = this.newsAuditComment.value;
    const content = this.newsAuditContent.value;
    const email = this.newsAuditNotify.value;
    let valid = this.newsAuditNotify.valid;
    let emailList = [];
    if (email.trim() !== '' && valid) {
      emailList = [email];
    } else if (email.trim() === '') {
      valid = true;
    }
    const host = window.location.host;
    let data = {};
    if (valid && (entities.length !== 0) && (content !== '')) {
      if (this.auditView) {
        data = {
          cockpit_host: host,
          comment: comment,
          article_id: this.params.news,
          title: this.newsinfo.title,
          content: content,
          read_more_link: this.newsinfo.read_more_link || '',
          publish_time: this.newsinfo.publish_time,
          subjects: entities,
          subject_ids: entitiesIds,
          filter: (this.auth.filter) ? this.auth.filter : '',
          notify: emailList
        };
        this.auth.newsAudit(data).subscribe(() => {
          this.snackBar.open('News flagged for action!', 'OK', { duration: 5000 });
        });

        this.dialogRef.close('audited');

      } else if (this.dismissView) {
        data = {
          comment: comment,
          article_id: this.params.news,
          title: this.newsinfo.title,
          content: content,
          read_more_link: this.newsinfo.read_more_link || '',
          publish_time: this.newsinfo.publish_time,
          subjects: entities,
          subject_ids: entitiesIds,
          filter: (this.auth.filter) ? this.auth.filter : ''
        };
        this.auth.newsDismiss(data).subscribe(() => {
          this.snackBar.open('News dismissed!', 'OK', { duration: 5000 });
        });

        this.dialogRef.close('dismissed');

      }
    }
  }

  /**
   * resolve open news case
   */

  newsResolve() {
    const data = {
      comment: this.newsResolveComment.value,
      uid: this.data.currentnews.auditInfo.uid
    };
    this.auth.newsResolve(data).subscribe(() => {
      this.snackBar.open('News resolved!', 'OK', { duration: 5000 });
    });

    this.dialogRef.close('resolved');
  }

  /**
   * gets the content type and updates newsAuditContent form accordingly
   */
  getContent(type) {
    if (type === 'article') {
      this.contentType = 'article';
      this.newsAuditContent.setValue(this.newsinfo.content);
    } else if (type === 'event') {
      this.contentType = 'event';
      let eventContent = '';

      if (this.mytag && ((this.mytag.type === 'sub_event') || (this.mytag.type === 'event') || (this.mytag.type === 'super_event'))) {
        eventContent += this.mytag.name;
        eventContent += '\n\n';
        if (this.mytag.where === 'title') {
          eventContent += this.newsinfo.title.substring(this.mytag.span.start, this.mytag.span.end);
        } else if (this.mytag.where === 'content') {
          eventContent += this.newsinfo.content.substring(this.mytag.span.start, this.mytag.span.end);
        }
      } else {
        this.newsinfo.events.forEach((event, index) => {
          eventContent += (index !== 0) ? '\n\n' : '';
          eventContent += event.name;
          eventContent += '\n\n';
          if (event.where === 'title') {
            eventContent += this.newsinfo.title.substring(((event.span) ? event.span.start : null), ((event.span) ? event.span.end : null));
          } else if (event.where === 'content') {
            eventContent += this.newsinfo.content.substring(((event.span) ? event.span.start : null), ((event.span) ? event.span.end : null));
          }
        });
      }
      this.newsAuditContent.setValue(eventContent);
    } else if (type === 'custom') {
      this.contentType = 'custom';
      this.newsAuditContent.setValue('');
    } else if (type === 'edit') {
      this.contentType = 'custom';
    }
  }

  /**
   * trigger the target entities to display
   */
  clickTarget(match) {
    this.showHighlights = false;
    this.selectedEvent = null;
    this.selectedEventInstance = null;
    this.eventid = undefined;
    this.oldEventIndex = undefined;
    if (this.mytag && this.mytag.entity && ((this.mytag.entity.alpha_id + ':' + this.mytag.entity.alpha_id) === (match.entity.alpha_id + ':' + match.entity.alpha_id))) {
      // this.mytag = undefined;
      // this.btntag = undefined;
      // this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
      // this.inrelated = undefined;
    } else {
      this.mytag = match;
      this.scrollTo(this.mytag.spans, 0);
      this.btntag = undefined;
      this.inmatches = match.entity;
      this.inrelated = match.entity;
    }
  }

  /**
   * trigger the relate entities to display
   */
  clickRelate(match) {
    this.showHighlights = false;
    this.selectedEvent = null;
    this.selectedEventInstance = null;
    this.eventid = undefined;
    this.oldEventIndex = undefined;
    if (this.mytag !== match) {
      this.mytag = match;
      this.scrollTo(this.mytag.spans, 0);
      this.btntag = undefined;
      this.inmatches = match.entity;
      this.inrelated = match.entity;
      const aMatch = this.matches.filter(el => (el.entity.type + ':' + el.entity.alpha_id) === (match.entity.type + ':' + match.entity.alpha_id));
      if (aMatch.length > 0) {
        this.selectedMatch = aMatch[0].entity.alpha_id;
      } else {
        this.selectedMatch = null;
      }
    }
  }

  onMatchSelection(event) {
    const match = this.matches.filter(el => el.entity.alpha_id === event.value);
    if (match[0]) {
      this.clickTarget(match[0]);
    }
  }

  scrollTo(spans, index) {
    const howManyTitleSpans = spans.filter(span => span.location === 'title').length;
    const where = (index < howManyTitleSpans) ? 'title' : 'content';
    const relIndex = (where === 'title') ? index : (index - howManyTitleSpans);
    const targetId = 'target-' + relIndex + '-' + where;
    setTimeout(() => {
      const targetElement = document.getElementById(targetId);
      targetElement.scrollIntoView(false);
    }, 100);

  }

  onEventSelection(event) {
    const uniqueEvent = this.events.filter(el => el.id === event.value)[0];
    if (uniqueEvent) {
      const index = uniqueEvent.instances[0].index;
      this.selectedEventInstance = 0;
      this.iEvent(index);
    }
  }

  onEventSelection2(index) {
    this.events.forEach(e => {
      e.instances.forEach((i, indexI) => {
        if (i.index === index) {
          this.selectedEvent = e.id;
          this.selectedEventInstance = indexI;
        }
      });
    });
    this.iEvent(index);
  }

  navEvent(direction) {
    const uniqueEvent = this.events.filter(el => el.id === this.selectedEvent)[0];
    if (direction === 'next') {
      if (this.selectedEventInstance < (uniqueEvent.instances.length - 1)) {
        this.selectedEventInstance++;
        this.iEvent(uniqueEvent.instances[this.selectedEventInstance].index);
      }
    } else if (direction === 'previous') {
      if (this.selectedEventInstance > 0) {
        this.selectedEventInstance--;
        this.iEvent(uniqueEvent.instances[this.selectedEventInstance].index);
      }
    }
  }

  navEventInfo() {
    if (this.selectedEvent && (this.selectedEventInstance !== null)) {
      const uniqueEvent = this.events.filter(el => el.id === this.selectedEvent)[0];
      const totalInstances = uniqueEvent.instances.length;
      return {
        current: (this.selectedEventInstance + 1),
        total: totalInstances
      };
    } else {
      return {
        current: '',
        total: ''
      };
    }
  }

  /**
   * trigger tags to display
   */
  clickTag(tag) {
    this.showHighlights = false;
    this.selectedEvent = null;
    this.selectedEventInstance = null;
    this.eventid = undefined;
    this.oldEventIndex = undefined;
    if (this.mytag !== tag) {
      this.mytag = tag;
      this.scrollTo(this.mytag.spans, 0);
      this.btntag = undefined;
      this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
      this.inrelated = undefined;
      this.selectedMatch = null;
    }
  }

  /**
   * open report component
   */
  goReport() {
    let uniqueEvents = [];
    let uniqueParticipants = [];
    this.newsinfo.events.forEach(event => {
      uniqueEvents.push({
        id: event.id,
        type: event.type,
        name: event.name
      });
      event.participants.forEach(participant => {
        uniqueParticipants.push({
          alpha_id: participant.alpha_id,
          type: participant.type,
          name: participant.name
        });
      });
    });
    uniqueEvents = [...new Map(uniqueEvents.map(item => [item['id'], item])).values()];
    uniqueParticipants = [...new Map(uniqueParticipants.map(item => [item['alpha_id'], item])).values()];
    this.reportData = {
      newsId: this.params.news,
      newsInfo: this.newsinfo,
      matches: this.matches,
      tag: this.tag,
      email: this.user.email,
      project: this.config.appConfig.routing.project,
      events: uniqueEvents,
      participants: uniqueParticipants,
      inmatches: this.inmatches
    };
    if (this.routing.isReader()) {
      this.dialog.open(NewsReportboxComponent, {
        data: this.reportData
      });
    }
  }

  /**
   * add an annotation
   */
  addNote() {
    this.dialog.open(AnnotationComponent, {
      data: {
        newone: true,
        entity: this.params.type + ':' + this.params.id,
        newsday: moment(this.newsinfo.publish_time).format('YYYY-MM-DD')
      },
    });
  }

  toggleView(type) {
    if (type === 'report') {
      this.reportView = !this.reportView;
      if (this.reportView) {
        this.goReport();
      }
      if (this.dismissView) {
        this.dismissView = false;
      }
      if (this.auditView) {
        this.auditView = false;
      }
    } else if (type === 'audit') {
      this.auditView = !this.auditView;
      if (this.dismissView) {
        this.dismissView = false;
      }
      if (this.reportView) {
        this.reportView = false;
      }
    } else if (type === 'dismiss') {
      this.dismissView = !this.dismissView;
      if (this.auditView) {
        this.auditView = false;
      }
      if (this.reportView) {
        this.reportView = false;
      }
    } else if (type === 'resolve') {
      this.resolveView = !this.resolveView;
    } else if (type === 'check') {
      this.checkView = !this.checkView;
    }
  }

  /**
   * bookmark the news
   */
  addStar(id) {
    if (this.stars.includes(id)) {
      this.auth.delStars(id).subscribe(result => {
        this.stars.splice(this.stars.indexOf(id), 1);
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('news_curation', this.stars.join(','));
        }
      });
    } else {
      this.auth.addStars(id).subscribe(result => {
        this.stars.push(id);
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('news_curation', this.stars.join(','));
        }
      });
    }
  }

  /**
   * display the bookamark
   */
  iBookmark(id) {
    return this.stars.includes(id);
  }

  /**
   * navigate thru the news list
   */
  iNavigo(value) {
    this.dialogRef.close('changed');
    const index = this.data.arraid.indexOf(this.params.news);
    let id = this.data.arraid[index - 1];
    if (value === 'next') {
      id = this.data.arraid[index + 1];
    }
    const news = this.data.newsfeed.filter(item => {
      return item.id === id;
    })[0];
    // let highlight = null;
    // if ( news.hasOwnProperty('query_highlights') && (news.query_highlights.length !== 0) ) {
    //   highlight = encodeURIComponent(JSON.stringify(news.query_highlights));
    // }
    this.router.navigate([], {
      queryParams: { news: id, /*highlight: highlight,*/ audit: null, dismiss: null, resolve: null, check: null },
      queryParamsHandling: 'merge',
    });
  }

  /**
   *
   */
  iDisable(value) {
    if (!this.data.arraid) {
      return true;
    }
    const index = this.data.arraid.indexOf(this.params.news);
    if (value === 'previus' && (index === 0 || index === -1)) {
      return true;
    }
    if (value === 'next' && index === this.data.arraid.length - 1) {
      return true;
    }
    return false;
  }

  /**
   * close the dialog
   */
  iClose() {
    this.dialogRef.close();
    this.router.navigate([], {
      queryParams: {
        news: null,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: (this.auth.featureFlags.showNavigation) ? true : false
    });
  }

  /**
   * show the related entities
   */
  isRelated(array) {
    if (this.mytag && array) {
      const data = array.filter(item => item[1].visible.length > 0);
      return data.length > 0;
    }
    return false;
  }

  /**
   * check if event encounter tag is active
   */
  isActiveEvent(index, id) {
    if (this.eventsEncountersChecked) {
      return ((this.eventindex === index) && (this.eventindex === this.oldEventIndex));
    } else {
      return (this.mytag && this.mytag.type && (this.mytag.type === 'sub_event') && this.mytag.id && (this.mytag.id === id));
    }
  }

  /**
   * display the event highlight
   */
  iEvent(index) {
    this.eventsEncountersChecked = true;
    this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
    this.inrelated = undefined;
    this.selectedMatch = null;
    this.showHighlights = false;
    this.eventindex = index;
    if (this.eventindex === this.oldEventIndex) {
      // this.oldEventIndex = undefined;
      // this.mytag = undefined;
      // this.selectedEvent = null;
      // this.selectedEventInstance = null;
    } else {
      this.newsinfo.events.forEach((event, index2) => {
        if (index2 === index) {
          event['spans'] = [
            {
              location: event.where,
              start: (event.span) ? event.span.start : null,
              end: (event.span) ? event.span.end : null,
              sentiment: null,
            }
          ];
          this.mytag = event;
          this.scrollTo(this.mytag.spans, 0);
        }
      });
      this.oldEventIndex = index;
    }
  }

  /**
   * display url defined highlight
   */
  iHighlight() {
    this.selectedEvent = null;
    this.selectedEventInstance = null;
    this.eventid = undefined;
    this.oldEventIndex = undefined;
    this.selectedMatch = null;
    this.btntag = undefined;
    this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
    this.inrelated = undefined;
    this.showHighlights = !this.showHighlights;
    if (!this.showHighlights) {
      this.mytag = undefined;
    } else {
      let highlightSpans = [];
      if (this.params.highlight) {
        highlightSpans = JSON.parse(decodeURIComponent(this.params.highlight));
      } else if (this.paramsURL.highlight) {
        highlightSpans = JSON.parse(decodeURIComponent(this.paramsURL.highlight));
      }
      this.mytag = {
        spans: highlightSpans.map(fragment => {
          return {
            location: fragment.where,
            start: fragment.span.start,
            end: fragment.span.end,
            sentiment: null,
          };
        })
      };
      this.scrollTo(this.mytag.spans, 0);
    }
  }

  /**
   * search article's title and content, returns PNE's surface matching spans
   */
  // highlightPNE() {
  //   this.showHighlightsPNE = !this.showHighlightsPNE;
  //   if (!this.showHighlightsPNE) {
  //     this.mytag = undefined;
  //   } else {
  //     const highlightSpans = [];
  //     const lengthPNE = this.auth.pne.pne.surface.length;
  //     const indicesTitle = this.getIndicesOf(this.newsinfo.title, this.auth.pne.pne.surface);
  //     if (indicesTitle.length > 0) {
  //       indicesTitle.forEach(item => {
  //         highlightSpans.push({
  //           location: 'title',
  //           start: item,
  //           end: item + lengthPNE,
  //           sentiment: null
  //         });
  //       });
  //     }
  //     const indicesContent = this.getIndicesOf(this.newsinfo.content, this.auth.pne.pne.surface);
  //     if (indicesContent.length > 0) {
  //       indicesContent.forEach(item => {
  //         highlightSpans.push({
  //           location: 'content',
  //           start: item,
  //           end: item + lengthPNE,
  //           sentiment: null
  //         });
  //       });
  //     }
  //     this.mytag = {
  //       spans: highlightSpans
  //     };
  //   }
  // }

  /**
   * get indices of all occurrences of one string in another
   */
  getIndicesOf(sourceStr, searchStr) {
    return [...sourceStr.matchAll(new RegExp(searchStr, 'gi'))].map(a => a.index);
  }

  /**
   * get link with highlights
   */
  getHighlightLink() {
    if (!this.showHighlights) {
      this.iHighlight();
    }
    const path = window.location.host + '/cockpit/news/portfolio?news=' + this.params.news + '&highlight=' + this.params.highlight;
    this.copyMessage(path);
    this.snackBar.open('Link with highlights copied to clipboard.', 'OK', { duration: 5000 });
  }

  /**
   * get news reader link
   */
  getNewsReaderLink() {
    let path = window.location.origin + '/widget/reader?news=' + this.params.news;
    if (this.params.time) {
      path += '&time=' + this.params.time;
    }
    if (this.params.highlight && (this.auth.featureFlags.gettingHighlightsEnabled && (this.auth.userdata.roles?.includes('ADMIN') || this.auth.userdata.groups?.includes('ADMIN') || this.newsinfo.document_categorization.is_licensed_content))) {
      path += '&highlight=' + this.params.highlight;
    }
    this.copyMessage(path);
    this.snackBar.open('Link to the article copied to clipboard.', 'OK', { duration: 5000 });
  }

  /**
   * get news original link
   */
  getNewsOriginalLink(link) {
    const path = link;
    this.copyMessage(path);
    this.snackBar.open('Link to the original article copied to clipboard.', 'OK', { duration: 5000 });
  }

  copyMessage(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  addCustomEntity(event) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.customAuditEntities.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.customEntitiesControl.setValue(null);
  }

  removeCustomEntity(entity) {
    const index = this.customAuditEntities.indexOf(entity);
    if (index >= 0) {
      this.customAuditEntities.splice(index, 1);
    }
  }

  selectedCustomEntity(event) {
    this.customAuditEntities.push(event.option.viewValue);
    this.customEntitiesInput.nativeElement.value = '';
    this.customEntitiesControl.setValue(null);
  }

  filterEntities(value) {
    const filterValue = value.toLowerCase();
    return this.addedEntities.filter(entity => entity.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * open help component
   */
  openHelp() {
    this.dialog.open(HelpComponent, {
      data: { component: 'article' }
    });
  }

}
