import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QueryDialogComponent } from '../query-dialog.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-group',
  templateUrl: './query-group.component.html',
  styleUrls: ['./query-group.component.scss']
})
export class QueryGroupComponent implements OnInit, OnChanges {

  groupForm: UntypedFormGroup;
  @Input('data') data: any;

  constructor(
    private query: QueryDialogComponent,
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
    this.groupForm = this.data.form;
  }

  addGroup() {
    this.query.addGroup();
  }

  delGroup() {
    this.query.delGroup(this.data.index);
  }

  addRule() {
    this.query.addRule(this.data.index, this.data.level);
  }

  delRule(index) {
    if (this.data.form.controls.filters.controls.length === 1 && this.data.level === 1) {
      this.delGroup();
    } else {
      this.query.delRule(this.data.level, this.data.index, index);
    }
  }

  fieldSelect(item) {
    item.controls.name.value = '';
    item.controls.value.value = '';
  }



}
