<div class="board-event">
  <div class="event-main">
    <div class="event" *ngFor="let event of event1; index as index" (click)="iEvent1(event)"
      [class.active]="ievent1==event.entity.id">
      <div class="main">
        <div class="name">{{event.entity.name}}</div>
        <div class="count">{{event.count}}</div>
      </div>
    </div>
  </div>
  <div class="event-main" *ngIf="event2">
    <div class="event" *ngFor="let event of event2; index as index" (click)="iEvent2(event)"
      [class.active]="ievent2==event.entity.id">
      <div class="main">
        <div class="name">{{event.entity.name}}</div>
        <div class="count">{{event.count}}</div>
      </div>
    </div>
  </div>
  <div class="event-main" *ngIf="event3">
    <div class="event" *ngFor="let event of event3; index as index" (click)="iEvent3(event)"
      [class.active]="ievent3==event.entity.id">
      <div class="main">
        <div class="name">{{event.entity.name}}</div>
        <div class="count">{{event.count}}</div>
      </div>
    </div>
  </div>
  <div class="event-main" *ngIf="event4">
    <div class="event" *ngFor="let event of event4; index as index" (click)="iEvent4(event)"
      [class.active]="ievent4==event.entity.alpha_id">
        <img *ngIf="event.entity.media" [src]="event.entity.media.uni" alt="">
      <div class="main">
        <div class="type">{{event.entity.type|ucfirst}}</div>
        <div class="name">{{event.entity.name}}</div>
        <div class="count">{{event.count}}</div>
      </div>
    </div>
  </div>
</div>