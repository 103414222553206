import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';

/**
 * iframe widget display the single component
 */
@Component({
  selector: 'app-widget-iframe',
  templateUrl: './widget-iframe.component.html',
  styleUrls: ['./widget-iframe.component.scss']
})
export class WidgetIframeComponent {

  /**
   * query parameters url
   */
  params: any;

  previousValue: any;

  /**
   * basic info of the entity
   */
  $titolo: Observable<YukkApi>;

  /**
   * basic info for the news reader
   */
  article: any;

  /**
   * data for the story reader
   */
  newsfeedStory: any;

  /**
   * basic info for the news reader
   */
  match: any;

  /**
   * basic info for the news reader
   */
  tag: any;

  /**
   * query parameters subscribe and info data request
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private yukkApi: YukkApi,
    public routing: RoutingService,
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.isin) {
        this.yukkApi.isinParse([params.isin]).subscribe(result => {
          const isinObject = Object.values(result)[0];
          if (isinObject && isinObject['compound_key']) {
            const type = isinObject['compound_key'].split(':')[0];
            const id = isinObject['compound_key'].split(':')[1];
            this.router.navigate([], {
              queryParams: {
                isin: null,
                type: type,
                id: id
              },
              queryParamsHandling: 'merge',
              replaceUrl: false
            });
          }
        });
      } else if ( params.type === 'portfolio' && params.id ) {
        router.navigate( ['/widget/portfolio/chart'], {
          queryParams: {
            id: params.id,
            feed: params.feed
          }, queryParamsHandling: '', replaceUrl: false
        } );
      } else if ( params.type === 'portfolio' && params.name ) {
        router.navigate( ['/widget/portfolio/chart'], {
          queryParams: {
            feed: params.feed,
            name: params.name,
            entities: params.entities
          }, queryParamsHandling: '', replaceUrl: false
        } );
      }
      this.params = params;
      this.$titolo = this.yukkApi.isentiment(this.params, true);
      if (this.routing.isReader() && params.news) {
        if (params.type && params.id) {
          this.match = params.type + ':' + params.id;
        }
        if (params.tag) {
          this.tag = params.tag;
        }
        this.yukkApi.newsviewNew({ id: params.news, params: params }).subscribe(result => {
          this.article = result;
        }, error => {
          if (params.redirectURL) {
            const redirectURL = decodeURIComponent(params.redirectURL);
            window.location.href = redirectURL;
          } else {
            this.yukkApi.newsviewNew({ id: params.news, params: Object.assign({}, params, {time: (params.time ? params.time : 365)}) }).subscribe(result => {
              this.article = result;
            }, error2 => {
              this.yukkApi.newsviewNew({ id: params.news, params: Object.assign({}, params, {time: (params.time ? params.time : 1095)}) }).subscribe(result => {
                this.article = result;
              }, error3 => {
                this.router.navigate(['/message/autherror']);
              });
            });
          }
        });
      }
      if (this.routing.isStoryReader() && params.story && this.routing.reFresh(params, this.previousValue, ['story'])) {
        this.yukkApi.newsfeedStory({ storyId: params.story, params: params }).subscribe(result => {
          this.newsfeedStory = {
            stories: [result]
          };
        }, error => {
          if (params.redirectURL) {
            const redirectURL = decodeURIComponent(params.redirectURL);
            window.location.href = redirectURL;
          } else {
            this.router.navigate(['/message/autherror']);
          }
        });
      }
      this.previousValue = params;
    });
  }

}
