import { Component, OnInit } from '@angular/core';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';

@Component({
  selector: 'app-board-event',
  templateUrl: './board-event.component.html',
  styleUrls: ['./board-event.component.scss']
})
export class BoardEventComponent {

  event1: any;
  event2: any;
  event3: any;
  event4: any;

  ievent1: string;
  ievent2: string;
  ievent3: string;
  ievent4: string;

  treemap = ['', '', '', ''];
  params: any;
  previousValue: any;

  constructor(
    private yukkApi: YukkApi,
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.routing.reFresh(this.params, this.previousValue, ['eventid'])) {

        if (!this.event1 || !this.params.eventid) {
          this.event1 = undefined;
          this.event2 = undefined;
          this.event3 = undefined;
          this.event4 = undefined;
          this.ievent1 = '';
          this.ievent2 = '';
          this.ievent3 = '';
          this.ievent4 = '';
          this.yukkApi.evenTreemap('all', 'all', this.params).subscribe(result => {
            this.event1 = result.treemap.children;
            this.iTreemap();
          });
        } else {
          this.iTreemap();
        }
      }
      this.previousValue = this.params;


    });
  }

  iTreemap() {
    if (this.params.treemap) {

      this.treemap = this.params.treemap.split(',');
      this.treemap.forEach((item, index) => {
        if (index === 0 && item !== '') {
          this.ievent1 = item;
          this.ievent2 = undefined;
          this.event2 = undefined;
          this.event3 = undefined;
          this.event4 = undefined;
          this.event1.forEach(item2 => {
            if (item2.entity.id === this.ievent1) {
              this.event2 = item2.children;
            }
          });
        }
        if (index === 1 && item !== '') {
          this.ievent2 = item;
          this.ievent3 = undefined;
          this.event3 = undefined;
          this.event4 = undefined;
          this.event2.forEach(item2 => {
            if (item2.entity.id === this.ievent2) {
              this.event3 = item2.children;
            }
          });
        }
        if (index === 2 && item !== '') {
          this.ievent3 = item;
          this.event4 = undefined;
          this.ievent4 = undefined;
          this.event3.forEach(item2 => {
            if (item2.entity.id === this.ievent3) {
              this.event4 = item2.children;
            }
          });
        }
        if (index === 3 && item !== '') {
          this.ievent4 = item;
        }
      });
    }
  }

  iEvent1(event) {
    const treemap = [event.entity.id];
    this.router.navigate([], {
      queryParams: {
        eventype: event.entity.type,
        eventid: event.entity.id,
        treemap: treemap.join(',')
      },
      queryParamsHandling: 'merge'
    });
  }
  iEvent2(event) {
    const treemap = [this.treemap[0], event.entity.id];
    this.router.navigate([], {
      queryParams: {
        eventype: event.entity.type,
        eventid: event.entity.id,
        treemap: treemap.join(',')
      },
      queryParamsHandling: 'merge'
    });
  }
  iEvent3(event) {
    const treemap = [this.treemap[0], this.treemap[1], event.entity.id];
    this.router.navigate([], {
      queryParams: {
        eventype: event.entity.type,
        eventid: event.entity.id,
        treemap: treemap.join(',')
      },
      queryParamsHandling: 'merge'
    });
  }
  iEvent4(event) {
    const treemap = [this.treemap[0], this.treemap[1], this.treemap[2], event.entity.alpha_id];
    this.router.navigate([], {
      queryParams: {
        eventype: event.entity.type,
        eventid: event.entity.alpha_id,
        treemap: treemap.join(',')
      },
      queryParamsHandling: 'merge'
    });
  }

}
