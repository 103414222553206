import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { YukkApi } from '../../service/yukkapi.service';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit, OnChanges {

  /**
   * url parameters
   */
  params: any;

  /**
   * recommendations input value
   */
  mySearch = '';

  /**
   * previous starred terms searched for recommendations
   */
  previousSearchesStarred = [];

  /**
   * previous unstarred terms searched for recommendations
   */
  previousSearchesUnstarred = [];

  /**
   * how many of unstarred searches to keep in the database
   */
  howManyUnstarred = 10;

  /**
   * recommended articles
   */
  newsfeedDataFeed: any;

  constructor(
    private yukkApi: YukkApi,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<RecommendationsComponent>,
  ) {

    this.route.queryParams.subscribe(params => {
      if (this.routing.isNewsletter() && this.auth.newsletterParams) {
        this.params = this.auth.newsletterParams;
      } else {
        this.params = params;
      }
    });

  }

  ngOnInit() {
    this.auth.getUserSettings('recommendationSearch').subscribe(result => {
      if (result.entry) {
        const resultObj = JSON.parse(result.entry);
        this.previousSearchesStarred = resultObj.starred ? resultObj.starred : [];
        this.previousSearchesUnstarred = resultObj.unstarred ? resultObj.unstarred : [];
      } else {
        this.previousSearchesStarred = [];
        this.previousSearchesUnstarred = [];
      }
    });
  }

  ngOnChanges() {

  }

  close() {
    this.dialog.closeAll();
  }

  search() {
    if (this.mySearch.trim()) {
      const titles = [this.mySearch.trim()];
      this.yukkApi.newsfeed({
        returnRecommendations: true,
        params: this.params,
        custom: {
          title: titles,
          size: 50
        }
      }).subscribe(result => {
        const recommendations = result.news;
        this.newsfeedDataFeed = {
          news: recommendations,
          news_total_count: recommendations.length,
          fromAssistedBookmarking: true
        };
      });
      if (!this.previousSearchesStarred.includes(this.mySearch.trim()) && !this.previousSearchesUnstarred.includes(this.mySearch.trim())) {
        this.previousSearchesUnstarred.unshift(this.mySearch.trim());
        if (this.previousSearchesUnstarred.length > this.howManyUnstarred) {
          this.previousSearchesUnstarred.pop();
        }
      } else if (this.previousSearchesUnstarred.includes(this.mySearch.trim())) {
        this.previousSearchesUnstarred = this.previousSearchesUnstarred.filter(item => item !== this.mySearch.trim());
        this.previousSearchesUnstarred.unshift(this.mySearch.trim());
      }
      const recommendationSearchValue = JSON.stringify({
        starred: this.previousSearchesStarred,
        unstarred: this.previousSearchesUnstarred
      });
      this.auth.setUserSettings('recommendationSearch', recommendationSearchValue).subscribe();
    }
  }

  isStarred(item) {
    return this.previousSearchesStarred.includes(item);
  }

  star(item) {
    if (item) {
      if (!this.previousSearchesStarred.includes(item.trim())) {
        this.previousSearchesStarred.unshift(item.trim());
      }
      if (this.previousSearchesUnstarred.includes(item.trim())) {
        this.previousSearchesUnstarred = this.previousSearchesUnstarred.filter(el => el !== item.trim());
      }
      const recommendationSearchValue = JSON.stringify({
        starred: this.previousSearchesStarred,
        unstarred: this.previousSearchesUnstarred
      });
      this.auth.setUserSettings('recommendationSearch', recommendationSearchValue).subscribe();
    }
  }

  unstar(item) {
    if (item) {
      this.previousSearchesStarred = this.previousSearchesStarred.filter(el => el !== item.trim());
      this.previousSearchesUnstarred.unshift(item.trim());
      if (this.previousSearchesUnstarred.length > this.howManyUnstarred) {
        this.previousSearchesUnstarred.pop();
      }
      const recommendationSearchValue = JSON.stringify({
        starred: this.previousSearchesStarred,
        unstarred: this.previousSearchesUnstarred
      });
      this.auth.setUserSettings('recommendationSearch', recommendationSearchValue).subscribe();
    }
  }

}
