import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import { yukka, dev } from './hierarchy';

/**
* This is the treemap of the cockpit/news/market route
*/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-board',
  templateUrl: './news-board.component.html',
  styleUrls: ['./news-board.component.scss']
})
export class NewsBoardComponent {

  /**
  * url parameters
  */
  params: any;

  /**
  * menu hierarchies
  */
  hierarchies: any = [];

  /**
  * query parameters subscribe and select the hierarchies
  */
  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    public config: ConfigService,
  ) {
    this.hierarchies = dev;
    if (this.config.appConfig.environment === 'dev') { this.hierarchies = dev; }
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  checkCurrentTabIndex(item, allItems): boolean {
    const selectedIndex = allItems.findIndex(h => h.id === this.params.id);
    const itemIndex = allItems.findIndex(h => h.id === item.id);
    if (selectedIndex === 0 && itemIndex === 1) {
      return false;
    } else if (selectedIndex === 2 && itemIndex === 0) {
      return false;
    }
    return true;
  }
}
