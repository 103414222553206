<div class="query-folio" [class.widget]="routing.isWidget()" [class.full-size]="routing.isQuery() && (params.id === 'default')">
  <!--<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let tab of auth.querys|orderBy:'name'" [active]="tab.uid==params.groupId"
      (click)="inTab(tab.uid)">{{tab.name}}</a>
  </nav>-->
  <div class="contipo">
    <div class="main-portfolio" *ngIf="auth.querys">
      <!--<div class="nodata" *ngIf="auth.querys.length==0">
        <p class="text">In a collection, you can add multiple different types and combinations of entities such as topics, events, companies and people through a boolean query. A combination of these queries can then be saved to a “Collection”</p>
        <button mat-stroked-button (click)="iCreate()">Create a collection</button>
      </div>-->
      <ng-container>
        <ng-template #loading>
          <div class="loading">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
        </ng-template>
        <ng-container *ngIf="items else loading">

          <!--<div class="submenu" *ngIf="routing.isMain() && (params.id !== 'default')">
            <mat-icon class="menu" matTooltip="Change View" [class.icon-view1]="collectionView === 'mosaic'" [class.icon-view2]="collectionView === 'list'" [matMenuTriggerFor]="menuView">more_vert</mat-icon>
            <mat-menu #menuView="matMenu">
              <button mat-menu-item (click)="changeView('list')">List</button>
              <button mat-menu-item (click)="changeView('mosaic')">Mosaic</button>
            </mat-menu>
          </div>-->

          <div class="submenu" *ngIf="routing.isMain()">
            <mat-icon class="menu icon-view2" matTooltip="Manage Collections" [matMenuTriggerFor]="menuCollectionManagement">more_vert</mat-icon>
            <mat-menu #menuCollectionManagement="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="menuImportCollection">Import</button>
              <button mat-menu-item [matMenuTriggerFor]="menuExportCollection" *ngIf="routing.isDev() || auth.featureFlags.exportCollections">Export</button>
              <button mat-menu-item *ngIf="(params.id !== 'default')" (click)="iManage()">Manage</button>
            </mat-menu>
            <mat-menu #menuImportCollection="matMenu">
              <button mat-menu-item *ngIf="auth.featureFlags.importQueries && (params.id === 'default')" (click)="fileInputQuery.click()">Queries (from files)</button>
              <input #fileInputQuery class="inptfile" type="file" accept=".json" value="" (click)="fileInputQuery.value = null" (change)="iImport($event, 'query')" multiple/>
              <button mat-menu-item *ngIf="params.id !== 'default'" (click)="iImport(null, 'query')">Saved queries</button>
              <!--<button mat-menu-item *ngIf="auth.featureFlags.importCollections" (click)="fileInputCollection.click()">Collections (from files)</button>
              <input #fileInputCollection class="inptfile" type="file" accept=".json" value="" (click)="fileInputCollection.value = null" (change)="iImport($event, 'collection')" multiple/>-->
            </mat-menu>
            <mat-menu #menuExportCollection="matMenu">
              <!--<button mat-menu-item (click)="iExport('events')" *ngIf="routing.isDev()">Events (xlsx)</button>-->
              <button mat-menu-item *ngIf="auth.featureFlags.exportQueries && (params.id === 'default')" (click)="iExport('queries')">Queries (json)</button>
              <button mat-menu-item (click)="iExport('collection')" *ngIf="auth.featureFlags.exportCollections && (params.id !== 'default')">Collection (json)</button>
              <button mat-menu-item (click)="iExport('stats')" *ngIf="auth.featureFlags.exportCollections && (params.id !== 'default')">Statistics (xlsx)</button>
            </mat-menu>
          </div>

          <div class="folio-entity" [class.empty]="items.length==0" *ngIf="(params.id !== 'default') && (collectionView === 'list')">
            <div class="nodata" *ngIf="items.length==0">
              <button mat-stroked-button (click)="iQuery()">Create a query</button>
            </div>
            <div class="table-main" *ngIf="items.length!=0">
              <div class="titolo">
                <div class="box-table">
                  <div class="box-table-cont">
                    <div class="tito">
                      <span class="name" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">
                        <span class="sort" (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Query </span>
                        <span [class.no-sort]="!(params.sort==='abc'||params.sort==='-abc')">
                          {{((params.sort==='abc') ? -1 : 1 )|trend}}
                        </span>
                      </span>
                    </div>
                    <div class="info">
                      <div class="cont">
                        <span class="count" [class.sort-active]="params.sort==='count'||params.sort==='-count'">
                          <span class="sort" (click)="goSort('count')" matTooltip="Total number of news articles" matTooltipPosition="above">Vol </span>
                          <span [class.no-sort]="!(params.sort==='count'||params.sort==='-count')">
                            {{((params.sort==='count') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span class="ratio" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'">
                          <span class="sort" (click)="goSort('ratio')" matTooltip="The relative change in the total number of news articles from the previous period" matTooltipPosition="above">ΔVol </span>
                          <span [class.no-sort]="!(params.sort==='ratio'||params.sort==='-ratio')">
                            {{((params.sort==='ratio') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span class="percent" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'">
                          <span class="sort" (click)="goSort('senti')" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">Sent </span>
                          <span [class.no-sort]="!(params.sort==='senti'||params.sort==='-senti')">
                            {{((params.sort==='senti') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span class="numb" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
                          <span class="sort" (click)="goSort('trend')" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">ΔSent </span>
                          <span [class.no-sort]="!(params.sort==='trend'||params.sort==='-trend')">
                            {{((params.sort==='trend') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-cont">
                <div class="box-table" [class.active1]="(params.newsfeedFromQuery == item.query.uid) && (params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti')" [class.active2]="(params.newsfeedFromQuery == item.query.uid) && !(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti')" [class.no-color]="!(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti')" *ngFor="let item of items | orderBy: myOrder" [style.background]="(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti') ? getBackground(item) : ''">
                  <div class="edit" *ngIf="auth.featureFlags.editQueries" (click)="iEdit(item)">
                    <div class="edit-border" [style.background]="!(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti') ? getBackground(item) : ''"></div>
                    <div class="edit-icon">
                      <mat-icon>mode_edit</mat-icon>
                    </div>
                  </div>
                  <div class="box-table-cont" (click)="addTag(item)">
                    <div class="tito" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'" [class.highlight-query]="currentQuery === item.query.uid" (mouseenter)="onMouseEnterName(item.query.uid)" (mouseleave)="onMouseLeaveName()" (click)="$event.stopPropagation(); iChart(item);">{{item.query ? item.query.name : ''}}</div>
                    <div class="info">
                      <div class="cont" *ngIf="item.sentiment">
                        <span class="count" [class.sort-active]="params.sort==='count'||params.sort==='-count'" matTooltip="Total number of news articles" matTooltipPosition="above">
                          <span *ngIf="item.sentiment">{{item.sentiment.count}}</span>
                        </span>
                        <span class="ratio" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'" matTooltip="The relative change in the total number of news articles from the previous period" matTooltipPosition="above">
                          <span *ngIf="item.sentiment" class="pero">&#215;</span>
                          <span *ngIf="item.sentiment">{{item.sentiment.volume_ratio | round:2}}</span>
                        </span>
                        <span class="percent" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">
                          <span *ngIf="item.sentiment">{{item.sentiment.sentiment|percent}}</span>
                        </span>
                        <span class="numb" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">
                          <span *ngIf="item.sentiment">{{item.sentiment.sentiment_delta*100|round|trend}}</span>
                          <span *ngIf="item.sentiment">{{item.sentiment.sentiment_delta*100|round|negative}}</span>
                        </span>
                      </div>
                      <div class="loading-item" *ngIf="!item.sentiment">
                        <mat-spinner [diameter]="18"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="folio-entity default" [class.empty]="items.length==0" *ngIf="(params.id === 'default')">
            <div class="nodata" *ngIf="items.length==0">
              <button mat-stroked-button [matMenuTriggerFor]="menuCreate">Create</button>
              <span class="orthat">OR</span>
              <button mat-stroked-button [matMenuTriggerFor]="menuImport">Import</button>
              <mat-menu #menuCreate="matMenu">
                <button mat-menu-item (click)="iCreate()">Create a collection</button>
                <button mat-menu-item (click)="iQuery()">Create a query</button>
              </mat-menu>
              <mat-menu #menuImport="matMenu">
                <button mat-menu-item (click)="loadSampleCollections()">Pre-load Sample Collections</button>
                <button mat-menu-item *ngIf="auth.featureFlags.importCollections" (click)="fileInputCollection.click()">Collections (from files)</button>
                <input #fileInputCollection class="inptfile" type="file" accept=".json" value="" (click)="fileInputCollection.value = null" (change)="iImport($event, 'collection')" multiple/>
              </mat-menu>
            </div>
            <div class="table-main" *ngIf="items.length!=0">
              <div class="titolo">
                <div class="box-table">
                  <div class="box-table-cont">
                    <div class="tito">
                      <span class="name" [class.sort-active]="params.defaultsort==='abc'||params.defaultsort==='-abc'">
                        <span class="sort" (click)="goDefaultSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Query </span>
                        <span [class.no-sort]="!(params.defaultsort==='abc'||params.defaultsort==='-abc')">
                          {{((params.defaultsort==='abc') ? -1 : 1 )|trend}}
                        </span>
                      </span>
                    </div>
                    <div class="info">
                      <div class="cont">
                        <span class="collection" *ngIf="auth.featureFlags.linkQueries">
                          <span class="sort" matTooltip="Query's description" matTooltipPosition="above">Description</span>
                        </span>
                        <span class="collection">
                          <span class="sort" matTooltip="Collections to which a query belongs" matTooltipPosition="above">Collections</span>
                        </span>
                        <span class="collection" *ngIf="auth.featureFlags.linkQueries">
                          <span class="sort" matTooltip="Queries to which a query is embedded" matTooltipPosition="above">Embedded In</span>
                        </span>
                        <span class="collection">
                          <span class="sort" matTooltip="Creation date of a query" matTooltipPosition="above">Created On</span>
                        </span>
                        <span class="collection">
                          <span class="sort" matTooltip="Last update of a query" matTooltipPosition="above">Updated On</span>
                        </span>
                      </div>
                    </div>
                    <div class="actions"></div>
                  </div>
                </div>
              </div>
              <div class="item-cont">
                <div class="box-table" *ngFor="let item of items | orderBy: myOrder">
                  <div class="edit" *ngIf="auth.featureFlags.editQueries" (click)="iEdit(item)">
                    <div class="edit-border"></div>
                    <div class="edit-icon">
                      <mat-icon>mode_edit</mat-icon>
                    </div>
                  </div>
                  <div class="box-table-cont" (click)="iChart(item)">
                    <div class="tito" [class.sort-active]="params.defaultsort==='abc'||params.defaultsort==='-abc'">{{item.query ? item.query.name : ''}}</div>
                    <div class="info">
                      <div class="cont">
                        <div class="collection" *ngIf="auth.featureFlags.linkQueries" matTooltip="{{item.query.description}}" matTooltipPosition="above">
                          <span>{{item.query.description}}</span>
                        </div>
                        <div class="collection" matTooltip="{{listContainingCollections(item)}}" matTooltipPosition="above">
                          <span>{{listContainingCollections(item)[0]}}{{(listContainingCollections(item).length > 1) ? ' &#43;' + (listContainingCollections(item).length - 1) : ''}}</span>
                        </div>
                        <div class="collection" *ngIf="auth.featureFlags.linkQueries" matTooltip="{{listContainingQueries(item)}}" matTooltipPosition="above">
                          <span>{{listContainingQueries(item)[0]}}{{(listContainingQueries(item).length > 1) ? ' &#43;' + (listContainingQueries(item).length - 1) : ''}}</span>
                        </div>
                        <div class="collection">
                          <span>{{item.query.created_on.split('T')[0]}}</span>
                        </div>
                        <div class="collection">
                          <span>{{item.query.updated_on.split('T')[0]}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="actions" (click)="$event.stopPropagation(); selectQuery(item.query);">
                      <mat-checkbox></mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <div class="folio-editin">
          <!--<button mat-raised-button class="goindex" [matMenuTriggerFor]="menuImport">
            <mat-icon>cloud_download</mat-icon>
            <span>IMPORT</span>
            <mat-menu #menuImport="matMenu">
              <button mat-menu-item *ngIf="auth.featureFlags.importQueries && (params.id === 'default')" (click)="fileInputQuery.click()">Queries (from files)</button>
              <input #fileInputQuery class="inptfile" type="file" accept=".json" value="" (click)="fileInputQuery.value = null" (change)="iImport($event, 'query')" multiple/>
              <button mat-menu-item *ngIf="params.id !== 'default'" (click)="iImport(null, 'query')">Saved queries</button>
              <button mat-menu-item *ngIf="auth.featureFlags.importCollections" (click)="fileInputCollection.click()">Collections (from files)</button>
              <input #fileInputCollection class="inptfile" type="file" accept=".json" value="" (click)="fileInputCollection.value = null" (change)="iImport($event, 'collection')" multiple/>
            </mat-menu>
          </button>-->
          <button mat-raised-button *ngIf="auth.featureFlags.addQueries" class="goindex" (click)="iQuery()" matTooltip="Add Query">
            <mat-icon>add</mat-icon>
            <!--<span>ADD</span>-->
          </button>
          <!--<button mat-raised-button *ngIf="(params.id !== 'default')" class="goindex" (click)="iModify()">
            <mat-icon>border_color</mat-icon>
            <span>EDIT</span>
          </button>-->
          <button mat-raised-button *ngIf="(params.id === 'default') && auth.featureFlags.editQueries" class="goindex" (click)="iDelete()" matTooltip="Remove Queries">
            <mat-icon>clear</mat-icon>
            <!--<span>DELETE</span>-->
          </button>
          <!--<button mat-raised-button class="goindex" (click)="iCreate()">
            <mat-icon>create_new_folder</mat-icon>
            <span>NEW</span>
          </button>-->
          <!--<button mat-raised-button class="goindex" [matMenuTriggerFor]="menuExport" *ngIf="routing.isDev() || auth.featureFlags.exportCollections">
            <mat-icon>save</mat-icon>
            <span>EXPORT</span>
            <mat-menu #menuExport="matMenu">
              <button mat-menu-item (click)="iExport('events')" *ngIf="routing.isDev()">Events</button>
              <button mat-menu-item *ngIf="auth.featureFlags.exportQueries && (params.id === 'default')" (click)="iExport('queries')">Queries</button>
              <button mat-menu-item (click)="iExport('collection')" *ngIf="auth.featureFlags.exportCollections && (params.id !== 'default')">Collection</button>
            </mat-menu>
          </button>-->
          <!--<button mat-raised-button *ngIf="(params.id !== 'default')" class="goindex" (click)="iManage()">
            <mat-icon>assignment_turned_in</mat-icon>
            <span>MANAGE</span>
          </button>-->
        </div>
      </ng-container>
    </div>
  </div>
</div>
