<div class="help-viewer-main">
    <div class="help-viewer-header">
        <div class="help-viewer-title">Help manual - Our comprehensive Guide on using YUKKA Lab</div>
        <mat-icon [mat-dialog-close]="true">close</mat-icon>
    </div>
    <div class="help-viewer-content">
        <ngx-extended-pdf-viewer *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment')" [src]="'https://yl-markcom.s3.eu-central-1.amazonaws.com/guides/YUKKA+Help+Guide.pdf'" [showDownloadButton]="false" [showOpenFileButton]="false" [showPrintButton]="false" [zoom]="'auto'"></ngx-extended-pdf-viewer>
        <ngx-extended-pdf-viewer *ngIf="!(auth.scorelabSettings.defaultScore === 'sentiment')" [src]="'https://yl-markcom.s3.eu-central-1.amazonaws.com/guides/YUKKA+ScoreLab+Help+Guide.pdf'" [showDownloadButton]="false" [showOpenFileButton]="false" [showPrintButton]="false" [zoom]="'auto'"></ngx-extended-pdf-viewer>
        <!--<div  style="width: 100%; height: 100%; position: relative;">
            <iframe *ngIf="!routing.isScorelab()" src="https://drive.google.com/file/d/1cU5UU5fFMWU36xB6TpIL8cgmru3NUD9I/preview" width="100%" height="100%"></iframe>
            <div style="width: 80px; height: 80px; position: absolute; opacity: 0; right: 0px; top: 0px;">&nbsp;</div>
        </div>-->
        <!--<iframe *ngIf="!routing.isScorelab()" src="https://drive.google.com/file/d/1cU5UU5fFMWU36xB6TpIL8cgmru3NUD9I/preview" width="100%" height="100%" allow="autoplay"></iframe>
        <iframe *ngIf="routing.isScorelab()" src="https://drive.google.com/file/d/1cai_trapi1ikshfnfByFaSiymm9M6n-Z/preview" width="100%" height="100%" allow="autoplay"></iframe>-->
        <!--<embed *ngIf="!routing.isScorelab()" src="https://yl-markcom.s3.eu-central-1.amazonaws.com/guides/YUKKA+Help+Guide.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%" height="100%">
        <embed *ngIf="routing.isScorelab()" src="https://yl-markcom.s3.eu-central-1.amazonaws.com/guides/YUKKA+ScoreLab+Help+Guide.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%" height="100%">-->
    </div>
</div>

