<mat-button-toggle-group [(ngModel)]="sortBy" (change)="onSortByChange($event)">
  <mat-button-toggle *ngFor="let option of sortByOptions" [value]="option.value" [class]="option.class">
    <mat-icon 
      svgIcon="{{ option.icon }}" 
      *ngIf="option.icon" 
      [matTooltip]="!hideTooltip ? 'Order by ' + option.label : null">
    </mat-icon>
    {{ hideTooltip ? option.label : null }}
  </mat-button-toggle>
</mat-button-toggle-group>
