<form class="query-group" [formGroup]="groupForm">
  <div class="optflo">
    <div>
      <div class="lineopt" *ngIf="data.level>0"></div>
      <mat-radio-group formControlName="operator">
        <mat-radio-button [value]="'and'">And</mat-radio-button>
        <mat-radio-button [value]="'or'">Or</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <button mat-icon-button (click)="addGroup()" *ngIf="data.level==0">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <button mat-icon-button (click)="addRule()">
        <mat-icon>playlist_add</mat-icon>
      </button>
    </div>
  </div>
  <div class="query-cont" formArrayName="filters">
    <div class="lintop"></div>
    <div class="flex" *ngFor="let rule of groupForm['controls'].filters['controls']; index as index">
      <div class="query-main" [formGroupName]="index">
        <div class="linemi"></div>
        <mat-checkbox formControlName="negated">Not</mat-checkbox>
        <mat-form-field floatLabel="float" class="select">
          <mat-select formControlName="field" (selectionChange)="fieldSelect(rule)">
            <mat-option value="entity">Any</mat-option>
            <mat-option value="entity">Entity</mat-option>
            <mat-option value="text">Text</mat-option>
            <mat-option value="source">Source</mat-option>
            <mat-option value="source">Event</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="text" *ngIf="rule.controls.field.value=='entity' || rule.controls.field.value=='source'">
          <app-query-search
            [data]="{index:index, groups:data.index, level:data.level, name:rule.controls.name.value, value:rule.controls.value.value}">
          </app-query-search>
        </div>
        <div class="text" *ngIf="rule.controls.field.value=='text'">
          <mat-form-field floatLabel="float">
            <input matInput placeholder="Search" formControlName="value" autocomplete="off">
          </mat-form-field>
        </div>
        <button class="btndel" mat-icon-button (click)="delRule(index)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="ingroup">
      <app-query-group *ngFor="let form of groupForm['controls'].groups['controls']; index as index"
        [data]="{index:index,form:form,level:1}">
      </app-query-group>
    </div>
  </div>
</form>