import { Component } from '@angular/core';

@Component({
  selector: 'app-main-trend',
  templateUrl: './main-trend.component.html',
  styleUrls: ['./main-trend.component.scss']
})
export class MainTrendComponent {

  constructor(
  ) {
  }

}
