<div class="yukkapp">

  <div class="userform">
    <div class="logo">
      <img *ngIf="logo !== 'universal'" src="assets/logo/{{logo}}_default.svg" alt="yukkalab">
      <img *ngIf="logo === 'universal'" src="assets/logo/{{logo}}_default.png" alt="yukkalab">
    </div>

    <form *ngIf="!getCode" [formGroup]="loginForm" (ngSubmit)="doLogin($event)">
      <mat-form-field class="blok email">
        <input matInput formControlName="email" type="email" placeholder="Email" name="login_email">
        <mat-hint class="error" *ngIf="error_email">Email not correct</mat-hint>
      </mat-form-field>
      <mat-form-field class="blok password">
        <input matInput formControlName="password" type="password" placeholder="Password" [type]="hide ? 'password' : 'text'" name="login_password">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-hint class="error" *ngIf="error_password">Password not correct</mat-hint>
      </mat-form-field>
      <div class="loading-container">
        <div class="loading login" *ngIf="loading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="btncont">
          <button mat-raised-button type="submit" *ngIf="!loading">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>

    <form autocomplete="off" *ngIf="getCode" [formGroup]="codeForm" (ngSubmit)="checkCode($event)">
      <mat-form-field class="blok email">
        <input matInput formControlName="email" placeholder="Email" name="2fa_email" autocomplete="off">
        <mat-hint class="error" *ngIf="error_email">Email not correct</mat-hint>
      </mat-form-field>
      <mat-form-field class="blok password">
        <input matInput formControlName="code" placeholder="Code" name="2fa_code" autocomplete="off">
        <!--<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>-->
        <mat-hint class="error" *ngIf="error_code">Code not correct</mat-hint>
      </mat-form-field>
      <div class="loading-container">
        <div class="loading login" *ngIf="loading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="btncont">
          <button mat-raised-button type="submit" *ngIf="!loading">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>

    <div class="disclaimer">
      <div *ngIf="message">
        <span *ngIf="message=='notadmin'">Login as administrator to access this area</span>
      </div>
      <div *ngIf="!message">
        <!-- <span *ngIf="project=='yukka'">
          <a class="link" href="https://shop.yukkalab.de/" target="_black">registration</a>
        </span> -->
        <!-- <span *ngIf="project!='yukka'" class="link" routerLink="../registration">registration</span> - -->
        <span class="link" routerLink="../password/request">Forgot your password?</span>
      </div>
    </div>
  </div>

  <div class="impressum">
    <span>
      <a class="link" href="https://www.yukkalab.com/imprint/" target="_blank">Impressum / Legal Disclosure</a>
    </span>
  </div>

  <div class="version">
    <span>{{version}}</span>
  </div>

</div>
