<div class="news-participants">

    <div class="news-participants-title" *ngIf="!(routing.isFolio() || routing.isHomepage())">
        <app-news-title [titolo]="titolo"></app-news-title>
    </div>

    <div class="news-participants-filter" *ngIf="!(routing.isFolio() || routing.isHomepage())">
        <mat-form-field class="participants-header-content">
            <mat-label>Countries</mat-label>
            <mat-select (selectionChange)="onChange()" class="participants-filters" [(ngModel)]="selectedCountry" name="country">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let country of countries | orderBy: 'label'" [value]="country.value">
                    {{country.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="participants-header-content">
            <mat-label>Sectors</mat-label>
            <mat-select (selectionChange)="onChange()" class="participants-filter" [(ngModel)]="selectedSector" name="sector">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let sector of sectors | orderBy: 'label'" [value]="sector.value">
                    {{sector.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="news-participants-chart" *ngIf="!loading && !nodata" [chart]="chart"></div>

    <div class="loading" *ngIf="loading">
        <mat-spinner [diameter]="50" *ngIf="!nodata"></mat-spinner>
    </div>
    <div class="nodata" *ngIf="nodata">NOT ENOUGH DATA</div>

</div>
