import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { StockChart } from 'angular-highcharts';
import { YukkApi } from 'src/app/service/yukkapi.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-micro-chart',
  templateUrl: './micro-chart.component.html',
  styleUrls: ['./micro-chart.component.scss']
})
export class MicroChartComponent implements OnChanges {

  @Input() data: Array<number>;
  @Input() name: string;
  @Input() event: string;
  @Input() timeframe: string;

  currentData: Array<number>;
  currentName: string;
  currentEvent: string;
  currentTimeframe: any;

  stockChart: StockChart;

  chartOptions: any = {
    title: { enabled: false },
    chart: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      type: 'area',
      margin: [2, 0, 2, 0],
      width: 140,
      height: 25,
      style: {
        overflow: 'visible'
      }
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      lineWidth: 1,
      // lineColor: 'rgb(120, 120, 120)',
      lineColor: 'rgb(50, 50, 50)',
      gridLineWidth: 0,
      gridLineColor: 'transparent',
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      gridLineWidth: 0,
      gridLineColor: 'transparent',
      tickPositions: []
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
        // lineWidth: 1,
        pointWidth: 5,
        shadow: false,
        // states: {
        //   hover: {
        //     lineWidth: 1
        //   }
        // },
        // marker: {
        //   radius: 1,
        //   states: {
        //     hover: {
        //       radius: 2
        //     }
        //   }
        // }
        // fillOpacity: 0.25
      },
      column: {
        grouping: false,
        borderColor: 'transparent'
      }
    },
    series: [
      // {
      //   name: '',
      //   type: 'area',
      //   data: []
      // },
      {
        name: '',
        type: 'area',
        data: []
      }
    ]
  };

  constructor(
    private yukkApi: YukkApi,
  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (change.name && change.name.currentValue) {
      this.currentName = change.name.currentValue;
    }
    if (change.event && change.event.currentValue) {
      this.currentEvent = change.event.currentValue;
    }
    // let dataAllEvents = null;
    let dataScoreEvent = null;
    if (change.data && change.data.currentValue && this.currentEvent) {
      const eventsArray = change.data.currentValue;
      // dataAllEvents = _.map(_.toPairs(_.mapValues(_.groupBy(eventsArray, (d) => d.date), (vs) => _.sumBy(vs, (v) => v.count))), (x) => ({ date: x[0], count: x[1] })).map(item => [this.geTime(item.date), item.count]);
      const dataevent = eventsArray.filter((d) => d.sub_event.id === this.currentEvent);
      dataScoreEvent = _.map(_.toPairs(_.mapValues(_.groupBy(dataevent, (d) => d.date), (vs) => _.sumBy(vs, (v) => v.count))), (x) => ({ date: x[0], count: x[1] })).map(item => [this.geTime(item.date), item.count]);
    }
    this.chartOptions.series = [
      // {
      //   name: this.currentName ? this.currentName : null,
      //   type: 'column',
      //   color: 'rgb(120, 120, 120)',
      //   data: dataAllEvents
      // },
      {
        name: this.currentName ? this.currentName : null,
        type: 'column',
        // color: 'rgb(180, 180, 180)',
        color: '#008B8B',
        data: dataScoreEvent
      }
    ];
    this.chartOptions.xAxis = Object.assign({}, this.chartOptions.xAxis, {
      type: 'datetime',
      ordinal: false,
      min: this.getRange().min,
      max: this.getRange().max
    });
    this.stockChart = new StockChart(this.chartOptions);
  }

  geTime(value) {
    return moment.utc(value).valueOf();
  }

  getRange() {
    const fromto = this.yukkApi.chartFromTo(this.timeframe);
    const range = {
      min: moment.utc(fromto.date1).valueOf(),
      max: moment.utc(fromto.date2).valueOf(),
    };
    return range;
  }

}
