import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrls: ['./dialog-table.component.scss']
})
export class DialogTableComponent {

  columns = [
    {
      columnDef: 'authority',
      header: 'Authority',
      cell: (element) => `${(element.authority) ? element.authority : '-'}`,
    },
    {
      columnDef: 'program',
      header: 'Program',
      cell: (element) => `${(element.program) ? element.program : '-'}`,
    },
    {
      columnDef: 'start',
      header: 'Start Date',
      cell: (element) => `${(element.start) ? element.start : ''}`,
    },
    {
      columnDef: 'end',
      header: 'End Date',
      cell: (element) => `${(element.end) ? element.end : ''}`,
    },
  ];

  displayedColumns = this.columns.map(c => c.columnDef);

  currentEntity: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogTableComponent>,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
  ) {}

  onMouseEnterName(entity) {
    this.currentEntity = entity;
  }

  onMouseLeaveName() {
    this.currentEntity = null;
  }

  navEntity(entity) {
    const sanction = this.data.table.filter(el => (entity === el.authority))[0];
    this.dialogRef.close();
    let path = '/cockpit/news/market/chart';
    if (this.routing.isMobile()) {
      path = '/mobile/news/market/chart';
    }
    this.router.navigate([path], {
      queryParams: {
        type: sanction.authorityType,
        id: sanction.authorityId,
        portfolioId: null,
        tag: null,
        template: null,
        user: null,
        folio: null,
        folioupdate: null,
        period: null,
        news: null,
        eventid: null,
        eventype: null,
        newstype: null,
        eventsFromScanner: null
      },
      queryParamsHandling: 'merge',
    });
  }
}
