<div class="newsletter-preview">

    <!--<div class="preview-scroll" infiniteScroll [infiniteScrollThrottle]="10" [infiniteScrollDistance]="4" [scrollWindow]="false" (scrolled)="inScrolled()"></div>-->

    <div class="preview-scroll">

        <div class="preview-main" *ngIf="newsletterPreview && !loading && !errorMessage" [style.backgroundColor]="'white'" [style.color]="'black'">
            <div [innerHTML]="newsletterPreview | sanitizeHtml"></div>
        </div>

        <div class="load-preview" *ngIf="loading || errorMessage || (params && params.id && (params.id === 'create'))">
            <span class="info-message" *ngIf="loading">
                For large Portfolios, Collections or complex Queries, rendering of the Newsletter Preview may be delayed.
            </span>
            <span class="info-message" *ngIf="errorMessage">
                Newsletter Preview failed to load in due time. This can be caused by large Portfolio, Collections or complex Queries.
            </span>
            <span class="info-message" *ngIf="params && params.id && (params.id === 'create')">
                Please create and save newsletter to see the Newsletter Preview.
            </span>
            <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
        </div>

        <div class="floating-actions" *ngIf="routing.isNewsletter() && (selectedArticles.length !== 0)">
            <mat-icon (click)="dismissManyNews()" matTooltip="Dismiss selected articles from the newsletter">clear</mat-icon>
        </div>

    </div>

    <div class="load-scroll" *ngIf="numSections && (scrollIndex < (numSections - 1))">
        <mat-progress-bar mode="determinate" [value]="(scrollIndex / numSections) * 100" style="height: 10px"></mat-progress-bar>
    </div>

</div>
