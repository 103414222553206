import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';

/**
* this guard is used in the search route, it uses localStorage to retrieve the data
*/

@Injectable({
  providedIn: 'root'
})
export class SearchGuard  {
  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public config: ConfigService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    let stored;
    if (this.config.appConfig.setup.useStorage) {
      stored = JSON.parse(localStorage.getItem('search'));
    }
    if (stored) {
      this.auth.query = stored;
      if (!state.root.queryParams.id) {
        this.router.navigate([state.url.split('?')[0]], {
          queryParams: {
            id: Math.random()
          },
          replaceUrl: true,
        });
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/'], {
        replaceUrl: true
      });
    }
  }
}
