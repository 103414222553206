import { Component } from '@angular/core';

/**
* in this component is used to display the main components of the cokcpit in a gallery or single one by one
*/
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {

  /**
  * constructor
  */
  constructor(
  ) { }

}
