import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '../../../service/auth.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { v1 as uuid } from 'uuid';
import { Observable } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfigService } from '../../../service/config.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-annotation',
  templateUrl: './news-annotation.component.html',
  styleUrls: ['./news-annotation.component.scss']
})

export class AnnotationComponent {

  params: any;
  note: any;
  editmode: boolean;
  user: any;
  Editor = ClassicEditor;
  keyd: string;
  annotations: any;
  note$: Observable<any>;
  config = { toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'], };
  entity: string;
  title = new UntypedFormControl('', [
    Validators.required,
    // Validators.minLength(4),
    Validators.maxLength(30),
  ]);
  comment = '';
  public = false;
  authpro: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public auth: AuthService,
    public configService: ConfigService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.authpro = this.configService.appConfig.routing.authpro === 'YUKKA';
    this.user = auth.authToken();
    this.editmode = false;
    this.title.setValue('');
    if (this.data.newone) {
      this.note = {
        uid: uuid(),
        entity: this.data.entity,
        position: this.data.newsday,
        public: this.public,
      };
    } else {
      this.note = this.data;
      this.title.setValue(this.data.annotation);
      this.comment = this.data.comment;
      this.public = this.data.is_global;
    }
  }

  goSave() {
    if (this.title.valid) {
      const data = {
        uid: this.note.uid,
        entity: this.note.entity,
        position: this.note.position,
        annotation: this.title.value,
        comment: this.comment,
        is_global: this.public,
        project: ''
      };
      if (this.data.is_global) {
        data.project = this.configService.appConfig.routing.authpro;
      }
      if (this.data.is_global !== this.public && !this.data.newone) {
        this.auth.delNote(this.note).subscribe(() => {
          this.auth.addNote(data).subscribe(risp => {
            this.dialog.closeAll();
            this.router.navigate([], {
              queryParams: {
                note: this.note.uid,
                update: Math.random()
              }, queryParamsHandling: 'merge', replaceUrl: false
            });
          });
        });
      } else {
        this.auth.addNote(data).subscribe(risp => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              note: this.note.uid,
              update: Math.random()
            }, queryParamsHandling: 'merge', replaceUrl: false
          });
        });
      }

    }
  }

  delNote() {
    if (window.confirm('Delete this annotation?')) {
      this.auth.delNote(this.note).subscribe(risp => {
        this.router.navigate([], {
          queryParams: {
            note: null,
            update: Math.random()
          },
          queryParamsHandling: 'merge', replaceUrl: false
        });
        this.dialog.closeAll();
      });
    }
  }

  goClear() {
    this.title.setValue('');
    this.comment = '';
  }

  isAuthor() {
    if (
      (this.note.email === this.user['email'] || this.data.newone) ||
      ((this.user.roles?.includes('ADMIN') || this.user.groups?.includes('ADMIN')) && this.data.is_global)
    ) {
      return true;
    }
  }

}
