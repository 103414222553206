import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * This pipe is created by Nick using the lodash, it define what groups of target can be visible
 */

@Pipe({
  name: 'matchgraph'
})
export class MatchgraphPipe implements PipeTransform {
  transform(matches: any, entity: any): any {
    if (entity) {
      const edgesForward: {} = {};
      const edgesBackward: {} = {};
      for (const node of matches) {
        for (const item of node.influences_entities) {
          const idxFrom = node.entity.compound_key;
          const idxTo = item;
          if (edgesForward[idxFrom] !== undefined) {
            edgesForward[idxFrom].push(idxTo);
          } else {
            edgesForward[idxFrom] = [idxTo];
          }
          if (edgesBackward[idxTo] !== undefined) {
            edgesBackward[idxTo].push(idxFrom);
          } else {
            edgesBackward[idxTo] = [idxFrom];
          }
        }
      }
      const visible = {};
      let stack = [entity.compound_key];
      // let counter = 0;
      while (stack.length > 0) {
        // counter += 1;
        // if (counter > 1000) {
        //   break;
        // }
        const e = stack.pop();
        visible[e] = true;
        const theseEdges = edgesForward[e];
        if (theseEdges === undefined) { continue; }
        for (const id of theseEdges) {
          if (!visible[id]) {
            stack.push(id);
          }
        }
      }
      stack = [entity.compound_key];
      // counter = 0;
      while (stack.length > 0) {
        // counter += 1;
        // if (counter > 1000) {
        //   break;
        // }
        const e = stack.pop();
        if (e !== entity.compound_key) {
          visible[e] = true;
        }
        const theseEdges = edgesBackward[e];
        if (theseEdges === undefined) { continue; }
        for (const id of theseEdges) {
          if (!visible[id]) {
            stack.push(id);
          }
        }
      }
      for (const n of matches) {
        if (visible[n.entity.compound_key] === undefined) {
          visible[n.entity.compound_key] = false;
        }
      }
      const matches2 = matches.filter(match => {
        return match.implied === true;
      });
      const groupedByType = _.groupBy(matches2, m => m.entity.type);
      const groupedByVisibility = _.mapValues(groupedByType, ms => {
        return _.defaults(_.groupBy(ms, m => visible[m.entity.compound_key]), { 'true': [], 'false': [] });
      });
      const matchStructure = _.mapValues(groupedByVisibility, mo => {
        if (mo['true'].length) {
          return {
            'toShow': mo['true'][0],
            'visible': mo['true'],
            'toShowIsVisible': true
          };
        } else if (mo['false'].length) {
          return {
            'toShow': mo['false'][0],
            'visible': [],
            'toShowIsVisible': false
          };
        } else {
          return {
            'toShow': null,
            'visible': [],
            'toShowIsVisible': false
          };
        }
      });
      return matchStructure;
    }

  }
}
