import { Pipe, PipeTransform } from '@angular/core'

/**
 * This pipe return true or false of a negative number
 */

@Pipe({
  name: 'negative'
})
export class NegativePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value < 0) {
      return value.toString().substring(1)
    } else {
      return value
    }
  }

}
