<div class="news-newsfeed">
  <div class="feedcount" *ngIf="!routing.isHot()">
    <app-news-count [newsfeedData]="newsfeedDataCount"></app-news-count>
  </div>
  <div class="feednwes" *ngIf="(params.newstype !== 'Events') || (!routing.isScanner() && params.eventid) || (routing.isScanner() && params.eventid && params.tag)">
    <app-news-feed [newsfeedData]="newsfeedDataFeed"></app-news-feed>
  </div>
  <div class="feednwes" *ngIf="!routing.isScanner() && (params.newstype === 'Events') && !params.eventid">
    <div class="nodata">PLEASE SELECT AN EVENT FROM THE EVENT MOSAIC</div>
  </div>
  <div class="feednwes" *ngIf="routing.isScanner() && (params.newstype === 'Events') && (!params.eventid || !params.tag)">
    <div class="nodata">PLEASE SELECT A COMPANY FROM THE PORTFOLIO AND AN EVENT FROM THE EVENT MOSAIC</div>
  </div>
<!--  <div class="feedtopic">-->
<!--    <app-news-tags></app-news-tags>-->
<!--  </div>-->
</div>
