import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, startWith, debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { v1 as uuid } from 'uuid';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-flags-setup',
  templateUrl: './flags-setup.component.html',
  styleUrls: ['./flags-setup.component.scss']
})
export class FlagsSetupComponent implements OnInit {

  @ViewChild('selectorFlagEntityType', { static: false }) entityTypeField: MatSelect;
  @ViewChild('selectorFlagScoreType', { static: false }) scoreTypeField: MatSelect;
  @ViewChild('selectorFlagThresholdSetting', { static: false }) thresholdSettingField: MatSelect;
  @ViewChild('inputFlagThreshold', { static: false, read: ElementRef }) thresholdField: ElementRef;

  isButtonActive = false;

  currentFlag: any;
  customFlags = [];

  notitle = false;

  scoreTypes = [];
  entityTypes = [];

  params: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params));
    });

  }

  ngOnInit() {

    this.yukkApi.getEntityTypes().subscribe(res => {
      this.entityTypes = res.entity_types;
    });

    // this.scoreTypes.push({
    //   value: 'volume',
    //   label: 'Volume'
    // });
    if (this.auth.scorelabSettings.sentimentAccess) {
      this.scoreTypes.push({
        value: 'sentiment',
        label: 'Sentiment'
      });
    }
    if (this.auth.scorelabSettings.generalRiskAccess) {
      this.scoreTypes.push({
        value: 'general_risk',
        label: 'General Risk Score'
      });
    }
    if (this.auth.scorelabSettings.creditRiskAccess) {
      this.scoreTypes.push({
        value: 'credit_risk',
        label: 'Credit Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgRiskAccess) {
      this.scoreTypes.push({
        value: 'esg_risk',
        label: 'ESG Risk Score'
      });
    }
    if (this.auth.scorelabSettings.immediateRiskAccess) {
      this.scoreTypes.push({
        value: 'immediate_risk',
        label: 'Immediate Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgAccess) {
      this.scoreTypes.push({
        value: 'esg',
        label: 'ESG Score'
      });
    }
    if (this.auth.scorelabSettings.eb_simAccess) {
      this.scoreTypes.push({
        value: 'eb_sim',
        label: 'EB-SIM Score'
      });
      this.scoreTypes.push({
        value: 'eb_sim_risk',
        label: 'EB-SIM Risk Score'
      });
    }
    if (this.auth.scorelabSettings.bbAccess) {
      this.scoreTypes.push({
        value: 'bb',
        label: 'Bull-Bear Score'
      });
    }
    if (this.auth.scorelabSettings.customScoreAccess && this.auth.scorelabSettings.customScores) {
      this.auth.scorelabSettings.customScores.forEach(item => {
        this.scoreTypes.push({
          value: item.uid,
          label: item.name
        });
      });
    }

    this.initialize();

  }

  initialize() {
    this.notitle = false;
    if (this.auth.scorelabSettings.customFlags) {
      this.customFlags = JSON.parse(JSON.stringify(this.auth.scorelabSettings.customFlags));
    }
    if (this.auth.scorelabSettings.defaultFlag) {
      this.currentFlag = this.customFlags.filter(el => {
        return el.uid === this.auth.scorelabSettings.defaultFlag.uid;
      })[0];
    } else {
      this.currentFlag = {
        uid: 'new',
        name: '',
        entity_type: [],
        score_type: '',
        threshold: '',
        threshold_setting: ''
      };
    }
  }

  selectFlag(flag) {
    this.notitle = false;
    this.currentFlag = this.customFlags.filter(el => {
      return el.uid === flag.uid;
    })[0];
  }

  setAsDefault(flag) {
    this.notitle = false;
    this.selectFlag(flag);
    this.auth.scorelabSettings.defaultFlag = JSON.parse(JSON.stringify(flag));
    const newDefaultName = flag.name;
    const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
      user_defaultFlag: flag
    });
    this.auth.cockpitSettings = newCustomSettings;
    this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
      this.snackBar.open('Default flag set to: ' + newDefaultName + '.', 'OK', { duration: 5000 });
    });
    this.router.navigate([], {
      queryParams: {
        updateFlag: Math.random(),
        score: null,
        time: this.params.score ? null : this.params.time
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  unsetDefault(flag) {
    this.auth.scorelabSettings.defaultFlag = null;
    const oldDefaultName = flag.name;
    const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
      user_defaultFlag: null
    });
    this.auth.cockpitSettings = newCustomSettings;
    this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
      this.snackBar.open('Default flag: ' + oldDefaultName + ' unset.', 'OK', { duration: 5000 });
    });
  }

  createFlag() {
    this.notitle = false;
    this.currentFlag = {
      uid: 'new',
      name: '',
      entity_type: [],
      score_type: '',
      threshold: '',
      threshold_setting: ''
    };
  }

  isValidFlag(flag) {
    let isValid = true;
    if (!flag.name.trim()) {
      this.notitle = true;
      isValid = false;
    }
    if (!(flag.entity_type.length > 0)) {
      if (this.entityTypeField) {
        this.entityTypeField.focus();
      }
      isValid = false;
    }
    if (!flag.score_type) {
      if (this.scoreTypeField) {
        this.scoreTypeField.focus();
      }
      isValid = false;
    }
    if (!flag.threshold && !(flag.threshold === 0)) {
      if (this.thresholdField) {
        this.thresholdField.nativeElement.focus();
      }
      isValid = false;
    }
    if (!flag.threshold_setting) {
      if (this.thresholdSettingField) {
        this.thresholdSettingField.focus();
      }
      isValid = false;
    }
    return isValid;
  }

  saveFlag() {
    this.notitle = false;
    if (this.currentFlag.uid === 'new') {
      if (this.isValidFlag(this.currentFlag)) {
        this.currentFlag.uid = uuid();
        this.customFlags.push(this.currentFlag);
        this.auth.scorelabSettings.customFlags = JSON.parse(JSON.stringify(this.customFlags));
        const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
          user_customFlags: this.customFlags
        });
        this.auth.cockpitSettings = newCustomSettings;
        this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
          this.snackBar.open('Custom flag created.', 'OK', { duration: 5000 });
          this.selectFlag(this.currentFlag);
        });
      } else {
        alert('Error: all of the required fields must be valid.');
      }
    } else {
      if (this.isValidFlag(this.currentFlag)) {
        this.auth.scorelabSettings.customFlags = JSON.parse(JSON.stringify(this.customFlags));
        let newCustomSettings;
        if (this.currentFlag.uid === this.auth.scorelabSettings.defaultFlag.uid) {
          this.auth.scorelabSettings.defaultFlag = JSON.parse(JSON.stringify(this.currentFlag));
          newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
            user_defaultFlag: this.currentFlag,
            user_customFlags: this.customFlags
          });
        } else {
          newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
            user_customFlags: this.customFlags
          });
        }
        this.auth.cockpitSettings = newCustomSettings;
        this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
          this.snackBar.open('Custom flag saved.', 'OK', { duration: 5000 });
        });
        if (this.currentFlag.uid === this.auth.scorelabSettings.defaultFlag.uid) {
          this.router.navigate([], {
            queryParams: {
              updateFlag: Math.random(),
              score: null,
              time: this.params.score ? null : this.params.time
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        }
      } else {
        alert('Error: all of the required fields must be valid.');
      }
    }
  }

  deleteFlag() {
    this.customFlags = this.customFlags.filter(flag => {
      return flag.uid !== this.currentFlag.uid;
    });
    this.auth.scorelabSettings.customFlags = JSON.parse(JSON.stringify(this.customFlags));
    let newCustomSettings;
    if (this.auth.scorelabSettings.defaultFlag.uid === this.currentFlag.uid) {
      this.auth.scorelabSettings.defaultFlag = null;
      newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
        user_defaultFlag: null,
        user_customFlags: this.customFlags
      });
    } else {
      newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
        user_customFlags: this.customFlags
      });
    }
    this.auth.cockpitSettings = newCustomSettings;
    this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
      this.snackBar.open('Custom flag removed.', 'OK', { duration: 5000 });
    });
    this.initialize();
  }

  onScoreTypeChange() {
    this.currentFlag.threshold = '';
    this.currentFlag.threshold_setting = '';
  }

  iClose() {
    this.dialog.closeAll();
  }

}
