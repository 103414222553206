import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '../../../service/auth.service';

/**
* This dialog siaply the report.
*/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent {

  updating: boolean;

  /**
  * constructor
  */
  constructor(
    /**
    * Router
    */
    private router: Router,
    /**
    * MAT_DIALOG_DATA
    */
    @Inject(MAT_DIALOG_DATA) public data: any,
    /**
    * MatSnackBar
    */
    public snackBar: MatSnackBar,
    /**
    * AuthService
    */
    public auth: AuthService,
  ) { }

  /**
  * save the report
  */
  iSave() {
    this.updating = true;
    this.auth.storeReport(this.data).subscribe(result => {
      this.updating = false;
      this.snackBar.open('Report updated', 'OK', { duration: 5000 });
    });
  }

  /**
  * redirect the url report id
  */
  goReport(id) {
    this.router.navigate([], {
      queryParams: {
        query: 'id:equal:' + id
      }
    });
  }

  /**
  * open news reader for reported news
  */
  goNews(url) {
    window.open(url);
  }

  /**
   * open cockpit using reported url
   */
  goCockpit(url) {
    window.open(url);
  }

}
