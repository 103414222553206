<form class="query2-group" [formGroup]="groupForm">
  <div class="query-cont" formArrayName="filters" *ngIf="groupForm.controls.filters">
    <div class="query-main" *ngIf="groupForm.controls.filters.controls.length">
      <div class="query-flex" *ngFor="let rule of groupForm.controls.filters.controls; index as index; last as last;">
        <div class="query-main" [formGroupName]="index">
          <div class="main-flex">
            <mat-checkbox formControlName="negated" [hidden]="(groupForm.controls.operator.value === 'near')">NOT</mat-checkbox>
            <div class="query-text" [class.near-not]="(groupForm.controls.operator.value === 'near')">
              <app-query-editor-search [data]="{
                    index:index,
                    groups:data.index,
                    level:data.level,
                    field:rule.controls.field.value,
                    name:rule.controls.name.value,
                    value:rule.controls.value.value,
                    input:rule.controls.input.value,
                    operator:groupForm.controls.operator.value
                  }">
              </app-query-editor-search>
            </div>
            <div class="btndel" (click)="delRule(index)"
              *ngIf="groupForm.controls.filters.controls.length>1 || data.length>1">
              <mat-icon>remove_circle</mat-icon>
            </div>
          </div>
          <div class="opeadd" *ngIf="!last" [class.filter]="data.level==1">{{groupForm.controls.operator.value}}</div>
        </div>
      </div>
      <div class="btnflex filter" *ngIf="!['query', 'portfolio'].includes(groupForm.controls.filters.controls[0].controls.field.value)">
        <button mat-button (click)="addRule()">
          <mat-icon>add_circle</mat-icon>
          <span>CRITERIA</span>
        </button>
        <button mat-button (click)="duplicateGroup()">
          <mat-icon>ballot</mat-icon>
          <span>DUPLICATE</span>
        </button>
      </div>
    </div>
    <div *ngIf="groupForm.controls.groups.controls as group" class="group-query">
      <div *ngFor="let form of group; index as index; last as last">
        <div class="app-query">
          <app-query-editor-group [data]="{index:index,form:form,level:1,length:group.length}"></app-query-editor-group>
        </div>
        <div class="opeadd" *ngIf="!last">{{groupForm.controls.operator.value}}</div>
        <div class="btnflex group" *ngIf="last">
          <button mat-button (click)="addGroup('group')">
            <mat-icon>library_add</mat-icon>
            <span>GROUP</span>
          </button>
          <button mat-button *ngIf="auth.featureFlags.linkQueries" (click)="addGroup('query')">
            <mat-icon>library_add</mat-icon>
            <span>QUERY</span>
          </button>
          <button mat-button *ngIf="auth.featureFlags.linkQueries" (click)="addGroup('portfolio')">
            <mat-icon>library_add</mat-icon>
            <span>PORTFOLIO</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="query-opera" *ngIf="showOperator()">
    <mat-form-field appearance="outline">
      <!-- <mat-label>Operator</mat-label> -->
      <mat-select *ngIf="groupForm.controls.operator.value === 'near'" formControlName="operator" (selectionChange)="inOperatorNear($event.value)">
        <mat-option value="and">AND</mat-option>
        <mat-option value="or">OR</mat-option>
        <mat-option value="near" *ngIf="data.level === 1">NEAR</mat-option>
      </mat-select>
      <mat-select *ngIf="groupForm.controls.operator.value !== 'near'" formControlName="operator" (selectionChange)="inOperator($event.value)">
        <mat-option value="and">AND</mat-option>
        <mat-option value="or">OR</mat-option>
        <mat-option value="near" *ngIf="data.level === 1">NEAR</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="near-slop" appearance="outline" *ngIf="(groupForm.controls.operator.value === 'near')">
      <input matInput type="number" placeholder="words" formControlName="slop" min="0">
    </mat-form-field>
  </div>
</form>
