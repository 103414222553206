<mat-drawer-container class="mainapp" autosize>
  <mat-drawer #drawer class="sidemain" mode="over">
    <div class="blok">
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>News</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Markets</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item>
                  <button mat-button routerLink="news/market"
                    [queryParams]="{tag:null,portfolio:null,event:null,type: 'hierarchy',id:'icb'}"
                    queryParamsHandling="merge" (click)="drawer.toggle()">Country</button>
                </mat-list-item>
                <mat-list-item>
                  <button mat-button routerLink="news/market"
                    [queryParams]="{tag:null,event:null,type:'hierarchy',id:'icb'}"
                    queryParamsHandling="merge" (click)="drawer.toggle()">Industry</button>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="folios">
              <mat-expansion-panel-header>
                <mat-panel-title>Portfolio</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let folio of folios | async">
                  <button mat-button routerLink="news/portfolio"
                    [queryParams]="{type:null,id:null,event:null}" queryParamsHandling="merge"
                    (click)="drawer.toggle()">{{folio.name}}</button>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>Invest</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Signals</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let item of listsignal">
                  <button mat-button [routerLink]="['invest/signal']" [queryParams]="{id: item.alpha_id}"
                    (click)="drawer.toggle()">{{item.name}}</button>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
            <!--<mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Sentiment</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let item of sentiment">
                  <button mat-button [routerLink]="['trend/indicator']" [queryParams]="{}"
                    (click)="drawer.toggle()">{{item.name}}</button>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>-->
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="blok blok2">
      <button mat-raised-button class="logout" (click)="logOut()">
        <span>Logout</span>
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </mat-drawer>
  <div class="example-sidenav-content">
    <div class="contmobile">
      <!-- <div class="pwabtn" *ngIf="promptEvent">
        <div class="pwacont">
          <button mat-button (click)="installPwa()">
            <mat-icon>home</mat-icon>
            INSTALL APPLICATION</button>
          <button mat-button (click)="installPwa()">SKIP</button>
        </div>
      </div> -->
      <div class="contabv">
        <!--<button mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>-->
        <div class="search">
          <app-menu-above></app-menu-above>
        </div>
      </div>
      <div class="contmid" [class.rounded]="routing.isMarket() && routing.isFeed()">
        <router-outlet></router-outlet>
      </div>
      <div class="contbot">

        <nav mat-tab-nav-bar *ngIf="routing.isFolio()">
          <a mat-tab-link routerLink="news/portfolio/scanner" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge" routerLinkActive="active" [active]="routing.isScanner()">
            <mat-icon>compass_calibration</mat-icon>
          </a>
          <a mat-tab-link routerLink="news/portfolio/newsfeed" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge" routerLinkActive="active" [active]="routing.isFeed()">
            <mat-icon svgIcon="newspaper-variant"></mat-icon>
          </a>
        </nav>
        
        <nav mat-tab-nav-bar *ngIf="routing.isMarket()">
          <a mat-tab-link routerLink="news/market/chart" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge"
             routerLinkActive="active" [active]="routing.isChart()">
            <mat-icon>insert_chart</mat-icon>
          </a>
          <a mat-tab-link routerLink="news/market/newsfeed" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge"
            routerLinkActive="active" [active]="routing.isFeed()">
            <mat-icon svgIcon="newspaper-variant"></mat-icon>
          </a>
        </nav>

      </div>
    </div>
  </div>
</mat-drawer-container>
