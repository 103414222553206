import { Component, OnInit, ViewEncapsulation, Inject, SecurityContext } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * This component is used inside the query-folio component 4 edit the query itself
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.scss']
})
export class QueryEditorComponent {

  queryForm: UntypedFormGroup;
  namequery: UntypedFormControl;
  descriptionQuery: UntypedFormControl;
  selectedCollection: any;
  qtext: boolean;
  query: string;
  $querylist: Observable<any>;
  insave: boolean;
  qparams: any;
  idelete = 0;
  defaultSlop = 5;

  isButtonActive = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthService,
    public routing: RoutingService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

    this.route.queryParams.subscribe(qparams => {
      this.qparams = qparams;
    });
    this.selectedCollection = [];
    this.namequery = new UntypedFormControl('', [Validators.required]);
    this.descriptionQuery = new UntypedFormControl('', [Validators.required]);
    if (data) {
      const queryCopy = JSON.parse(JSON.stringify(data.query));
      if (data.query.referenced_queries?.length > 0) {
        data.query.referenced_queries.forEach(query => {
          queryCopy.groups.push({
            operator: 'or',
            groups: [],
            filters: [{
              negated: query.negated,
              field: 'query',
              name: query.name,
              value: query.artifact_id,
              input: query.name
            }]
          });
        });
      }
      if (data.query.referenced_portfolios?.length > 0) {
        data.query.referenced_portfolios.forEach(portfolio => {
          queryCopy.groups.push({
            operator: 'or',
            groups: [],
            filters: [{
              negated: portfolio.negated,
              field: 'portfolio',
              name: portfolio.name,
              value: portfolio.artifact_id,
              input: portfolio.name
            }]
          });
        });
      }
      this.inEdit(queryCopy);
      this.namequery.setValue(data.name);
      this.descriptionQuery.setValue(data.description);
      this.selectedCollection = data.groups;
    } else {
      this.inStart();
      this.selectedCollection.push(this.qparams.id);
    }

  }

  inStart() {
    this.queryForm = this.formBuilder.group({
      operator: new UntypedFormControl('and'),
      filters: new UntypedFormArray([]),
      groups: new UntypedFormArray([])
    });
    this.addGroup('group');
  }

  inEdit(query) {
    const filters = query.filters.map(item => this.myRule2(item));
    this.queryForm = this.formBuilder.group({
      operator: new UntypedFormControl(query.operator),
      filters: new UntypedFormArray(filters),
      groups: new UntypedFormArray(this.initGroup(query.groups))
    });
  }

  initGroup(groups) {
    if (groups) {
      return groups.map(item1 => {
        const filters1 = item1.filters.map(item => this.myRule2(item));
        return this.formBuilder.group({
          operator: new UntypedFormControl(item1.operator),
          slop: new UntypedFormControl(item1.slop),
          filters: new UntypedFormArray(filters1),
          groups: new UntypedFormArray([])
        });
      });
    }
    return [];
  }

  addGroup(type) {
    const rule = {
      negated: false,
      field: 'entity',
      name: '',
      value: '',
      input: ''
    };
    if (type !== 'group') {
      rule.field = type;
    }
    const filters = this.formBuilder.array([this.myRule2(rule)]);
    const groups = this.formBuilder.group({
      operator: 'or',
      slop: this.defaultSlop,
      filters: filters,
      groups: this.formBuilder.array([])
    });
    const mygroup = this.queryForm.get('groups') as UntypedFormArray;
    mygroup.push(groups);
  }

  duplicateGroup(index) {
    const groups = this.queryForm.get('groups') as UntypedFormArray;
    const groupCopy = _.cloneDeep(groups.at(index)) as UntypedFormGroup;
    groups.insert(index, groupCopy);
  }

  delGroup(index) {
    const groups = this.queryForm.get('groups') as UntypedFormArray;
    groups.removeAt(index);
  }

  addRule(index, level) {
    if (level === 0) {
      const filters = this.queryForm.get('filters') as UntypedFormArray;
      filters.push(this.myRule());
    } else {
      const groups = this.queryForm.get('groups') as UntypedFormArray;
      const filters = groups.at(index).get('filters') as UntypedFormArray;
      filters.push(this.myRule());
    }
  }

  delRule(level, index1, index2) {
    if (level === 0) {
      const filters = this.queryForm.get('filters') as UntypedFormArray;
      filters.removeAt(index2);
    } else {
      const groups = this.queryForm.get('groups') as UntypedFormArray;
      const filters = groups.at(index1).get('filters') as UntypedFormArray;
      filters.removeAt(index2);
    }
  }

  myRule() {
    return this.formBuilder.group({
      negated: new UntypedFormControl(false, Validators.required),
      field: new UntypedFormControl('entity', Validators.required),
      name: new UntypedFormControl(''),
      value: new UntypedFormControl('', Validators.required),
      input: new UntypedFormControl('', Validators.required)
    });
  }

  myRule2(rule) {
    return this.formBuilder.group({
      negated: new UntypedFormControl(rule.negated, Validators.required),
      field: new UntypedFormControl(rule.field, Validators.required),
      name: new UntypedFormControl(rule.name),
      value: new UntypedFormControl(rule.value, Validators.required),
      input: new UntypedFormControl(rule.input, Validators.required)
    });
  }

  iDelete() {
    if (window.confirm('Are you sure?')) {
      // this.idelete = 0;
      if (this.auth.query.uid === 'default') {
        this.auth.delQueries([this.data.uid]).subscribe(() => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
              queryid: null,
              newsfeedFromQuery: null
            }, queryParamsHandling: 'merge'
          });
        });
      } else {
        this.auth.removeQueriesFromGroup([this.data.uid], this.auth.query.uid).subscribe(() => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
              queryid: null,
              newsfeedFromQuery: null
            }, queryParamsHandling: 'merge'
          });
        });
      }
    }
  }

  iSave(update) {
    const queryGroups = [];
    const embeddedPortfolios = [];
    const embeddedQueries = [];
    this.queryForm.value.groups.forEach(group => {
      if (group.filters && (group.filters.length > 0) && (group.filters[0].field === 'portfolio')) {
        embeddedPortfolios.push(
          {
            artifact_id: group.filters[0].value,
            negated: group.filters[0].negated,
            name: group.filters[0].name
          }
        );
      } else if (group.filters && (group.filters.length > 0) && (group.filters[0].field === 'query')) {
        embeddedQueries.push(
          {
            artifact_id: group.filters[0].value,
            negated: group.filters[0].negated,
            name: group.filters[0].name
          }
        );
      } else {
        queryGroups.push(group);
      }
    });
    if (this.namequery.valid) {
      this.insave = false;
      const data = {
        groups: this.selectedCollection,
        name: this.namequery.value,
        description: this.sanitizeInput(this.descriptionQuery.value),
        query: Object.assign({}, this.queryForm.value, {
          groups: queryGroups,
          referenced_portfolios: embeddedPortfolios,
          referenced_queries: embeddedQueries
        })
      };
      if (this.data && update) {
        data['uid'] = this.data.uid;
      }
      this.auth.addQuery(data).subscribe(result => {
        this.auth.getGroupsQuery().subscribe(groups => {
          groups.map(group => {
            if (group.uid === result.uid) {
              this.auth.iquery = group.content;
              this.auth.query = {
                uid: group.uid,
                name: group.name,
                query: {
                  operator: 'or',
                  filters: [],
                  groups: group.content.map(query => query.query)
                }
              };
            }
          });
        });
        this.dialog.closeAll();
        this.router.navigate([], {
          queryParams: {
            update: Math.random(),
            queryid: result.uid,
            newsfeedFromQuery: null
          }, queryParamsHandling: 'merge'
        });
      });
    }
  }

  iExport() {
    if (this.namequery.valid) {
      const queryPure = {
        name: this.namequery.value,
        description: this.descriptionQuery.value,
        query: this.queryForm.value
      };
      const queryString = JSON.stringify(queryPure);
      const blob = new Blob([queryString], {type: 'application/json'});
      FileSaver.saveAs(blob, 'query_' + queryPure.name + '.json');
    }
  }

  /**
   * import query from file
   */
  importQuery(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const result = JSON.parse(e.target.result);
        this.inEdit(result.query);
        this.namequery.setValue(result.name);
        this.descriptionQuery.setValue(result.description);
      };
      reader.readAsText(event.target.files[0]);
    }
  }

  sanitizeInput(input: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, input) || '';
  }

}
