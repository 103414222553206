<div>
  <p *ngIf="!routing.isDev()">Select desired timeframe. Please note that min start date is: {{config.appConfig.setup.startDate}}, and date range can't exceed {{auth.internalCockpitSettings.maxTimeframe}} days.</p>
  <p *ngIf="routing.isDev()">Select desired timeframe. Please note that min start date is: {{config.appConfig.setup.startDate}}.</p>
</div>
<form (ngSubmit)="goDate()" class="filter-datepick">
  <mat-form-field>
    <input matInput [min]="minStartDate" [max]="maxStartDate" [matDatepicker]="picker1" placeholder="From" [formControl]="date1" (change)="inDate1()">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepicker]="picker2" placeholder="To" [formControl]="date2">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button mat-raised-button color="primary" type="submit">Select</button>
</form>
