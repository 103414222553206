<!-- <app-news-title [titolo]="titolo" *ngIf="titolo"></app-news-title> -->

<div class="news-treemap">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <div class="nodata" *ngIf="nodata">NO LINKED ENTITIES</div>
  <div class="contmap" *ngIf="company">
    <div *ngFor="let item of relations | orderBy: myOrder; let i=index" class="contindex"
      [class.multi]="item.children.length>1">
      <div class="topo box-map" [class.open]="showall==i" *ngIf="relations.length>1" (click)="goWhat(item, i)"
        [style.color]="item.sentiment | color">
        <div class="back" [style.background-color]="item.sentiment | color"></div>
        <div class="contito">
          <div class="label" *ngIf="item.children.length>1" [innerHTML]="company.entity.name | nicetxt | isbeta">
          </div>
          <div class="timulty" [class.label]="item.children.length==1">
            <div class="count" *ngIf="item.children.length>1">
              <div class="txt">{{item.children.length}}</div>
            </div>
            <div [innerHTML]="item.name | nicetxt | isbeta"></div>
            <div class="count" *ngIf="item.children.length>1">
              <mat-icon [class.opened]="(item.children.length>1 && showall==i) || relations.length==1">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>
          <div class="nametity" *ngIf="item.children.length==1">
            <span>{{item.children[0].entity.name|nicetxt}}</span>
          </div>
        </div>
        <div class="continfo" [class.trend]="params.sort==='trend'||params.sort==='-trend'">
          <div class="percent" *ngIf="item.sentiment>-1">
            <span class="brak">(</span>
            {{item.sentiment|percent}}
            <span class="brak">)</span>
          </div>
          <span class="numb">
            <span class="brak">(</span>
            <span>{{item.sentiment_delta*100|round|trend}}</span>
            <span>{{item.sentiment_delta*100|round|negative}}</span>
            <span class="brak">)</span>
          </span>
        </div>
      </div>
      <div class="boxi" *ngIf="(item.children.length>1 && showall==i) || relations.length==1">
        <div class="boxo slylo box-map" *ngFor="let itom of item.children | orderBy: myOrder"
          [class.trend]="params.sort==='trend'||params.sort==='-trend'" [class.small]="item.children.length<10"
          (click)="goView(itom)" [class.pointer]="itom.sentiment!=-1" [style.color]="itom.sentiment | color">
          <div class="back" [style.background]="itom.sentiment | color"></div>
          <div class="cont name">{{itom.entity.name | nicetxt}}</div>
          <div class="cont info" *ngIf="itom.sentiment>-1">
            <span class="percent">
              <span class="brak">(</span>
              {{itom.sentiment | percent}}
              <span class="brak">)</span>
            </span>
            <span class="numb">
              <span class="brak">(</span>
              <span>{{itom.sentiment_delta*100|round|trend}}</span>
              <span>{{itom.sentiment_delta*100|round|negative}}</span>
              <span class="brak">)</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
