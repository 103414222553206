<div class="widget-gallery">
  <div class="widget-menu">
    <div class="cont">
      <mat-form-field appearance="outline">
        <mat-label>Widget</mat-label>
        <mat-select [(ngModel)]="widget" multiple (openedChange)="inWidget()">
          <mat-option *ngFor="let item of widgets2" [value]="item">{{item|ucwords}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Theme</mat-label>
        <mat-select [(ngModel)]="theme" (openedChange)="inTheme()">
          <mat-option value="yukka">Yukka</mat-option>
          <mat-option value="expo">Expo</mat-option>
          <mat-option value="light">Light</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Style</mat-label>
        <mat-select [(ngModel)]="style" multiple (openedChange)="inStyle()">
          <mat-option value="nomenu">Menu</mat-option>
          <mat-option value="notitle">Title</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="islide">
        <mat-form-field appearance="outline">
          <mat-label>Width</mat-label>
          <input matInput placeholder="Width" [(ngModel)]="iwidth" (keyup.enter)="inSize()">
        </mat-form-field>
        <mat-slider min="500" max="1200" step="10" [(ngModel)]="iwidth" (input)="iwidth=$event.value"></mat-slider>
      </div>
      <div class="islide">
        <mat-form-field appearance="outline">
          <mat-label>Height</mat-label>
          <input matInput placeholder="Height" [(ngModel)]="iheight" (keyup.enter)="inSize()">
        </mat-form-field>
        <mat-slider min="500" max="1200" step="10" [(ngModel)]="iheight" (input)="iheight=$event.value"></mat-slider>
      </div>
      <mat-slide-toggle class="srcslide" [(ngModel)]="isrc">Src</mat-slide-toggle>
    </div>
  </div>
  <div class="widget-main">
    <div class="widget-cont">
      <div class="widget" [style.width.px]="iwidth" [style.height.px]="iheight" *ngFor="let iframe of iframes">
        <div class="cont">
          <div class="isrc" *ngIf="isrc">{{iframe}}</div>
          <iframe [src]="iframe|trustUrl" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
