import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is used for define the BETA label after certain text
 */


@Pipe({ name: 'isbeta' })
export class BetaPipe implements PipeTransform {
  transform(text: string): string {
    const array = [
      'CEOs',
      'Products',
      'Product Categories',
      'Collection',
      'Product Model',
      'Product Type',
      'Automotive Ceo',
      'Automotive Poducts',
      'Automotive Poducts Categories',
      'Manufacturer',
      'Supplier',
      'Product Models',
    ];
    if (array.includes(text)) {
      text += '<span class="beta">BETA</span>';
    }
    return text;
  }
}
