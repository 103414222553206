import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { YukkApi } from '../../../../../service/yukkapi.service';
import { ActivatedRoute } from '@angular/router';
import { QueryDialogComponent } from '../query-dialog.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-search',
  templateUrl: './query-search.component.html',
  styleUrls: ['./query-search.component.scss']
})
export class QuerySearchComponent implements OnInit {

  mySearch: UntypedFormControl;
  @Input('data') data: any;
  items$: Observable<YukkApi>;
  params: any;
  name: string;
  value: string;
  error: boolean;

  constructor(
    public query: QueryDialogComponent,
    private yukkApi: YukkApi,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.mySearch = new UntypedFormControl(this.data.name);
    this.name = this.data.name;
    this.value = this.data.value;
    this.mySearch.valueChanges.subscribe(value => {
      if (value) {
        // this.items$ = this.yukkApi.search(value, this.params).map(result => {
        //   return result.hits
        // })
      }
    });
  }

  goSelect(value) {
    this.name = value.entity.name;
    this.value = value.entity.type + ':' + value.entity.alpha_id;
    this.mySearch.setValue(this.name);
    this.error = false;
    if (this.data.level === 0) {
      const rule = this.query.queryForm.get('filters') as UntypedFormArray;
      rule.controls[this.data.index].get('name').setValue(this.name);
      rule.controls[this.data.index].get('value').setValue(this.value);
    } else {
      const groups = this.query.queryForm.get('groups') as UntypedFormArray;
      const filters = groups.at(this.data.groups).get('filters') as UntypedFormArray;
      filters.controls[this.data.index].get('name').setValue(this.name);
      filters.controls[this.data.index].get('value').setValue(this.value);
    }
  }

  inBlur(event) {
    // if (this.name && this.value) {
    //   if (this.name !== event.value) {
    //     setTimeout(() => {
    //       this.mySearch.setValue(this.name)
    //     }, 100)
    //   }
    //   this.error = false
    // } else {
    //   this.mySearch.reset()
    //   this.error = true
    // }
  }

}
