import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-analytics-temporality',
  templateUrl: './event-analytics-temporality.component.html',
  styleUrls: ['./event-analytics-temporality.component.scss']
})
export class EventAnalyticsTemporalityComponent {
  @Input() itemId: string;
  @Input() eventsAnalyticsButtonWidth: (type: string, value: string, id: string) => number;
}
