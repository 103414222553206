import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * This pipe is created by Nick using the lodash, it define what groups of target can be visible
 */

@Pipe({
  name: 'matchgroup'
})
export class MatchgroupPipe implements PipeTransform {

  transform(matches: any, entity: any): any {
    const edgesForward: {} = {};
    const edgesBackward: {} = {};
    for (const node of matches) {
      for (const item of node.influences_entities) {
        const idxFrom = node.entity.compound_key;
        const idxTo = item;
        if (edgesForward[idxFrom] !== undefined) {
          edgesForward[idxFrom].push(idxTo);
        } else {
          edgesForward[idxFrom] = [idxTo];
        }
        if (edgesBackward[idxTo] !== undefined) {
          edgesBackward[idxTo].push(idxFrom);
        } else {
          edgesBackward[idxTo] = [idxFrom];
        }
      }
    }
    const visible = {};
    let stack = [entity.compound_key];
    // let counter = 0;
    while (stack.length > 0) {
      // counter += 1;
      // if (counter > 1000) {
      //   break;
      // }
      const e = stack.pop();
      visible[e] = true;
      const theseEdges = edgesForward[e];
      if (theseEdges === undefined) { continue; }
      for (const id of theseEdges) {
        if (!visible[id]) {
          stack.push(id);
        }
      }
    }
    stack = [entity.compound_key];
    // counter = 0;
    while (stack.length > 0) {
      // counter += 1;
      // if (counter > 1000) {
      //   break;
      // }
      const e = stack.pop();
      if (e !== entity.compound_key) {
        visible[e] = true;
      }
      const theseEdges = edgesBackward[e];
      if (theseEdges === undefined) { continue; }
      for (const id of theseEdges) {
        if (!visible[id]) {
          stack.push(id);
        }
      }
    }
    for (const n of matches) {
      if (visible[n.entity.compound_key] === undefined) {
        visible[n.entity.compound_key] = false;
      }
    }
    const target_filter = matches.filter(match => match.implied === false);
    const target_entity = _.groupBy(target_filter, match => match.entity.type);
    const target_active = _.mapValues(target_entity, ms => {
      return _.map(ms, el => {
        return _.extend({}, el, { active: visible[el.entity.compound_key] });
      });
    });
    const target_visiby = _.mapValues(target_active, ms => _.sortBy(ms, m => !visible[m.entity.compound_key]));
    return target_visiby;
  }
}
