import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-chat-navigation',
  templateUrl: './chat-navigation.component.html',
  styleUrls: ['./chat-navigation.component.scss']
})
export class ChatNavigationComponent implements OnInit {

  @Input() childMessageChatTitle: string;
  @Input() childMessageChatId: string;

  // chat history 
  chats: any[] = [];
  defaultTitle: string = "New Chat";
  activeChatId: string | null = null;
  form: FormGroup;
  editMode: { [key: string]: boolean } = {};
  originalTitles: { [key: string]: string } = {};

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router, 
    private route: ActivatedRoute, 
  ) {
    this.form = this.fb.group({
      title: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.getChats();
    this.route.queryParams
      .pipe(filter(params => params.chatId))
      .subscribe(params => {
        this.activeChatId = params.chatId;
      });
  }

  ngOnChanges(): void {
    // Check if childMessageChatId and childMessageChatTitle are set and update the title if necessary
    if (this.childMessageChatId && this.childMessageChatTitle) {
      this.updateChatTitleIfDefaultTitle(this.childMessageChatId, this.childMessageChatTitle);
    }
  }

  getChats(): void {
    this.auth.getChats().subscribe({
      next: (data) => {
        this.chats = (data as any).hits;
        this.redirectToFirstChatOrStartNew();
        setTimeout(()=> {
          const target = document.getElementById('llmContainer');
          target.scrollTo(0, target.scrollHeight);
        }, 500);
      },
      error: (err) => console.error(err),
      complete: () => console.log('Chats fetched successfully.')
    });
  }

  updateChatTitleIfDefaultTitle(chatId: string, title: string): void {
    const chat = this.chats.find(c => c.chat_id === chatId);
    if (chat && chat.title === this.defaultTitle) {
      this.auth.editChatTitle(title, chatId).subscribe({
        next: (res: any) => {
          chat.title = title;      
        },
        error: (error) => console.error('Failed to update chat title', error)
      });
    }
  }

  // redirecting url into first chat or start a new chat if no chats 
  redirectToFirstChatOrStartNew(): void {
    if (this.chats.length > 0) {
      if (!this.activeChatId) {
        const firstChatId = this.chats[0].chat_id;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { chatId: firstChatId },
          queryParamsHandling: 'merge',
        }).then(() => {
          this.activeChatId = firstChatId;
        }).catch((err) => {
          console.error('Navigation error:', err);
        });
      }
    } else {
      this.startNewChat();
    }
  }

  // Onclick method when clicking on a chatId
  goToChatConversation(chat: any): void {
    if (chat && chat.chat_id) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { chatId: chat.chat_id },
        queryParamsHandling: 'merge',
      }).then(() => {
        console.log(`Navigated to chat with ID: ${chat.chat_id}`);
      }).catch((err) => {
        console.error('Navigation error:', err);
      });
    } else {
      console.error('chat id is undefined or chat object is invalid', chat);
    }
  }

  // New Chat button onclick method
  startNewChat(): void {
    const title = this.defaultTitle;
    this.auth.startNewChat(title).subscribe({
      next: (response) => {
        console.log('New chat started:', response);
        const newChatId = (response as any).uid;
        if (newChatId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { chatId: newChatId },
            queryParamsHandling: 'merge',
          }).then(() => {
            console.log(`Navigated to new chat with ID: ${newChatId}`);
            this.getChats();
          }).catch((err) => {
            console.error('Navigation error:', err);
          });
        }
      },
      error: (err) => console.error('Error starting new chat:', err)
    });
  }

  // edit chatId title
  editChat(chatId: string, event: Event): void {
    event.stopPropagation();
    const chat = this.chats.find(chat => chat.chat_id === chatId);
    if (chat) {
      this.originalTitles[chatId] = chat.title;
      this.editMode[chatId] = true;
    }
  }

  // post the new title to change chatId name
  saveChatTitle(chat: any): void {    
    const updatedTitle = chat.title;

    this.auth.editChatTitle(updatedTitle, chat.chat_id).subscribe({
      next: (res: any) => {
        console.log('Chat title edited successfully', res);
        this.editMode[chat.chat_id] = false;
        this.getChats();
      },
      error: (error) => {
        console.error('Failed to update chat title', error);
        this.editMode[chat.chat_id] = false;
        chat.title = this.originalTitles[chat.chat_id]; 
      }
    });
  }

  // cancel editing chatId title
  abortEditChat(chat: any): void {
    this.editMode[chat.chat_id] = false;
    chat.title = this.originalTitles[chat.chat_id]; 
  }

  // delete a chatId
  deleteChat(chatId: string): void {
    if (chatId) {
      this.auth.deleteChat(chatId).subscribe({
        next: (res: any) => {
          console.log('Chat deleted successfully', res);
          this.getChatsAfterDeletion();
        },
        error: (err: any) => {
          console.error('There was an error deleting the chat', err);
        }
      });
    }
  }

  // refreshing new list of chats after changes
  getChatsAfterDeletion(): void {
    this.auth.getChats().subscribe({
      next: (data) => {
        this.chats = (data as any).hits;
        if (this.chats.length > 0) {
          const firstChatId = this.chats[0].chat_id;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { chatId: firstChatId },
            queryParamsHandling: 'merge',
          }).then(() => {
            console.log(`Redirected to first chat with ID: ${firstChatId}`);
            this.activeChatId = firstChatId;
          }).catch((err) => {
            console.error('Navigation error:', err);
          });
        } else {
          this.startNewChat();
        }
      },
      error: (err) => console.error(err),
      complete: () => console.log('Chats fetched successfully.')
    });
  }

  onMouseEnter(preset) {
    preset.visible = true;
  }

  onMouseLeave(preset) {
    preset.visible = false;
  }
}