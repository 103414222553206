<div class="widget-iframe" [class.nomenu]="params.nomenu=='true'">
  <div class="iframe-cont">
    <div class="iframe-menu" *ngIf="!routing.isReader() && !routing.isStoryReader() && !(params?.nomenu=='true')">
      <app-menu-above></app-menu-above>
    </div>
    <div class="iframe-main">
      <div class="iframe-main-title" *ngIf="!routing.isReader() && !routing.isStoryReader() && !(params?.notitle=='true')">
        <app-news-title *ngIf="$titolo|async as titolo" [titolo]="titolo"></app-news-title>
      </div>
      <div class="iframe-main-conto" *ngIf="!routing.isReader() && !routing.isStoryReader()">
        <router-outlet></router-outlet>
      </div>
      <div class="iframe-main-conto reader" *ngIf="routing.isReader()">
        <app-news-view *ngIf="article" [apicall]="{apicall: article, match: match, tag: tag}"></app-news-view>
      </div>
      <div class="iframe-main-conto reader story" *ngIf="routing.isStoryReader()">
        <app-news-feed style="width: 100%" *ngIf="newsfeedStory" [newsfeedData]="newsfeedStory"></app-news-feed>
      </div>
    </div>
  </div>
</div>
