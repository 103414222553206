<div class="newsletter-main">

  <div class="newsletter-aside">
    <app-newsletter-form [newsletter]="newsletter" [newsletterParamsReady]="newsletterParamsReady"></app-newsletter-form>
  </div>
  <div class="newsletter-cont">
    <div class="newsletter-cont-abs">
      <div class="newsletter-cont-top">

        <div class="newsletter-cont-header" *ngIf="routing.isNewsletter()">
          <div class="edit-button" (click)="$event.stopPropagation(); addNewsletter();" matTooltip="Create Newsletter">
            <mat-icon>add</mat-icon>
          </div>
          <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let newslet of $newsletters|async|orderBy:'title'" [active]="newslet.uid==params.id" (click)="inTabNewsletter(newslet.uid)">
              <div class="tab-info">
                <mat-icon class="active" *ngIf="newslet.active" matTooltip="Active">event_available</mat-icon>
                <mat-icon class="inactive" *ngIf="!newslet.active" matTooltip="Inactive">event_busy</mat-icon>
              </div>
              <div class="tab-name" [matTooltip]="newslet.title">
                <span>{{newslet.title}}</span>
              </div>
              <div class="tab-edit" (click)="$event.stopPropagation(); inDelete(newslet.uid);" matTooltip="Delete Newsletter">
                <mat-icon *ngIf="newslet.uid==params.id">close</mat-icon>
              </div>
              <span class="tab-divider" [class.tab-divider-transparent]="checkCurrentNewsletterIndex(newslet.uid, $newsletters|async|orderBy:'title')"></span>
            </a>
          </nav>
        </div>

        <div class="router-outlet">
          <router-outlet></router-outlet>
        </div>

      </div>
    </div>
  </div>
  <div class="newsletter-bside">
    <app-menu-bside></app-menu-bside>
  </div>

</div>


<!--<div class="news-letter">
  <div class="letter-side">
    <div class="side-header">Newsletters</div>
    <div class="side-main">
      <div class="side-letter" *ngFor="let item of $newsletters|async" [class.active]="item.uid==params.id">
        <button mat-icon-button matTooltip="Delete newsletter" (click)="inDelete(item.uid)">
          <mat-icon>close</mat-icon>
        </button>
        <div class="pointer" routerLink="/cockpit/newsletter" routerLinkActive="active" [queryParams]="{id: item.uid}" queryParamsHandling="merge">
          <div class="txt">{{item.title}}</div>
          <div class="act act1" *ngIf="item.active">ACTIVE</div>
          <div class="act act2" *ngIf="!item.active">INACTIVE</div>
        </div>
      </div>
    </div>
    <div class="side-acto">
      <button mat-raised-button routerLink="/cockpit/newsletter" routerLinkActive="active" [queryParams]="{id: 'create'}" queryParamsHandling="merge">CREATE</button>
    </div>
  </div>
  <div class="letter-main">
    <app-newsletter-form [newsletter]="newsletter" [newsletterParamsReady]="newsletterParamsReady"></app-newsletter-form>
  </div>
  <div class="letter-page">
    <app-newsletter-page [newsletter]="newsletter" [newsletterParamsReady]="newsletterParamsReady"></app-newsletter-page>
  </div>
</div>-->
