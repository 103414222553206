<div class="homepage-insights-main">

    <div class="homepage-insights-chart" *ngIf="!loading && !nodata" [chart]="chart"></div>

    <div class="loading" *ngIf="loading">
        <mat-spinner [diameter]="50" *ngIf="!nodata"></mat-spinner>
    </div>
    <div class="nodata" *ngIf="nodata">NOT ENOUGH DATA</div>

</div>
