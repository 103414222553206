<div class="yukkapp">
  <div class="userform">
    <div class="logo">
      <img *ngIf="logo !== 'universal'" src="assets/logo/{{logo}}_default.svg" alt="yukkalab" routerLink="/">
      <img *ngIf="logo === 'universal'" src="assets/logo/{{logo}}_default.png" alt="yukkalab" routerLink="/">
    </div>
    <form [formGroup]="formEmail" (ngSubmit)="sendEmail($event)" *ngIf="params.id === 'request'">
      <mat-form-field class="blok email">
        <input matInput formControlName="email" type="email" placeholder="Email" name="request_password_email">
        <mat-hint *ngIf="nouser">Something went wrong, please try again later</mat-hint>
      </mat-form-field>
      <div class="loading-container">
        <div class="loading login" *ngIf="loading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="btncont">
          <button mat-raised-button type="submit" *ngIf="!loading">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="formPassword" (ngSubmit)="setPassword($event)" *ngIf="params.id === 'change'">
      <mat-form-field class="blok password">
        <input matInput formControlName="password1" type="password" placeholder="Old password" name="old_password" [type]="hideOld ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hideOld = !hideOld">{{hideOld ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password1'].errors?.required">Field can't be empty</mat-error>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password1'].errors?.minlength">At least 6 characters</mat-error>
      </mat-form-field>
      <mat-form-field class="blok password password2">
        <input matInput formControlName="password2" type="password" placeholder="New password" name="new_password" [type]="hideNew ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hideNew = !hideNew">{{hideNew ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password2'].errors?.required">Field can't be empty</mat-error>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password2'].errors?.pattern">Password must contain at least 8 characters, including upper case letter, lower case letter, number and special character</mat-error>
      </mat-form-field>
      <div class="loading-container">
        <div class="loading login" *ngIf="loading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="btncont">
          <button mat-raised-button type="submit" *ngIf="!loading">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="formPassword" (ngSubmit)="setPassword($event)" *ngIf="params.id === 'reset'">
      <mat-form-field class="blok password">
        <input matInput formControlName="password1" type="password" placeholder="New password" name="new_password_reset" [type]="hideOld ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hideOld = !hideOld">{{hideOld ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password1'].errors?.required">Field can't be empty</mat-error>
        <mat-error *ngIf="!formPassword.valid && formPassword.controls['password1'].errors?.minlength">At least 6 characters</mat-error>
        <!-- <mat-hint *ngIf="!formPassword.valid">{{formPassword.errors}}</mat-hint> -->
      </mat-form-field>
      <mat-form-field class="blok password password2">
        <input matInput formControlName="password2" type="password" placeholder="Confirm password" name="new_password_confirm" [type]="hideNew ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hideNew = !hideNew">{{hideNew ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <div class="loading-container">
        <div class="loading login" *ngIf="loading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="btncont">
          <button mat-raised-button type="submit" *ngIf="!loading">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </form>
    <div class="disclaimer">
      <!--<span *ngIf="project!='ubs'">
        <a class="link" href="https://shop.yukkalab.de/" target="_black">registration</a> -
      </span>-->
      <span class="link" routerLink="/login">login page</span>
    </div>
  </div>

  <div class="impressum">
    <span>
      <a class="link" href="https://www.yukkalab.com/imprint/" target="_blank">Impressum / Legal Disclosure</a>
    </span>
  </div>
</div>
