<div class="queryform">
  <!-- <a href="/">LOGO</a> -->
  <div class="querycont">
    <form [formGroup]="queryForm" (ngSubmit)="goQuery()">
      <div class="blok">
        <mat-form-field>
          <mat-select placeholder="Database" formControlName="query0" (selectionChange)="goDB($event.value)">
            <mat-option value="report" (click)="goDB('report')">Report</mat-option>
            <!--<mat-option value="feedback" (click)="goDB('feedback')">Feedback</mat-option>-->
            <mat-option value="annotation" (click)="goDB('annotation')">Annotation</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="blok" *ngIf="databaseType === 'report'">
        <mat-form-field>
          <mat-select placeholder="Report type" formControlName="reportType" (selectionChange)="selectReportType($event.value)">
            <mat-option value="all">All types</mat-option>
            <mat-option value="sentiment">Sentiment</mat-option>
            <!--<mat-option value="Sentiment positive">Sentiment positive</mat-option>
            <mat-option value="Sentiment neutral">Sentiment neutral</mat-option>
            <mat-option value="Sentiment negative">Sentiment negative</mat-option>-->
            <mat-option value="entity">Entity</mat-option>
            <!--<mat-option value="Wrong entity recognized">Wrong entity recognized</mat-option>
            <mat-option value="Entity not recognized">Entity not recognized</mat-option>-->
            <mat-option value="event">Event</mat-option>
            <!--<mat-option value="Wrong event recognized">Wrong event recognized</mat-option>
            <mat-option value="Event not recognized">Event not recognized</mat-option>
            <mat-option value="Wrong participant recognized">Wrong participant recognized</mat-option>
            <mat-option value="Participant not recognized">Participant not recognized</mat-option>-->
            <mat-option value="other">Custom report</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!--<div class="blok">
        <mat-form-field>
          <mat-select placeholder="Parameter" formControlName="query1">
            <mat-option value="id">Id</mat-option>
            <mat-option value="email">Email</mat-option>
            <mat-option value="report" matTooltip="Possible values: 'Sentiment positive', 'Sentiment neutral', 'Sentiment negative', 'Wrong entity recognized', 'Entity not recognized', 'Wrong event recognized', 'Event not recognized', 'Wrong participant recognized', 'Participant not recognized', 'Custom report'">Report</mat-option>
            <mat-option value="reportInfo.type" matTooltip="Possible values: 'sentiment', 'entity', 'event', 'other'">Report type</mat-option>
            <mat-option value="reportInfo.error" matTooltip="Possible values: 'wrong entity', 'unrecognized entity', 'wrong event', 'unrecognized event', 'wrong participant', 'unrecognized participant'">Error type</mat-option>
            <mat-option value="reportInfo.sentiment" matTooltip="Possible values: 'positive', 'neutral', 'negative'">Sentiment</mat-option>
            <mat-option value="message">Message</mat-option>
            <mat-option value="label">Label</mat-option>
            <mat-option value="project">Project</mat-option>
            <mat-option value="language">Language</mat-option>
            <mat-option value="time">Time</mat-option>
            <mat-option value="reportInfo.entity">Entity name</mat-option>
            <mat-option value="reportInfo.entityType">Entity type</mat-option>
            <mat-option value="reportInfo.entityId">Entity id</mat-option>
            <mat-option value="reportInfo.event">Event name</mat-option>
            <mat-option value="reportInfo.eventType">Event type</mat-option>
            <mat-option value="reportInfo.eventId">Event id</mat-option>
            <mat-option value="reportInfo.participant">Participant name</mat-option>
            <mat-option value="reportInfo.participantType">Participant type</mat-option>
            <mat-option value="reportInfo.participantId">Participant id</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="blok">
        <mat-form-field>
          <mat-select placeholder="Operator" formControlName="query2">
            <mat-option value="less">Less</mat-option>
            <mat-option value="equal">Equal</mat-option>
            <mat-option value="more">More</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="blok">
        <mat-form-field>
          <input matInput placeholder="Input" formControlName="query3">
        </mat-form-field>
      </div>-->
    </form>
  </div>
</div>
