export const autofolio = [
  // {
  //   name: 'Automotive',
  //   content: [
  //     'company:tesla_motors',
  //     'company:volkswagen',
  //     'company:bmw',
  //     'company:daimler',
  //   ]
  // },
  {
    name: 'NASDAQ Sample',
    content: ['company:electronic_arts', 'company:alphabet_inc', 'company:autodesk', 'company:biogen', 'company:costco_wholesale', 'company:asml', 'company:cadencedesign', 'company:activision_blizzard', 'company:fiserv', 'company:facebook', 'company:adobe_systems', 'company:apple', 'company:broadcom', 'company:align_biopharma', 'company:advanced_micro', 'company:baidu', 'company:check_point', 'company:cognizant_technology_solutions', 'company:cintas', 'company:incyte', 'company:fastenal', 'company:illumina', 'company:intel', 'company:csx', 'company:analog_device', 'company:gilead_sciences', 'company:cisco_systems', 'company:idexxlaboratories', 'company:comcast', 'company:charter_communications', 'company:alexion_pharmaceuticals', 'company:fox_corp_class_a', 'company:automatic_data_processing', 'company:applied_materials', 'company:amgen', 'company:amazon_com', 'company:cerner', 'company:dollar_tree', 'company:ebay']
  }
];
