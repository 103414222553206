<div class="main-container" *ngIf="newValue && (oldValue || (oldValue === 0))">
    <ng-container *ngIf="!(oldValue === 0)">
        <div class="line1" [ngStyle]='{ width : ((newValue > oldValue) ? (((oldValue/newValue) * 100) + "%") : (((newValue/oldValue) * 100) + "%")) }'></div>
        <div class="triangle-right" [class.trend-pos]="polarity === 1" [class.trend-neg]="polarity === -1" *ngIf="newValue > oldValue"></div>
        <div class="marker middle" [class.trend-pos]="polarity === -1" [class.trend-neg]="polarity === 1" *ngIf="newValue <= oldValue"></div>
    </ng-container>
    <ng-container *ngIf="!(newValue === oldValue)">
        <div class="line2 full" [class.trend-pos]="polarity === 1" [class.trend-neg]="polarity === -1" *ngIf="newValue > oldValue"></div>
        <div class="line2 dashed" [class.trend-pos]="polarity === -1" [class.trend-neg]="polarity === 1" *ngIf="newValue < oldValue"></div>
        <div class="triangle-left" [class.trend-pos]="polarity === -1" [class.trend-neg]="polarity === 1" *ngIf="newValue < oldValue"></div>
        <div class="marker" [class.trend-pos]="polarity === 1" [class.trend-neg]="polarity === -1" *ngIf="newValue > oldValue"></div>
    </ng-container>
</div>
<div class="main-container" *ngIf="newValue && !(oldValue || (oldValue === 0))">
    <div class="line1" [ngStyle]='{ width : "100%" }'></div>
</div>
