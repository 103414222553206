import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-event-sorting-toggle",
  templateUrl: "./event-sorting-toggle.component.html",
})
export class EventSortingToggleComponent {
  @Input() sortByOptions: {
    value: string;
    icon: string;
    label?: string;
    tooltip?: string;
    class?: string;
  }[] = [];
  @Input() sortBy: string;
  @Input() hideTooltip: boolean = true; // Default value
  @Output() sortByChange = new EventEmitter<string>();

  onSortByChange(event: any) {
    this.sortByChange.emit(event.value);
  }

}
