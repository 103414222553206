<div class="stepper" *ngIf="tutorial" [class.fullscreen]="fullscreen"
     [class.newsfeed]="tutorial=='newsfeed' || tutorial=='secsignal1' || tutorial=='indicator1'"
     [class.mainview]="tutorial=='market' || tutorial=='portfolio' || tutorial=='collection' || tutorial=='entity' || tutorial=='aggregation' || tutorial=='network' || tutorial=='radar' || tutorial=='secsignal2' || tutorial=='indicator2' || tutorial=='multichart' || tutorial=='chart'"
     [class.treemap]="tutorial=='treemap'"
     [class.tagcloud]="tutorial=='tagcloud'"
     [class.newsviewfeeds]="tutorial=='newsviewfeeds'"
     [class.newsviewinsights]="tutorial=='newsviewinsights'">
  <div class="cont">
    <div class="topo" *ngIf="istep">
      <div class="info">
        <div class="tito">
          <mat-icon>{{istep.icon}}</mat-icon> {{istep.tito}}
        </div>
        <div class="desk" [innerHTML]="istep.desk"></div>
      </div>
      <mat-accordion *ngIf="istep.menu">
        <mat-expansion-panel *ngFor="let item of istep.menu; let i=index" [expanded]="expandol==i">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>{{item.icon}}</mat-icon> {{item.tito}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="desk" [innerHTML]="item.desk"></div>
          <mat-accordion *ngIf="item.menu">
            <mat-expansion-panel *ngFor="let itom of item.menu" [disabled]="!itom.desk">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>{{itom.icon}}</mat-icon> {{itom.tito}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="desk" [innerHTML]="itom.desk"></div>
              <mat-accordion *ngIf="itom.menu" multi="true">
                <mat-expansion-panel *ngFor="let itam of itom.menu" expanded="false" [disabled]="!itam.desk">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>{{itam.icon}}</mat-icon> {{itam.tito}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div>{{itam.desk}}</div>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="acto">
      <div class="acto-cont">
        <button mat-raised-button [disabled]="step==0" (click)="goStep(step-1)">
          <mat-icon>navigate_before</mat-icon>
          <span>Back</span>
        </button>
        <button mat-raised-button [disabled]="step==steps.length-1" (click)="goStep(step+1)">
          <span>Next</span>
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
      <button mat-button [routerLink]="[]" [queryParams]="{tutorial:null,tutorial_steps:null}" queryParamsHandling="merge">
        <span>Exit</span>
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </div>
</div>
