export const helpData = (scoreType) => {
  return {
    scanner: {
      header: 'To learn more about the Scanner, watch this video:',
      video: 'https://www.loom.com/embed/16d9d6d064aa4d7594ef926580d1123b',
      content: [
        'Screen set of companies using the ' + scoreType,
        'Select a row from the table to find out which events are driving the ' + ((scoreType === 'Sentiment') ? 'sentiment' : 'score') + ' for the company',
        'Click on an event to read the underlying event summary for the news',
        'Next to the event spans, you can find all news or top news content',
        'Select a timeframe on the chart to see how the ' + scoreType + ' has been trending',
        'Click the company name to get to the company page for more in-depth detail'
      ]
    },
    portfolio: {
      edit: {
        header: 'To learn more about the Portfolio Edit view, watch this video:',
        video: 'https://www.loom.com/embed/f2ec12ed69134b2e810bb6373d3bb771',
        content: [
          'Create a portfolio to monitor entities such as companies, countries, currencies, topics or sectors',
          'Edit or Delete Portfolios',
          'Create as many portfolio’s as you like',
          'Sort your entities using the columns in the table',
          'Read Top News or All News or access your Bookmarked News',
          'Click the company name to get to the company chart view for more in-depth detail'
        ]
      },
      audit: {
        header: '',
        video: '',
        content: [
          'Scan the news',
          'Bookmark, Flag or Dismiss articles',
          'Access your bookmarked, flagged or dismissed articles',
          'Flagging an article allows you to share it with colleagues and it automatically creates an audit trail',
          'Click on Portfolio to select companies from your portfolio who are involved in events like Mergers, Product Launch etc.',
          'Select Discover to uncover what events are covered in the news and which companies & countries were involved or mentioned in relation to the events',
          'Click on the 3 dots to export an audit trail from flagging or to download the events insights'
        ]
      }
    },
    collection: {
      edit: {
        header: 'To learn more about the Collections Edit view, watch this video:',
        video: 'https://www.loom.com/embed/c798a32c7a724c4db9caf0eb777ab0cb',
        content: [
          'Use collections and queries to combine entities such as companies, countries, events, free text, topics into one query search or',
          'Create collection and queries for entities we don’t have in our database and can’t be added to a portfolio',
          'Create multiple collections with multiple queries',
          'Edit or Delete collections and queries',
          'Sort your queries using the columns in the table',
          'Read Top News or All News or access your Bookmarked News',
          'Click the query name to get to the queries chart view for more in-depth detail',
          'Click on the News View to uncover insights from your query'
        ]
      },
      audit: {
        header: 'To learn more about the News View, watch this video:',
        video: 'https://www.loom.com/embed/c798a32c7a724c4db9caf0eb777ab0cb',
        content: [
          'Scan the news',
          'Bookmark, Flag or Dismiss articles',
          'Access your bookmarked, flagged or dismissed articles',
          'Flagging an article allows you to share it with colleagues and it automatically creates an audit trail',
          'Click on Participants to uncover companies from your collection who are involved in events like Mergers, Product Launch etc.',
          'Select Discover to uncover what events are covered in the news and which companies & countries were involved or mentioned in relation to the events',
          'Click on the 3 dots to export an audit trail from flagging or to download the events insights'
        ]
      }
    },
    chart: {
      header: 'To learn more about the Chart view, watch this video:',
      video: 'https://www.loom.com/embed/574ad1161f7e4177a82a6b983add3acd',
      content: [
        'Analyse how the ' + ((scoreType === 'Sentiment') ? 'Sentiment' : (scoreType + ' & Sentiment')) + ' changes over time',
        'Add/remove details from the chart as required',
        'Export the data from the chart',
        'Access the Events covered in the news below the chart and select one to read relevant news',
        'Choose a Topic from the topic list to filter the newsfeed'
      ]
    },
    benchmark: {
      header: '',
      video: '',
      content: [
        'Compare the sentiment trend of different companies against each other',
        'Select the companies you want to compare from the legend',
        'Export this chart along with the data',
        'Explore events and topics mentioned in the news'
      ]
    },
    radar: {
      header: '',
      video: '',
      content: [
        'Compare the sentiment and volume of articles of different entities against each other',
        'The color of the bubble represents the sentiment (green - positive, yellow – neutral and red - negative)',
        'The size of the bubble represents how much news there is in the media for each entity. This gives you an indication of which entities are trending in the media and how they are perceived compared to others',
        'Explore events and topics mentioned in the news'
      ]
    },
    network: {
      header: '',
      video: '',
      content: [
        'Select an entity or a bubble to see the relationships in the media between different entities',
        'Read the related news'
      ]
    },
    outlier: {
      header: 'To learn more about the Outlier Detection, watch this video:',
      video: 'https://www.loom.com/embed/0d82a8ff7ee245dd9ca824e0e09dcab1',
      content: [
        'Spot outliers in the media',
        'Entities in the top right are the “top movers” (they have high sentiment and high media presence)',
        'Entities in the bottom left are the “bottom losers” (low sentiment and high media presence)',
        'Clicking on an entity in the matrix will show you extra information and filter the news'
      ]
    },
    trending: {
      header: 'To learn more about the Trending view, watch this video:',
      video: 'https://www.loom.com/embed/4d178db6e47b4ef08e18b0a7c39e7543',
      content: [
        'View a list of relevant companies based on our unique algorithm',
        'Discover companies that aren’t on your radar but could be interesting to watch'
      ]
    },
    newsletter: {
      header: 'To learn more on how to create a newsletter watch this video:',
      video: 'https://www.loom.com/embed/a03d54ce112c4bbdb2ecf758bd186733',
      content: [
        'Curate newsletters with the most relevant news to share with your team',
        'Add custom images or logos to fit your business theme',
        'Set the frequency for how often you want to send out the newsletter',
        'Select the type of content you want to include such as bookmarked news, all news, top news',
        'Select the design elements you want to include',
        'Add a signature and disclaimer',
        'Review the content of the newsletter by clicking on View on the right before sending out your article'
      ]
    },
    article: {
      header: 'To learn more what you can do within an article, watch this video:',
      video: 'https://www.loom.com/embed/282999f8cf0b4303986e21ce06e49621',
      content: [
        'Select an entity to see where the entity was mentioned in the news',
        'Select an event to read the event excerpt',
        'Bookmark an article to access it later on',
        'Flag an article to a colleague which creates an audit trail',
        'Dismiss articles that aren’t important',
        'Annotate an article which adds comments to the chart in the Chart view',
        'Click on Report to report any issues in the article'
      ]
    },
    filter: {
      header: 'To learn more about the filters, watch this video:',
      video: 'https://www.loom.com/embed/851beb620e204c4b95f1d2c84ada3310',
      content: [
        'These are the filters available to allow you customize what you’re looking at to ensure relevancy',
        'Choose from multiple filters such as Time, Sentiment, Language, Categories, Geography and Events',
        'Save your filter so that you can use it in future',
        'Set your filter as the default filter so that it is applied whenever you login'
      ]
    }
  };
};
