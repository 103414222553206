<div class="portfolio-import">
  <div mat-dialog-content>
    <mat-form-field appearance="outline" class="inptbox">
      <mat-label>Portfolio name</mat-label>
      <input matInput [formControl]="namefolio" autocomplete="off" [readonly]="!data.create">
      <mat-error *ngIf="namefolio.invalid">Min length 3</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="inptbox" *ngIf="!ready">
      <mat-icon class="infoicon" [matTooltip]="tooltip" matTooltipPosition="above">help_outline</mat-icon>
      <mat-label>ISIN</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [(ngModel)]="import"></textarea>
    </mat-form-field>
    <mat-accordion *ngIf="ready">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>{{isins.length}} Found</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item *ngFor="let isin of isins|orderBy">{{isin.name}}</mat-list-item>
        </mat-list>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{nones.length}} Not Found</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item *ngFor="let none of nones|orderBy">{{none}}</mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="iParse()" *ngIf="!ready">NEXT</button>
    <button mat-raised-button color="primary" (click)="ready=!ready" *ngIf="ready">CANCEL</button>
    <button mat-raised-button color="primary" (click)="iSubmit()" *ngIf="ready">SUBMIT</button>
  </div>
</div>
