<div class="trending-main" *ngIf="trending">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
  <ng-container *ngIf="!(loading || nodata)">
    <div class="titolo">
      <div class="border">
        <div class="bar" [style.background-color]="'transparent'" [style.color]="'transparent'"></div>
      </div>
      <div class="box-trend">
        <div class="tito">
          <div class="cont">
            <span class="value logo header"></span>
            <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">
              <span class="sort" (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Company </span>
              <span [class.no-sort]="!(params.sort==='abc'||params.sort==='-abc')">
                {{((params.sort==='abc') ? -1 : 1 )|trend}}
              </span>
            </span>
          </div>
        </div>
        <div class="info">
          <div class="cont">
            <span *ngIf="iTool('Country')" class="value" style="pointer-events: none;">
              <span class="sort">Country </span>
            </span>
            <span *ngIf="iTool('BB Score')" class="value" [class.sort-active]="params.sort==='bull_bear'||params.sort==='-bull_bear'">
              <span class="sort" (click)="goSort('bull_bear')">BB </span>
              <span [class.no-sort]="!(params.sort==='bull_bear'||params.sort==='-bull_bear')">
                {{((params.sort==='bull_bear') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('ESG Score')" class="value" [class.sort-active]="params.sort==='esg'||params.sort==='-esg'">
              <span class="sort" (click)="goSort('esg')">ESG </span>
              <span [class.no-sort]="!(params.sort==='esg'||params.sort==='-esg')">
                {{((params.sort==='esg') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('ESGR Score')" class="value" [class.sort-active]="params.sort==='esg_risk'||params.sort==='-esg_risk'">
              <span class="sort" (click)="goSort('esg_risk')">ESGR </span>
              <span [class.no-sort]="!(params.sort==='esg_risk'||params.sort==='-esg_risk')">
                {{((params.sort==='esg_risk') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('CR Score')" class="value" [class.sort-active]="params.sort==='credit_risk'||params.sort==='-credit_risk'">
              <span class="sort" (click)="goSort('credit_risk')">CR </span>
              <span [class.no-sort]="!(params.sort==='credit_risk'||params.sort==='-credit_risk')">
                {{((params.sort==='credit_risk') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('GR Score')" class="value" [class.sort-active]="params.sort==='general_risk'||params.sort==='-general_risk'">
              <span class="sort" (click)="goSort('general_risk')">GR </span>
              <span [class.no-sort]="!(params.sort==='general_risk'||params.sort==='-general_risk')">
                {{((params.sort==='general_risk') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('IR Score')" class="value" [class.sort-active]="params.sort==='immediate_risk'||params.sort==='-immediate_risk'">
              <span class="sort" (click)="goSort('immediate_risk')">IR </span>
              <span [class.no-sort]="!(params.sort==='immediate_risk'||params.sort==='-immediate_risk')">
                {{((params.sort==='immediate_risk') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('EB-SIM Score')" class="value" [class.sort-active]="params.sort==='eb_sim'||params.sort==='-eb_sim'">
              <span class="sort" (click)="goSort('eb_sim')">EB-SIM </span>
              <span [class.no-sort]="!(params.sort==='eb_sim'||params.sort==='-eb_sim')">
                {{((params.sort==='eb_sim') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('EB-SIMR Score')" class="value" [class.sort-active]="params.sort==='eb_sim_risk'||params.sort==='-eb_sim_risk'">
              <span class="sort" (click)="goSort('eb_sim_risk')">EB-SIMR </span>
              <span [class.no-sort]="!(params.sort==='eb_sim_risk'||params.sort==='-eb_sim_risk')">
                {{((params.sort==='eb_sim_risk') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Price')" class="value" style="pointer-events: none;">
              <span class="sort">Price </span>
            </span>
            <span *ngIf="iTool('Volatility')" class="value" [class.sort-active]="params.sort==='vola'||params.sort==='-vola'">
              <span class="sort" (click)="goSort('vola')" matTooltip="Volatility">Vola </span>
              <span [class.no-sort]="!(params.sort==='vola'||params.sort==='-vola')">
                {{((params.sort==='vola') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Price Change')" class="value" [class.sort-active]="params.sort==='price'||params.sort==='-price'">
              <span class="sort" (click)="goSort('price')">ΔPrice </span>
              <span [class.no-sort]="!(params.sort==='price'||params.sort==='-price')">
                {{((params.sort==='price') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Sentiment')" class="value" [class.sort-active]="params.sort==='sent'||params.sort==='-sent'">
              <span class="sort" (click)="goSort('sent')">Sent </span>
              <span [class.no-sort]="!(params.sort==='sent'||params.sort==='-sent')">
                {{((params.sort==='sent') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Volume')" class="value" [class.sort-active]="params.sort==='count'||params.sort==='-count'">
              <span class="sort" (click)="goSort('count')">Vol </span>
              <span [class.no-sort]="!(params.sort==='count'||params.sort==='-count')">
                {{((params.sort==='count') ? 1 : -1 )|trend}}
              </span>
            </span>

            <span class="value edit header-menu" (click)="menuFilter.open()">
              <mat-icon matTooltip="Adjust columns to be displayed">more_vert</mat-icon>
              <mat-select [formControl]="ifilter" multiple (openedChange)="iFilter($event)" #menuFilter>
                <mat-option *ngFor="let filter of array_filter" [value]="filter">{{filter}}</mat-option>
              </mat-select>
            </span>

          </div>
        </div>
      </div>
    </div>
    <div class="item-cont" *ngIf="trending">
      <div class="box-trend" *ngFor="let item of trending | search:'name,isin':searchTerm | orderBy: myOrder" [class.active]="qparams.tag == ('m:' + item.type + ':' + item.alpha_id)" (click)="navigateTo(item)">
        <div class="tito">
          <div class="cont">
            <span class="value logo">
              <img *ngIf="item.logo" [src]="item.logo">
              <span *ngIf="!item.logo" class="logo-default">{{item.name.charAt(0)}}</span>
            </span>
            <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">{{item.name}}</span>
          </div>
        </div>
        <div class="info">
          <div class="cont">
            <span *ngIf="iTool('Country')" class="value" [matTooltip]="item.country_name">
              <span>{{item.country_id}}</span>
            </span>
            <span *ngIf="iTool('BB Score')" class="value" [class.sort-active]="params.sort==='bull_bear'||params.sort==='-bull_bear'">
              <span class="icon">
                <img *ngIf="item.trend_type === 'bear'" src="assets/icon/bear.svg">
                <img *ngIf="item.trend_type === 'bull'" src="assets/icon/bull.svg">
              </span>
              <span [class.bear]="item.trend_type === 'bear'" [class.bull]="item.trend_type === 'bull'">{{item.trend_value !== null ? item.trend_value : ''}}</span>
            </span>
            <span *ngIf="iTool('ESG Score')" class="value" [class.sort-active]="params.sort==='esg'||params.sort==='-esg'">
              <span>{{item.esg_score}}</span>
            </span>
            <span *ngIf="iTool('ESGR Score')" class="value" [class.sort-active]="params.sort==='esg_risk'||params.sort==='-esg_risk'">
              <span>{{item.esg_risk_score}}</span>
            </span>
            <span *ngIf="iTool('CR Score')" class="value" [class.sort-active]="params.sort==='credit_risk'||params.sort==='-credit_risk'">
              <span>{{item.credit_risk_score}}</span>
            </span>
            <span *ngIf="iTool('GR Score')" class="value" [class.sort-active]="params.sort==='general_risk'||params.sort==='-general_risk'">
              <span>{{item.general_risk_score}}</span>
            </span>
            <span *ngIf="iTool('IR Score')" class="value" [class.sort-active]="params.sort==='immediate_risk'||params.sort==='-immediate_risk'">
              <span>{{item.immediate_risk_score}}</span>
            </span>
            <span *ngIf="iTool('EB-SIM Score')" class="value" [class.sort-active]="params.sort==='eb_sim'||params.sort==='-eb_sim'">
              <span>{{item.eb_sim_score}}</span>
            </span>
            <span *ngIf="iTool('EB-SIMR Score')" class="value" [class.sort-active]="params.sort==='eb_sim_risk'||params.sort==='-eb_sim_risk'">
              <span>{{item.eb_sim_risk_score}}</span>
            </span>
            <span *ngIf="iTool('Price')" class="value">
              <span>{{item.price | round: 2}} {{(item.currency !== null ? item.currency : '')}}</span>
            </span>
            <span *ngIf="iTool('Volatility')" class="value" [class.sort-active]="params.sort==='vola'||params.sort==='-vola'">
              <!--<span class="icon">
                <img *ngIf="item.volatility_type === 'dolphin'" src="assets/icon/dolphin.svg">
                <img *ngIf="item.volatility_type === 'whale'" src="assets/icon/whale.svg">
              </span>-->
              <span [class.dolphin]="item.volatility_type === 'dolphin'" [class.whale]="item.volatility_type === 'whale'">{{(item.volatility_value !== null ? item.volatility_value + '%' : '')}}</span>
            </span>
            <span *ngIf="iTool('Price Change')" class="value" [class.sort-active]="params.sort==='price'||params.sort==='-price'">
              <span>{{(item.price_delta !== null ? ((item.price_delta > 0) ? '+' : '') + item.price_delta + '%' : '')}}</span>
            </span>
            <span *ngIf="iTool('Sentiment')" class="value" [class.sort-active]="params.sort==='price'||params.sort==='-price'">
              <span>{{item.sentiment | percent}}</span>
            </span>
            <span *ngIf="iTool('Volume')" class="value">
              <span>{{item.volume}}</span>
            </span>


            <span class="value edit" [class.transparent]="true">
              <mat-icon>mode_edit</mat-icon>
            </span>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
