import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { Observable, forkJoin } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PortfolioShareComponent } from '../portfolio-share/portfolio-share.component';

@Component({
  selector: 'app-portfolio-name',
  templateUrl: './portfolio-name.component.html',
  styleUrls: ['./portfolio-name.component.scss']
})
export class PortfolioNameComponent {

  namefolio: UntypedFormControl;
  $newsletter: Observable<any>;
  idelete = 0;
  newsletterTitle = '';
  portfoliosList = [];
  selectedPortfolios = [];
  portfolioType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService
  ) {

    this.portfolioType = 'regular';

    if (this.data && this.data.entity && !this.data.entity.compound_key && this.data.entity.type && this.data.entity.alpha_id) {
      this.data.entity.compound_key = this.data.entity.type + ':' + this.data.entity.alpha_id;
    }

    if (this.data.action === 'add') {
      this.auth.portFolios().subscribe(result => {
        this.portfoliosList = result.filter(item => {
          return (!(item.sharing_mode === 'read') && !item.is_aggregated);
        });
      });
    }

    this.namefolio = new UntypedFormControl('', [Validators.required, Validators.minLength(3)]);
    if (this.data.action === 'edit') {
      this.namefolio.setValue(this.auth.folio.name);
    }

  }

  iSave() {
    if (this.namefolio.valid) {
      if (this.data.action === 'edit') {
        this.auth.renamePortfolio(this.namefolio.value).subscribe(_ => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              update: Math.random()
            }, queryParamsHandling: 'merge'
          });
        });
      }
      if (this.data.action === 'new') {
        if (this.auth.folios && ((this.auth.folios.length + 1) > this.auth.featureFlags.portfoliosLimit)) {
          this.snackBar.open('Number of portfolios is limited to ' + this.auth.featureFlags.portfoliosLimit + '.', 'OK', { duration: 5000 });
        } else {
          this.auth.newFolio(this.namefolio.value, this.portfolioType).subscribe(result => {
            this.dialog.closeAll();
            this.router.navigate([], {
              queryParams: {
                id: result.id,
                update: Math.random()
              }, queryParamsHandling: 'merge'
            });
          });
        }
      }
    }
  }

  delete() {
    this.auth.deletePortfolio().subscribe(risp => {
      this.dialog.closeAll();
      this.router.navigate([], {
        queryParams: {
          id: null,
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  iDelete() {
    this.$newsletter = this.auth.allNewsletter();
    this.$newsletter.subscribe(results => {
      const hasNewsletter = results.filter(result => {
        const hasIt = result.displayables.filter(result2 => {
          const resArray = result2.split(':');
          if (resArray[2] === 'portfolio') {
            return resArray[3] === this.auth.folio.uid;
          } else {
            return false;
          }
        });
        if (hasIt.length > 0) {
          return true;
        } else {
          return false;
        }
      });
      if ( hasNewsletter.length > 0 ) {
        this.idelete = 1;
        this.newsletterTitle = '"' + hasNewsletter[0].title + '"';
      } else {
        this.delete();
        this.idelete = 0;
      }
    });
  }

  /**
   * share portfolio with other users
   */
  iShare() {
    if (!this.auth.folio.sharing_mode) {
      this.dialog.open(PortfolioShareComponent, {
        data: {
          // uid: this.params.folio,
        }
      });
    }
  }

  /**
   * create portfolio with provided entity
   */
  iCreate(openPortfolio) {
    if (this.namefolio.valid) {
      if (this.auth.folios && ((this.auth.folios.length + 1) > this.auth.featureFlags.portfoliosLimit)) {
        this.snackBar.open('Number of portfolios is limited to ' + this.auth.featureFlags.portfoliosLimit + '.', 'OK', { duration: 5000 });
      } else {
        this.auth.newFolio(this.namefolio.value).subscribe(result => {
          this.auth.addEntitiesToPortfolios([this.data.entity.compound_key], [result.id]).subscribe(() => {
            this.dialog.closeAll();
            this.snackBar.open('Portfolio ' + this.namefolio.value + ' created.', 'OK', { duration: 5000 });
            if (openPortfolio) {
              this.router.navigate(['/cockpit/news/portfolio'], {
                queryParams: {
                  id: result.id,
                  update: Math.random()
                }, queryParamsHandling: 'merge'
              });
            }
          });
        });
      }
    }
  }

  /**
   * add entity to selected portfolios
   */
  iAdd(openPortfolio) {
    if (this.selectedPortfolios.length > 0) {
      const portfoliosArray = this.selectedPortfolios;
      let allowAdd = true;
      this.portfoliosList.forEach(portfolio => {
        if (portfoliosArray.includes(portfolio.uid) && ((portfolio.content.length + 1) > this.auth.featureFlags.entitiesPerPortfoliosLimit)) {
          allowAdd = false;
        }
      });
      if (!allowAdd) {
        this.snackBar.open('Number of entities per portfolio is limited to ' + this.auth.featureFlags.entitiesPerPortfoliosLimit + '.', 'OK', { duration: 5000 });
      } else {
        this.auth.addEntitiesToPortfolios([this.data.entity.compound_key], portfoliosArray).subscribe(() => {
          this.dialog.closeAll();
          this.snackBar.open('Entity added to selected portfolios.', 'OK', { duration: 5000 });
          if (openPortfolio && (this.selectedPortfolios.length === 1)) {
            this.router.navigate(['/cockpit/news/portfolio'], {
              queryParams: {
                id: this.selectedPortfolios[0],
                update: Math.random()
              }, queryParamsHandling: 'merge'
            });
          }
        });
      }
    }
  }

}
