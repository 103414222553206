<div class="recommendations-main">
    <div class="recommendations-header">
        <div class="recommendations-header-title">
            Assisted Bookmarking
        </div>
        <div class="recommendations-header-actions">
            <mat-icon matTooltip="Close" matTooltipPosition="below" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="recommendations-container">
        <div class="recommendations-input">
            <div class="recommendations-input-label">I am looking for</div>
            <input matInput type="text" [(ngModel)]="mySearch" [matAutocomplete]="auto" name="recommendationSearchInput">
            <mat-autocomplete panelWidth="301px" #auto="matAutocomplete">
                <mat-option class="search-menu-option" *ngFor="let previousSearch1 of previousSearchesStarred" [value]="previousSearch1">
                    <div class="option-row">
                        <div class="option-text">
                            {{previousSearch1}}
                        </div>
                        <div class="option-actions">
                            <mat-icon *ngIf="isStarred(previousSearch1)" (click)="$event.stopPropagation(); unstar(previousSearch1);">star</mat-icon>
                            <mat-icon *ngIf="!isStarred(previousSearch1)" (click)="$event.stopPropagation(); star(previousSearch1);">star_border</mat-icon>
                        </div>
                    </div>
                </mat-option>
                <mat-option class="search-menu-option" *ngFor="let previousSearch2 of previousSearchesUnstarred | slice: 0:5" [value]="previousSearch2">
                    <div class="option-row">
                        <div class="option-text">
                            {{previousSearch2}}
                        </div>
                        <div class="option-actions">
                            <mat-icon *ngIf="isStarred(previousSearch2)" (click)="$event.stopPropagation(); unstar(previousSearch2);">star</mat-icon>
                            <mat-icon *ngIf="!isStarred(previousSearch2)" (click)="$event.stopPropagation(); star(previousSearch2);">star_border</mat-icon>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
            <div class="input-action">
                <mat-icon *ngIf="isStarred(mySearch.trim())" (click)="unstar(mySearch.trim())">star</mat-icon>
                <mat-icon *ngIf="!isStarred(mySearch.trim())" (click)="star(mySearch.trim())">star_border</mat-icon>
            </div>
            <button mat-button (click)="search()">Search</button>
        </div>
        <div class="recommendations-output" *ngIf="newsfeedDataFeed">
            <app-news-feed [newsfeedData]="newsfeedDataFeed"></app-news-feed>
        </div>
    </div>
    <div class="recommendations-footer"></div>
</div>
