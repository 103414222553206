<div class="homepage-main">
    <div class="homepage-insights">
        <div class="homepage-insights-title">
            <div class="homepage-insights-title-info">
                <div class="title">Sentiment Analysis</div>
                <div class="subtitle">Companies from your portfolio with the lowest sentiment scores</div>
            </div>
            <div class="homepage-insights-title-actions">
                <div class="first">
                    <mat-form-field>
                        <mat-label>Portfolio</mat-label>
                        <mat-select [(ngModel)]="selectedPortfolio.value" (selectionChange)="onSelectionChange(selectedPortfolio.value)">
                            <mat-select-trigger>
                                <div class="selected">
                                    <div class="action">
                                        <mat-icon *ngIf="selectedPortfolio.value !== defaultPortfolio" (click)="$event.stopPropagation(); setDefaultPortfolio(selectedPortfolio.value);" matTooltip="Set default portfolio">star_border</mat-icon>
                                        <mat-icon *ngIf="selectedPortfolio.value === defaultPortfolio"(click)="$event.stopPropagation(); resetDefaultPortfolio();" matTooltip="Remove default portfolio">star</mat-icon>
                                    </div>
                                    <div class="text">{{selectedPortfolio.name}}</div>
                                </div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let portfolio of portfolios" [value]="portfolio.uid">
                                {{'(' + (portfolio.sentiment.sentiment|percent) + ') ' + portfolio.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--<div class="second">Set as default</div>-->
            </div>
        </div>
        <div class="homepage-insights-cont">
            <div class="homepage-insights-info" *ngIf="auth.folio.contentTop && auth.folio.sentimentTop">
                <app-homepage-insights></app-homepage-insights>
            </div>
            <!--<div class="homepage-insights-space"></div>-->
            <div class="homepage-insights-chart">
                <app-news-participants></app-news-participants>
            </div>
        </div>
    </div>
    <div class="homepage-newsfeed">
        <!--<div class="homepage-newsfeed-title">
            <div class="homepage-newsfeed-title-info">
                <div class="title">Relevant News Feed</div>
            </div>
        </div>-->
        <div class="homepage-newsfeed-cont" *ngIf="auth.folio.contentTop">
            <app-news-newsfeed></app-news-newsfeed>
        </div>
    </div>
</div>
