<div class="portfolio-share-main">
    <div class="portfolio-share-header">
        <div>Share Portfolio</div>
        <mat-icon matTooltip="Close" matTooltipPosition="below" (click)="close()">close</mat-icon>
    </div>
    <div class="portfolio-share-container">
        <div class="portfolio-share-contents">
            <div class="content-header">
                <div class="content-title">{{currentPortfolio.name}}</div>
                <mat-slide-toggle *ngIf="isMine && users && (users.length > 0)" [checked]="isActive" [matTooltip]="activationTooltip()" (change)="setActivation($event.checked)"></mat-slide-toggle>
                <!--<mat-checkbox [checked]="isActive" [disabled]="!isMine" [matTooltip]="activationTooltip()" (change)="setActivation($event.checked)"></mat-checkbox>-->
            </div>
            <ng-container *ngIf="!currentPortfolio.is_aggregated">
                <p>Select access mode</p>
                <mat-radio-group [(ngModel)]="accessMode" (change)="onAccessModeChange($event)">
                    <mat-radio-button value="READ">Read</mat-radio-button>
                    <mat-radio-button value="WRITE">Write</mat-radio-button>
                    <mat-radio-button value="ADMIN">Admin</mat-radio-button>
                </mat-radio-group>
            </ng-container>
            <p>Enter e-mail addresses of the YUKKA Lab Users</p>
            <mat-form-field appearance="outline">
                <!--<mat-label>Users emails</mat-label>-->
                <mat-chip-list #chipList aria-label="Users selection">
                    <mat-chip *ngFor="let user of users" [selectable]="selectable" [removable]="removable" (removed)="remove(user)">
                        {{user}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </mat-form-field>
        </div>
    </div>
    <div class="portfolio-share-footer">
        <button class="share" mat-button (click)="share()">
            <mat-icon>folder_shared</mat-icon>
            <span>SHARE</span>
        </button>
        <button class="delete" *ngIf="isMine && users && (users.length > 0)" mat-button (click)="delete()" matTooltip="Stop sharing this portfolio (all information including users list will be removed)">
            <mat-icon>delete</mat-icon>
            <span>UNSHARE</span>
        </button>
    </div>
</div>
