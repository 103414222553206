<div class="contnews">

  <ng-template #loading>
    <div class="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>

  <div class="newscontainer" *ngIf="dataSource else loading">

    <table mat-table [dataSource]="dataSource" class="example-table">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef>Project</th>
        <td mat-cell *matCellDef="let row">{{row.project}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let row">{{row.time|date:'medium'}}</td>
      </ng-container>

      <ng-container matColumnDef="report">
        <th mat-header-cell *matHeaderCellDef>Report</th>
        <td mat-cell *matCellDef="let row">{{row.report}}</td>
      </ng-container>

      <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef>Subject</th>
        <td mat-cell *matCellDef="let row">{{row.matching}}</td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef>Language</th>
        <td mat-cell *matCellDef="let row">{{row.language}}</td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>Label</th>
        <td mat-cell *matCellDef="let row">{{row.label}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="inReport(row)">

    </table>

  </div>
  <div class="footer">
    <div class="cont">
      <mat-spinner [diameter]="40" *ngIf="deload"></mat-spinner>
      <button mat-raised-button (click)="iDelete()" *ngIf="!deload">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
      <button mat-raised-button [matMenuTriggerFor]="menuExport">
        <mat-icon>save_alt</mat-icon>
        <span>Export</span>
      </button>
      <mat-menu #menuExport="matMenu" class="menureport">
        <button mat-menu-item (click)="inExport()">Export JSON preview</button>
        <button mat-menu-item (click)="inFileExport()">Export XLSX file</button>
      </mat-menu>
      <mat-spinner [diameter]="40" *ngIf="labelLoading"></mat-spinner>
      <button mat-raised-button (click)="labelAllDone()" *ngIf="!labelLoading">
        <mat-icon>done_all</mat-icon>
        <span>Done</span>
      </button>
    </div>
    <div class="cont">
      <mat-paginator [length]="totalcount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="goPage($event)">
      </mat-paginator>
    </div>
  </div>
</div>
