import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from '../../../service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { NewsletterService } from 'src/app/service/newsletter.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-newsfeed',
  templateUrl: './news-newsfeed.component.html',
  styleUrls: ['./news-newsfeed.component.scss']
})
export class MainNewsfeedComponent {

  newsfeedDataCount: any;
  newsfeedDataFeed: any;
  params: any;
  previousValue: any;
  newstype: string;
  project: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public auth: AuthService,
    public routing: RoutingService,
    public config: ConfigService,
    public newsletterService: NewsletterService,
  ) {

    this.project = this.config.appConfig.routing.project;

    this.route.queryParams.subscribe(params => {

      if (this.routing.isNewsletter() && this.auth.newsletterParams) {
        this.params = this.auth.newsletterParams;
      } else {
        this.params = params;
      }

      if (!(params.eventsFromScanner && !params.eventid) && !((params.newstype && (params.newstype === 'Events')) && this.routing.isScanner() && (!params.eventid || !params.tag)) && (params.id || params.isin || this.routing.isHot()) && this.routing.reFresh(params, this.previousValue, ['update', 'updatePayload', 'updateNewsfeed', 'type', 'id', 'isin', 'time', 'period', 'lang', 'feed', 'categories', 'continents', 'countries', 'factuality', 'temporality', 'ranks', 'tag', 'participant', 'newsday', 'newstype', 'eventid', 'event_ids', 'filter', 'noise', 'license', 'duplicate', 'title', 'fadeout', 'dimmed', 'newsaudit', 'newsfeedFromQuery', 'factuality', 'temporality', 'sentence_with_entity_threshold', 'entity_span_threshold', 'elasticsearch_score_decay', 'entity_boost'])) {

        if (this.auth.featureFlags.showTopNews) {
          this.params.newstype ? (this.newstype = this.params.newstype) : ((this.routing.isFolio() || this.routing.isMarket()) ? this.newstype = 'Relevant' : this.newstype = 'Chronological');
        } else {
          this.params.newstype ? this.newstype = this.params.newstype : this.newstype = 'Chronological';
        }

        if (((this.routing.isNewsletter() && this.auth.newsletterParams && this.auth.newsletterParams.hasOwnProperty('type')) || !this.routing.isNewsletter()) && !(this.params.groupId && this.params.id && (this.params.groupId === 'default') && (this.params.id === 'default'))) {

          if (this.newstype === 'Stories') {

            this.yukkApi.newsfeed({
              returnStories: true,
              params: this.params,
              custom: {
                size: (this.params.size) ? this.params.size : 1000
              }
            }).subscribe(result => {
              if (this.newstype === 'Stories') {
                if (result.hasOwnProperty('stories')) {
                  this.newsfeedDataFeed = result;
                  this.newsfeedDataCount = result;
                } else {
                  this.newsfeedDataFeed = { stories: result };
                  this.newsfeedDataCount = { stories: result };
                }
                this.newsfeedDataFeed.reqError = false;
                this.newsfeedDataCount.reqError = false;
                if (this.params.tabsEventsFromScanner) {
                  this.newsfeedDataFeed.tabsEventsFromScanner = true;
                }
                this.newsfeedDataFeed = Object.assign({}, this.newsfeedDataFeed);
                this.newsfeedDataCount = Object.assign({}, this.newsfeedDataCount);
                this.router.navigate([], {
                  queryParams: { tabsEventsFromScanner: null },
                  queryParamsHandling: 'merge',
                  replaceUrl: false
                });
              }
            }, error => {

              if (this.newstype === 'Stories') {
                this.newsfeedDataFeed = {};
                this.newsfeedDataCount = {};
                this.newsfeedDataFeed.reqError = true;
                this.newsfeedDataCount.reqError = true;
              }

            });

          } else {

            if (params.filter) {

              this.yukkApi.newsfeed({
                params: this.params,
                returnRecommendations: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && (this.newsletterService.newsletter['contents'].includes('recommended_news'))),
                custom: {
                  next: null,
                  size: 1,
                  starred_only: this.newstype === 'Bookmarked',
                  audited_only: this.newstype === 'Flagged',
                  dismissed_only: this.newstype === 'Dismissed',
                  exclude_dismissed: this.routing.isNewsletter(),
                  newsletter_id: (this.routing.isNewsletter() && params && params.id) ? params.id : null,
                  top_news_score_threshold: false,
                  license: false,
                  title: false,
                  phrase: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_phrases'] : null,
                  similarity_threshold: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_threshold'] : null
                }
              }).subscribe(result => {
                this.newsfeedDataCount = result;
                this.newsfeedDataCount.reqError = false;

                this.newsfeedDataCount = Object.assign({}, this.newsfeedDataCount);

              }, error => {
                this.newsfeedDataCount.reqError = true;
              });

              this.yukkApi.newsfeed({
                returnEvents: (this.newstype === 'Events'),
                returnRelevantNews: (this.newstype === 'Relevant'),
                params: this.params,
                returnRecommendations: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && (this.newsletterService.newsletter['contents'].includes('recommended_news'))),
                custom: {
                  next: null,
                  size: (this.newstype !== 'Events') ? 50 : 1000,
                  starred_only: this.newstype === 'Bookmarked',
                  audited_only: this.newstype === 'Flagged',
                  dismissed_only: this.newstype === 'Dismissed',
                  exclude_dismissed: this.routing.isNewsletter(),
                  newsletter_id: (this.routing.isNewsletter() && params && params.id) ? params.id : null,
                  top_news_score_threshold: false,
                  license: false,
                  title: false,
                  phrase: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_phrases'] : null,
                  similarity_threshold: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_threshold'] : null
                }
              }).subscribe(result => {
                this.newsfeedDataFeed = result;
                this.newsfeedDataFeed.reqError = false;

                this.newsfeedDataFeed = Object.assign({}, this.newsfeedDataFeed);

              }, error => {
                this.newsfeedDataFeed = {};
                this.newsfeedDataFeed.reqError = true;
              });

            } else {

              this.yukkApi.newsfeed({
                returnEvents: (this.newstype === 'Events'),
                returnRelevantNews: (this.newstype === 'Relevant'),
                params: this.params,
                returnRecommendations: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))),
                custom: {
                  next: null,
                  size: (this.newstype !== 'Events') ? 50 : 1000,
                  starred_only: this.newstype === 'Bookmarked',
                  audited_only: this.newstype === 'Flagged',
                  dismissed_only: this.newstype === 'Dismissed',
                  exclude_dismissed: this.routing.isNewsletter(),
                  newsletter_id: (this.routing.isNewsletter() && params && params.id) ? params.id : null,
                  top_news_score_threshold: false,
                  license: false,
                  title: false,
                  phrase: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_phrases'] : null,
                  similarity_threshold: (this.routing.isNewsletter() && this.newsletterService && this.newsletterService.newsletter && this.newsletterService.newsletter['contents'] && (this.newsletterService.newsletter['contents'].includes('recommended_news'))) ? this.newsletterService.newsletter['recommended_news_threshold'] : null
                }
              }).subscribe(result => {
                this.newsfeedDataFeed = result;
                this.newsfeedDataCount = result;
                this.newsfeedDataFeed.reqError = false;
                this.newsfeedDataCount.reqError = false;

                this.newsfeedDataFeed = Object.assign({}, this.newsfeedDataFeed);
                this.newsfeedDataCount = Object.assign({}, this.newsfeedDataCount);

              }, error => {
                this.newsfeedDataFeed = {};
                this.newsfeedDataCount = {};
                this.newsfeedDataFeed.reqError = true;
                this.newsfeedDataCount.reqError = true;
              });

            }

          }

        } else if (this.params.groupId && this.params.id && (this.params.groupId === 'default') && (this.params.id === 'default')) {
          this.newsfeedDataFeed = {};
          this.newsfeedDataCount = {};
        }

      }

      this.previousValue = params;

    });

  }

}


