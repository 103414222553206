<div class="stats-main">

    <div class="stats-menu">

        <mat-form-field>
            <mat-label>Select country</mat-label>
            <input matInput type="text" [formControl]="myControlCountries" [matAutocomplete]="autoCountry">
            <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let country of filteredCountries | async | orderBy" [value]="country" (click)="selectCountry(country)">
                    {{country.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Select events</mat-label>
            <mat-select [(ngModel)]="selectedEvents" multiple (openedChange)="selectEvents($event)">
                <ng-container *ngFor="let superevent of events | orderBy:'entity.name'">
                    <mat-optgroup class="middleevent" *ngFor="let event of superevent.children | orderBy:'entity.name'" [label]="superevent.entity.name + ' - ' + event.entity.name">
                        <mat-option class="subevent" *ngFor="let subevent of event.children | orderBy:'entity.name'" [value]="subevent.entity" (click)="$event.stopPropagation()">
                            {{subevent.entity.name}}
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
        </mat-form-field>

    </div>

    <div class="stats-cont">

        <ng-template #loading>
            <div class="loading">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </ng-template>

        <table *ngIf="dataSource else loading" mat-table matSort matSortDisableClear [dataSource]="dataSource" class="example-table" (matSortChange)="sortData($event)">

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="industry">
                <th mat-header-cell mat-sort-header="industry" *matHeaderCellDef>Industry</th>
                <td mat-cell *matCellDef="let row">{{row.industry}}</td>
            </ng-container>

            <ng-container matColumnDef="ceo">
                <th mat-header-cell mat-sort-header="ceo" *matHeaderCellDef>CEO</th>
                <td mat-cell *matCellDef="let row">{{row.ceo}}</td>
            </ng-container>

            <ng-container matColumnDef="sentiment">
                <th mat-header-cell mat-sort-header="sentiment" start="desc" *matHeaderCellDef>Sent</th>
                <td mat-cell *matCellDef="let row">{{row.sentiment|percent}}</td>
            </ng-container>

            <ng-container matColumnDef="sentiment_delta">
                <th mat-header-cell mat-sort-header="sentiment_delta" start="desc" *matHeaderCellDef>ΔSent</th>
                <td mat-cell *matCellDef="let row">{{row.sentiment_delta*100|round|trend}}{{row.sentiment_delta*100|round|negative}}</td>
            </ng-container>

            <ng-container matColumnDef="volume">
                <th mat-header-cell mat-sort-header="volume" start="desc" *matHeaderCellDef>Vol</th>
                <td mat-cell *matCellDef="let row">{{row.volume}}</td>
            </ng-container>

            <ng-container matColumnDef="volume_ratio">
                <th mat-header-cell mat-sort-header="volume_ratio" start="desc" *matHeaderCellDef>ΔVol</th>
                <td mat-cell *matCellDef="let row">{{'&#215;'}}{{row.volume_ratio|round:2}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">

        </table>

    </div>

    <div class="stats-footer">

        <div class="cont">
            <button mat-raised-button [matMenuTriggerFor]="menuExport">
                <mat-icon>save_alt</mat-icon>
                <span>Export</span>
            </button>
            <mat-menu #menuExport="matMenu" class="menureport">
                <button mat-menu-item (click)="inFileExport()">Export XLSX file</button>
            </mat-menu>
        </div>

    </div>

</div>
