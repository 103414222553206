import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-reportbox',
  templateUrl: './news-reportbox.component.html',
  styleUrls: ['./news-reportbox.component.scss']
})
export class NewsReportboxComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
