<nav class="news-count-nav" mat-tab-nav-bar *ngIf="!routing.isNewsletter()">
  <a mat-tab-link *ngFor="let link of navLinks" [active]="newstype==link" (click)="changeNews(link)" [matTooltip]="getTooltip(link)">
    <div>{{renameTab(link)}}</div>
    <span class="info-label" *ngIf="link === 'Relevant'">BETA</span>
  </a>
  <div class="moreinfo" *ngIf="routing.isScanner()" (click)="moreinfo=!moreinfo">
    <mat-icon *ngIf="!moreinfo">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="moreinfo">arrow_drop_up</mat-icon>
  </div>
</nav>

<div class="news-count" *ngIf="!routing.isMobile() && !loading && params && (params.id !== 'default')">

  <!-- <div *ngIf="routing.isMobile() || routing.isIframe()">
    <app-news-title *ngIf="titolo$|async as titolo" [titolo]="titolo"></app-news-title>
  </div> -->

  <!--<nav mat-tab-nav-bar *ngIf="!routing.isNewsletter()">
    <a mat-tab-link *ngFor="let link of navLinks" [active]="newstype==link" (click)="changeNews(link)" [matTooltip]="getTooltip(link)">{{renameTab(link)}}</a>
  </nav>-->

  <div [class.hide]="params.nocount=='true'">
    <div class="feed-note" *ngIf="params.newsday">
      <div class="menu">
        <div class="tito" (click)="onote=!onote">
          <span *ngIf="notes.length">
            <mat-icon *ngIf="onote==true">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="onote==false">arrow_drop_down</mat-icon>
          </span>
          <span *ngIf="params.newsday">
            <span>{{params.newsday | date}}</span>
          </span>
          <span>
            <span>&nbsp;&#8226;&nbsp;</span>
            <span>{{notes.length}} Annotations</span>
          </span>
        </div>
        <div class="icon" *ngIf="params.newsday">
          <mat-icon (click)="newNote()" matTooltip="Annotation" *ngIf="params.id">add</mat-icon>
          <mat-icon (click)="outNote()" matTooltip="Exit">close</mat-icon>
        </div>
      </div>
      <div class="notes" *ngIf="onote">
        <div class="note" *ngFor="let note of notes; index as index" [class.active]="params.note==note.uid"
          (click)="linkNote(note)">
          <div class="cont">
            <div class="tito">{{note.annotation}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="count-message" *ngIf="params.time === '2'">
      <span>News counts for the past 24h are approximate</span>
    </div>
    <div class="main-count" *ngIf="datacount || (params.newstype === 'Stories') || (newstype === 'Stories') || (params.newstype === 'Events') || (newstype === 'Events')">
      <div *ngIf="showArticleSentiment && !(params.newstype === 'Stories') && !(newstype === 'Stories') && !(params.newstype === 'Events') && !(newstype === 'Events') && !routing.isScanner()" class="chartpie">
        <ngx-charts-pie-chart [view]="[80, 80]" [scheme]="ngxpie.color" [results]="ngxpie.data" [doughnut]="true"
          [arcWidth]=".3" (select)="inFilter($event.name)">
        </ngx-charts-pie-chart>
      </div>
      <div *ngIf="showArticleSentiment && !(params.newstype === 'Stories') && !(newstype === 'Stories') && !(params.newstype === 'Events') && !(newstype === 'Events') && !routing.isScanner()" class="legend">
        <div class="label" (click)="inFilter('pos')" [class.active]="isActive('pos')">
          <div class="bord pos"></div>
          <div class="tito">
            <div>Positive</div>
            <div>{{datacount.positiv | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('neu')" [class.active]="isActive('neu')">
          <div class="bord neu"></div>
          <div class="tito">
            <div>Neutral</div>
            <div>{{datacount.neutral | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('neg')" [class.active]="isActive('neg')">
          <div class="bord neg"></div>
          <div class="tito">
            <div>Negative</div>
            <div>{{datacount.negativ | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('all')" [class.active]="isActive('all')">
          <div class="bord all"></div>
          <div class="tito">
            <div>Total</div>
            <div>{{datacount.total | thousandSuff}}</div>
          </div>
        </div>
      </div>
      <div class="moreinfo" *ngIf="!routing.isScanner()" (click)="moreinfo=!moreinfo">
        <mat-icon *ngIf="!moreinfo">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="moreinfo">arrow_drop_up</mat-icon>
      </div>
    </div>
    <div class="main-count" *ngIf="!(datacount || (params.newstype === 'Stories') || (newstype === 'Stories') || (params.newstype === 'Events') || (newstype === 'Events'))">
      <div class="moreinfo" *ngIf="!routing.isScanner()" (click)="moreinfo=!moreinfo">
        <mat-icon *ngIf="!moreinfo">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="moreinfo">arrow_drop_up</mat-icon>
      </div>
    </div>
    <div class="more-info" *ngIf="moreinfo">
      <div class="info-blok">
        <mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isLicensedContent(licensedContent)" [(ngModel)]="licensedContent">Licensed / Full Text Articles</mat-slide-toggle>
        <!--<mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isTitleEntity()" [(ngModel)]="titleEntity">Entity in Title</mat-slide-toggle>-->
        <mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isDupli()" [(ngModel)]="duplicate">Exclude Duplicate News</mat-slide-toggle>
        <mat-slide-toggle *ngIf="routing.isDev()" (change)="isNoise(noise)" [(ngModel)]="noise">Sentiment News</mat-slide-toggle>
        <mat-slide-toggle *ngIf="(params.newstype !== 'Flagged') && (params.newstype !== 'Dismissed')" (change)="fadeoutSeen()" [(ngModel)]="fadeout">Dim Previously Read Articles</mat-slide-toggle>
        <mat-slide-toggle *ngIf="(params.newstype === 'Flagged')" (change)="dimResolved()" [(ngModel)]="dimmed">Dim resolved cases</mat-slide-toggle>
        <mat-slide-toggle (change)="changeNoSentiments()" [(ngModel)]="showArticleSentiment">Show Article Sentiment</mat-slide-toggle>
      </div>

      <div class="info-blok" *ngIf="routing.isDev() && (newstype === 'Relevant')">
        <span class="relevancy-header">Relevancy <mat-icon matTooltip="Reset to default values" (click)="resetRelevancyFilters()">settings_backup_restore</mat-icon></span>

        <div class="relevancy-filter">
          <div class="relevancy-filter-description">
            <span>Entity related: Sentence with entity threshold (default: 0.35)</span>
            <mat-icon matTooltip="Proportion of sentences containing key entities compared to the total number of sentences in the article">info</mat-icon>
          </div>
          <div class="relevancy-filter-slider">

            <mat-slider [(ngModel)]="relevancy.sentence_with_entity_threshold" (input)="relevancy.sentence_with_entity_threshold = $event.value; applyRelevancyFilter($event, 'sentence_with_entity_threshold');" min="0" max="1" step="0.01" [displayWith]="sliderLabel"></mat-slider>

            <div class="relevancy-labels-container">
              <div class="relevancy-label-min">
                <div class="relevancy-label-top">0</div>
                <div class="relevancy-label-bottom">Less Relevant</div>
              </div>
              <div class="relevancy-label-max">
                <div class="relevancy-label-top">1</div>
                <div class="relevancy-label-bottom">More Relevant</div>
              </div>
            </div>

          </div>
        </div>

        <div class="relevancy-filter">
          <div class="relevancy-filter-description">
            <span>Entity related: Entity span threshold (default: 0.9)</span>
            <mat-icon matTooltip="Find articles where key entities are mentioned within the first 10% of the article">info</mat-icon>
          </div>
          <div class="relevancy-filter-slider">

            <mat-slider [(ngModel)]="relevancy.entity_span_threshold" (input)="relevancy.entity_span_threshold = $event.value; applyRelevancyFilter($event, 'entity_span_threshold');" min="0" max="1" step="0.01" [displayWith]="sliderLabel"></mat-slider>

            <div class="relevancy-labels-container">
              <div class="relevancy-label-min">
                <div class="relevancy-label-top">0</div>
                <div class="relevancy-label-bottom">Less Relevant</div>
              </div>
              <div class="relevancy-label-max">
                <div class="relevancy-label-top">1</div>
                <div class="relevancy-label-bottom">More Relevant</div>
              </div>
            </div>

          </div>
        </div>

        <div class="relevancy-filter">
          <div class="relevancy-filter-description">
            <span>Portfolio related: Elastic search score decay (default: 0.5)</span>
            <mat-icon matTooltip="The higher the number, the more low volume companies are highlighted">info</mat-icon>
          </div>
          <div class="relevancy-filter-slider">

            <mat-slider [(ngModel)]="relevancy.elasticsearch_score_decay" (input)="relevancy.elasticsearch_score_decay = $event.value; applyRelevancyFilter($event, 'elasticsearch_score_decay');" min="0" max="1" step="0.01" [displayWith]="sliderLabel"></mat-slider>

            <div class="relevancy-labels-container">
              <div class="relevancy-label-min">
                <div class="relevancy-label-top">0</div>
                <div class="relevancy-label-bottom">High Volume</div>
              </div>
              <div class="relevancy-label-max">
                <div class="relevancy-label-top">1</div>
                <div class="relevancy-label-bottom">Low Volume</div>
              </div>
            </div>

          </div>
        </div>

        <div class="relevancy-filter">
          <div class="relevancy-filter-description">
            <span>Portfolio related: Entity boost (default: 100)</span>
            <mat-icon matTooltip="The lower the number, the more it boosts low volume companies">info</mat-icon>
          </div>
          <div class="relevancy-filter-slider">

            <mat-slider [(ngModel)]="relevancy.entity_boost" (input)="relevancy.entity_boost = $event.value; applyRelevancyFilter($event, 'entity_boost');" min="0" max="1000" step="1" [displayWith]="sliderLabel"></mat-slider>

            <div class="relevancy-labels-container">
              <div class="relevancy-label-min">
                <div class="relevancy-label-top">0</div>
                <div class="relevancy-label-bottom">Disable</div>
              </div>
              <div class="relevancy-label-max">
                <div class="relevancy-label-top">1000</div>
                <div class="relevancy-label-bottom">Low Volume</div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="line-row"></div>
      <div class="line-row"></div>
    </div>
  </div>
</div>
