import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe defines the color of items sorted by volume ratio
 */
@Pipe({ name: 'colorRatio' })
export class ColorRatioPipe implements PipeTransform {
  constructor(
    public routing: RoutingService,
  ) { }
  transform(value: any, min: any, max: any): string {
    if (value || (value === 0)) {
      if (min > 1) { min = 1; }
      if (max < 1) { max = 1; }
      let colors = [];
      if (this.routing.theme === 'light') {
        colors = [(min !== 1) ? '#f26e6e' : '#f2f2f2', '#f2f2f2', (max !== 1) ? '#60e9d0' : '#f2f2f2'];
      } else {
        colors = [(min !== 1) ? '#f26e6e' : '#f2f2f2', '#f2f2f2', (max !== 1) ? '#60e9d0' : '#f2f2f2'];
      }
      const colorScale = d3.scaleLinear()
        .domain([min, 1, max])
        .range(colors);
      return colorScale(value.toFixed(2));
    } else {
      return 'rgb(120,120,120)';
    }
  }
}
