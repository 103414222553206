<div class="portfolio-main" [class.widget]="routing.isWidget()">
  <!--<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let folio of auth.folios|orderBy:'name'" [active]="folio.uid==qparams.id" (click)="inTab(folio.uid)">
      {{folio.name}}
    </a>
  </nav>-->
  <div class="contipo">
    <app-portfolio-view *ngIf="!empty"></app-portfolio-view>
    <ng-container *ngIf="empty">
      <div class="firstone">
        <button mat-stroked-button (click)="newFolio()">Create</button>
        <span class="orthat">OR</span>
        <button mat-stroked-button [matMenuTriggerFor]="menuImportPortfolio">Import</button>
        <mat-menu #menuImportPortfolio="matMenu">
          <button mat-menu-item (click)="loadSamplePortfolio()">Pre-load Example Portfolio</button>
          <button mat-menu-item (click)="iImport(null, 'isinsCreate')">ISINs list</button>
          <button mat-menu-item *ngIf="auth.featureFlags.importPortfolios" (click)="fileInputPortfolio.click()">Portfolios (from files)</button>
          <input #fileInputPortfolio class="inptfile" type="file" accept=".json" value="" (click)="fileInputPortfolio.value = null" (change)="iImport($event, 'portfolio')" multiple/>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</div>
