import { PipeTransform, Pipe } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';

/**
 * This pipe create the spans for highlight targets, tags, events etc...
 */

@Pipe({ name: 'highlight', pure: false })
export class HighlightPipe implements PipeTransform {

  // constructor(private sanitized: DomSanitizer) { }
  constructor() { }

  transform(text: string, location: string, tag: any) {
    if (text && location && tag) {
      let newtext = '';
      let currentIdx = 0;
      const spans = [];
      const location_spans = tag.spans.filter(s => s.location === location).sort((a, b) => a.start - b.start);
      if (location_spans.length > 0) {
        spans.push(location_spans[0]);
        for (let i = 1; i < location_spans.length; i++) {
          const prev_span = location_spans[i - 1];
          const current_span = location_spans[i];
          if (current_span.start >= prev_span.end) {
            spans.push(current_span);
          }
        }
      }
      spans.forEach((span, index) => {
        if ((span.start !== span.end) && (span.start >= currentIdx)) {
          newtext += text.substring(currentIdx, span.start);
          currentIdx = span.start;
          newtext += '<span class="highlight ' + span.sentiment + '" id="target-' + index + '-' + location + '">';
          newtext += text.substring(currentIdx, span.end);
          newtext += '</span>';
          currentIdx = span.end;
        }
      });
      newtext += text.substring(currentIdx, text.length);
      return newtext;
    } else {
      return text;
    }
  }

}
