<div class="stepwelcome">
  <div mat-dialog-content [ngSwitch]="project">

    <div *ngSwitchCase="'yukka'">
      <div class="tito">Introduction to News & Trend Lab</div>
      <div class="desk">
        <p>YUKKA Lab connects News Analytics with Artificial Language Intelligence to anticipate trends in financial markets. YUKKALab ́s self-developed algorithms and high-end sentiment technology processes daily more than 200,000 professional financial news articles in German and English. The combination of machine and deep learning and natural language processing enables context-based analyses and guarantees valid sentiment indicator for companies, events and individuals.</p>
        <p>Our vision for our News & Trend Lab is to create a real-time currency for the sentiment (how positive or negative an entity is reported on in the media) of relevant entities. An entity can be a company, index, sector, industry, country but also a topic, person or brand (in this manual always summarized as entities).</p>
        <p>Either click through the list of multiple News & Trend Labs features or follow the guided tutorial by clicking Tutorial or leaving the tutorial by clicking the Close button.</p>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="tuto news" routerLink="/cockpit/news/market"
      [queryParams]="{ tutorial: 'menuaside'}" queryParamsHandling="replace" mat-dialog-close>
      <span>tutorial</span>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>
