<div class="entityask">

  <div mat-dialog-title>

    <mat-form-field appearance="standard">
      <mat-select [(value)]="headerTab" (valueChange)="onHeaderTabChange()">
        <mat-option value="single">Add Single Entity</mat-option>
        <mat-option value="upload">Upload Excel File</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" (change)="uploadFile($event)" [class.inblurFull]="headerTab==='single'">
      <mat-label>Upload Excel File</mat-label>
      <ngx-mat-file-input [accept]="'.xlsx'"></ngx-mat-file-input>
      <mat-icon matSuffix>cloud_upload</mat-icon>
    </mat-form-field>

  </div>

  <div mat-dialog-content class="single" *ngIf="headerTab==='single'">
    <mat-form-field appearance="outline">
      <mat-label>Legal Name</mat-label>
      <input matInput type="text" [(ngModel)]="data.legalName" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Sector</mat-label>
      <input matInput type="text" [formControl]="myControlSectors" [matAutocomplete]="autoSector" required>
      <mat-autocomplete #autoSector="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let sector of filteredSectors | async | orderBy" [value]="sector">
          {{sector}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Website</mat-label>
      <input matInput type="url" [(ngModel)]="data.website" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="data.type" required>
        <mat-option *ngFor="let type of typesList | orderBy" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input matInput type="text" [(ngModel)]="data.city">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <input matInput type="text" [formControl]="myControlCountries" [matAutocomplete]="autoCountry" required>
      <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let country of filteredCountries | async | orderBy" [value]="country">
          {{country}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>ISIN</mat-label>
      <input matInput type="text" [(ngModel)]="data.isin">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>CEO Name</mat-label>
      <input matInput type="text" [(ngModel)]="data.ceo">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Surface Forms</mat-label>
      <mat-chip-list #chipList aria-label="Surface forms selection">
        <mat-chip *ngFor="let form of data.surfaceForms" [selectable]="selectable" [removable]="removable" (removed)="remove(form)">
          {{form}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput [(ngModel)]="data.message" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </div>

  <div mat-dialog-content class="upload" *ngIf="headerTab==='upload'">
    <mat-form-field appearance="outline">
      <mat-label>Legal Name</mat-label>
      <mat-select [(ngModel)]="dataFile.legalName">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Sector</mat-label>
      <mat-select [(ngModel)]="dataFile.sector">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Website</mat-label>
      <mat-select [(ngModel)]="dataFile.website">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="dataFile.type">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <mat-select [(ngModel)]="dataFile.city">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <mat-select [(ngModel)]="dataFile.country">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>ISIN</mat-label>
      <mat-select [(ngModel)]="dataFile.isin">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>CEO Name</mat-label>
      <mat-select [(ngModel)]="dataFile.ceo">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Surface Forms</mat-label>
      <mat-select [(ngModel)]="dataFile.surfaceForms">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <mat-select [(ngModel)]="dataFile.message">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of fileColumns; let i = index;" [value]="i">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div class="query-actions">
      <div class="add-query" *ngIf="headerTab==='single'">
        <mat-checkbox [(ngModel)]="createQuery">Add Automated Query</mat-checkbox>
      </div>
      <div class="add-query" *ngIf="headerTab==='upload'">
        <mat-checkbox [(ngModel)]="createQuery">Add Automated Queries</mat-checkbox>
      </div>
      <div class="select-collection">
        <mat-form-field appearance="standard">
          <mat-label>Collection</mat-label>
          <mat-select [(ngModel)]="selectedCollections" multiple [disabled]="!createQuery">
            <mat-option *ngFor="let collection of collectionsList" [value]="collection.uid" [disabled]="(collection.content.length + entitiesNumber) > auth.featureFlags.queriesPerCollectionsLimit">
              {{collection.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="submit-actions">
      <button mat-raised-button color="secondary" mat-dialog-close>CANCEL</button>
      <button mat-raised-button color="primary" class="entityask-submit" (click)="iSubmit()">SUBMIT</button>
    </div>
  </div>

</div>
