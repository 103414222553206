import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
  ) { }

  openPortfolioBoard() {
    this.router.navigate(['/cockpit/news/portfolio/main'], {
      replaceUrl: false
    });
  }

}
