<div class="admin-annotation">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="pointer" (click)="inDelete(element)">delete</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> time </th>
      <td mat-cell *matCellDef="let element"> {{element.position|date}} </td>
    </ng-container>

    <ng-container matColumnDef="public">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> public </th>
      <td mat-cell *matCellDef="let element"> {{element.is_global}} </td>
    </ng-container>

    <ng-container matColumnDef="entity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> entity </th>
      <td mat-cell *matCellDef="let element"> {{element.entity}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> title </th>
      <td mat-cell *matCellDef="let element"> {{element.annotation}} </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> comment </th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>