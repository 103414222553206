<div class="newsletter-send-container">

    <div class="newsletter-send-form">

        <mat-form-field appearance="outline">
            <mat-label>Custom summary</mat-label>
            <textarea matInput [(ngModel)]="summary" cdkTextareaAutosize></textarea>
        </mat-form-field>

    </div>

    <div class="newsletter-send-action">

        <div>
            <mat-checkbox [(ngModel)]="sendToAll" [hidden]="data.newsletter.recipients.length === 0">
                Send newsletter to the additional recipients.
            </mat-checkbox>
        </div>

        <div>
            <button mat-raised-button class="btnflex" (click)="onSubmit()" matTooltip="Send newsletter now">
                <span>{{'SEND'}}</span>
            </button>
        </div>

    </div>

</div>



