<div class="scanner">
    <div class="scanner-container">
        <ng-container *ngIf="auth.folio">
            <app-scanner-main></app-scanner-main>
        </ng-container>
        <div class="scanner-message" *ngIf="auth.folio === undefined">
            <p>CREATE A PORTFOLIO TO START SEEING YOUR INSIGHTS</p>
            <button mat-stroked-button (click)="openPortfolioBoard()">CREATE PORTFOLIO</button>
        </div>
        <div class="loading" *ngIf="auth.folio === null">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
    </div>
</div>
