<div class="chat-main">
  <app-chat-navigation [childMessageChatTitle]="parentMessageChatTitle" [childMessageChatId]="parentMessageChatId"></app-chat-navigation>

  <div class="chat__conversation">
    <div class="chat-container">
      <div class="top-fade" [class.theme-light]="params?.theme === 'light'"></div>

      <div class="chat-output">
        <div class="chat-output-inner" id="llmContainer">
          <div *ngIf="chatDetails && chatDetails.length > 0">
            <ng-container *ngFor="let chat of chatDetails; let i = index">
              <div *ngIf="chat.actor === 'human'" class="box__container"  >
                <div  class="box">
                  <div class="box__avatar">{{ userAvatar }}</div>
                </div>
                <div  class="chat-box" [innerHTML]="chat.message"></div>
              </div>

              <div *ngIf="chat.actor === 'ai'" class="box__container">
                <div class="box">
                  <div class="box__avatar box__avatar--response">{{ llmAvatar }}</div>
                </div>
                <div class="chat__container">
                  <div class="chat-box chat-response" *ngIf="chat.message?.joinedFacts || (!loading && !error)">
                    <div class="facts" [innerHTML]="chat.message?.joinedFacts"></div>
                    <div *ngIf="chat.message?.relevant_articles?.length > 0 || chat.message?.source_documents?.length > 0">
                      <h2 *ngIf="!chat.message.companyName || chat.message.companyName === 'all'" class="article">Articles</h2>
                      <h2 *ngIf="chat.message.companyName && chat.message.companyName !== 'all'" class="article">
                        Articles about
                        <a class="entity" (click)="navigateToCompany(chat.message.companyName, chat.message.companyType)">
                          {{ chat.message.entityName }}
                        </a>
                      </h2>
                
                      <div class="article__cards">
                        <div *ngFor="let article of(chat.message.relevant_articles.length > 0 ? chat.message.relevant_articles : chat.message.source_documents)" 
                             (click)="navigateToArticle(chat.message.companyName, chat.message.companyType, article.id, article.publish_time)" 
                             [ngClass]="{'article__card--event': chat.message.events, 'article__card': !chat.message.events}">
                          
                          <div *ngIf="article.eventNames?.length > 0" class="article__event">
                            <span>{{ article.eventNames[0] }}</span>
                          </div>
                
                          <div class="article__content">
                            <div class="article__title--container">
                              <div class="article__title">
                                {{ article.title | slice: 0: 70 }}{{ article.title.length > 70 ? '...' : '' }}
                              </div>
                            </div>
                            <div class="article__footer">
                              <div class="article__published">
                                <mat-icon class="icons">access_time</mat-icon>
                                <span class="article__published">{{ article.publish_time | date: 'mediumDate' }}</span>
                              </div>
                              <div class="article__provider">
                                <mat-icon class="icons">public</mat-icon>
                                <span>{{ article.provider | slice: 0: 15 }}{{ article.provider.length > 15 ? '...' : '' }}</span>
                              </div>
                            </div>
                          </div>
                
                        </div>
                      </div>
                    </div>
                
                  </div>
                  <div class="chat__feedbackbox" [ngClass]="{'always-visible': i === chatDetails.length - 1}">
                    <mat-icon
                      class="thumb-icon"
                      [ngClass]="{'active': chat.feedback === 1}"
                      (click)="chatFeedback(parentMessageChatId, chat.message_id, 'pos')"
                    >
                      thumb_up
                    </mat-icon>
                    <mat-icon
                      class="thumb-icon"
                      [ngClass]="{'active': chat.feedback === -1}"
                      (click)="chatFeedback(parentMessageChatId, chat.message_id, 'neg')"
                    >
                      thumb_down
                    </mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="loading  && !error"  class="box__container">
              <div class="box">
                <div class="box__avatar box__avatar--response">{{ llmAvatar }}</div>
              </div>
              <div class="chat-box chat-response">
                <div class="loader"></div>
              </div>
            </div>
            <div class="chat-box chat-response" *ngIf="i === chatDetails.length - 1 && error">
              <p>Something went wrong, please try again.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-input">
        <div class="bottom-fade" [class.theme-light]="params?.theme === 'light'"></div>
        <form class="input-box" (ngSubmit)="onSubmit($event)">
          <input
            matInput
            [(ngModel)]="chatInput"
            type="email"
            placeholder="Type a message..."
            name="chat_input"
          />
          <button mat-raised-button type="submit">
            <mat-icon>send</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>