import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';

/**
 * Component used for importing saved queries into collections similarly to portfolios imports
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-import',
  templateUrl: './query-import.component.html',
  styleUrls: ['./query-import.component.scss']
})
export class QueryImportComponent {

  queryList = [];
  filteredQueryList: Observable<any>;
  queryForm: UntypedFormControl;
  querySearchForm: UntypedFormControl;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private dialog: MatDialog,
      public snackBar: MatSnackBar,
      public auth: AuthService,
      public routing: RoutingService,
      @Inject(MAT_DIALOG_DATA) public data,
  ) {

    this.queryForm = new UntypedFormControl();
    this.querySearchForm = new UntypedFormControl();

    this.auth.getQuery().subscribe(result => {
      this.queryList = result.filter(item => {
        return !this.data.queries.some(item2 => (item2.uid === item.uid));
      }).map(element => {
        return {
          label: element.name,
          value: element.uid,
          selected: false
        };
      });

      this.filteredQueryList = this.querySearchForm.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(name => name ? this.filterMatches(name) : this.queryList.slice())
      );

    });

  }

  /**
   * flag selected items from multi-selection lists
   */
  handleSelection(element) {
    element.selected = !element.selected;
  }

  /**
   * update list of selected queries on search filter's item click
   */
  handleSearch(query) {
    const selectedQueries = this.queryForm && this.queryForm.value ? this.queryForm.value : [];
    if (!query.selected) {
      query.selected = true;
      selectedQueries.push(query.value);
      this.queryForm.setValue(selectedQueries);
    }
  }

  /**
   * displays name of the query related to query's form control value
   */
  displayQuery(query) {
    return query && query.label ? query.label : '';
  }

  /**
   * returns queries matching searched input text
   */
  filterMatches(text) {
    const filterValue = text.toLowerCase();
    return this.queryList.filter(item => item.label.toLowerCase().includes(filterValue));
  }

  /**
   * import selected queries into the current collection
   */
  iImport() {
    if (this.data.queries && this.queryForm.value && ((this.data.queries.length + this.queryForm.value.length) > this.auth.featureFlags.queriesPerCollectionsLimit)) {
      this.snackBar.open('Number of queries per collection is limited to ' + this.auth.featureFlags.queriesPerCollectionsLimit + '.', 'OK', { duration: 5000 });
    } else {
      const queriesIds = this.queryForm.value;
      this.auth.addQueriesToGroup(queriesIds, this.data.collection.uid).subscribe(result => {
        this.dialog.closeAll();
        this.router.navigate([], {
          queryParams: {
            update: Math.random()
          }, queryParamsHandling: 'merge'
        });
      });
    }
  }
}
