import { Pipe, PipeTransform } from '@angular/core';
/**
 * This pipe generate the icons for the trend
 */
@Pipe({ name: 'trend' })
export class TrendPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 0) { return '↑'; }
    if (value < 0) { return '↓'; }
    return '↑';
  }
}
