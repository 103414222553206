import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-trend-bar',
  templateUrl: './trend-bar.component.html',
  styleUrls: ['./trend-bar.component.scss']
})
export class TrendBarComponent implements OnChanges {

  @Input() oldValue: number;
  @Input() newValue: number;
  @Input() polarity: number;

  constructor(
  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (change.oldValue && change.oldValue.currentValue) {
      // console.log('Old value:');
      // console.log(change.oldValue.currentValue);
    }
    if (change.newValue && change.newValue.currentValue) {
      // console.log('New value:');
      // console.log(change.newValue.currentValue);
    }
  }

}
