<mat-form-field floatLabel="never" class="query-search" [class.mat-form-field-invalid]="error">
  <input matInput autocomplete="off" placeholder="Search" [formControl]="mySearch" [matAutocomplete]="menuQuery"
    (blur)="inBlur($event)">
  <mat-autocomplete #menuQuery="matAutocomplete" (optionSelected)="goSelect(mySearch.value)"
    [autoActiveFirstOption]="true" class="filterSearch">
    <div *ngFor="let items of items$|async|groupBy:'entity.type'|orderByType|pairs">
      <mat-optgroup [label]="items[0]|nicetxt">
        <mat-option *ngFor="let option of items[1]" [value]="option" [style.color]="option.sentiment|color">
          <div class="search-indexo">
            <div class="indexo-left">{{ option.entity.name }}</div>
            <div class="indexo-right">
              <span>{{option.sentiment | percent}}</span>
              <div class="trendicon">
                <span class="brak">(</span>
                <span>{{option.sentiment_delta*100|round|trend}}</span>
                <span>{{option.sentiment_delta*100|round|negative}}</span>
                <span class="brak">)</span>
              </div>
            </div>
          </div>
        </mat-option>
      </mat-optgroup>
    </div>
  </mat-autocomplete>
</mat-form-field>