import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { YukkApi } from 'src/app/service/yukkapi.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-query-alert',
  templateUrl: './query-alert.component.html',
  styleUrls: ['./query-alert.component.scss']
})
export class QueryAlertComponent {

  $queryList: Observable<any>;
  $queryAlertsList: Observable<any>;
  $notificationsList: Observable<any>;
  $queryCombinedList: Observable<any>;
  $articleContent: Observable<any>;
  queryCombinedList = [];
  articlesList = [];
  querySelected: any;
  articleSelected: any;
  params: any;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private yukkApi: YukkApi,
      private auth: AuthService
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.querySelected = undefined;
      this.$queryList = this.auth.getQuery();
      this.$queryAlertsList = this.auth.getQueryAlerts();
      this.$notificationsList = this.auth.getNotifications(99);
      this.$articleContent = (params.article) ? this.yukkApi.newsview({id: params.article, params: params}) : of({});

      this.$queryCombinedList = combineLatest([this.$queryList, this.$queryAlertsList, this.$notificationsList, this.$articleContent]).pipe(
          map(([queries, alerts, notifications, article]) => {
            return { queries, alerts, notifications, article };
          })
      );

      this.$queryCombinedList.subscribe(result => {

        this.queryCombinedList = [];
        this.queryCombinedList = result.queries.map(query => {

          const combinedQueryObject = {
            queryId: '',
            queryName: '',
            hasAlert: false,
            alertId: null,
            isActive: false,
            howMany: 0,
            notifications: [],
            selected: false
          };

          combinedQueryObject.queryId = query.uid;
          combinedQueryObject.queryName = query.name;

          result.alerts.forEach(alert => {
            if (alert.query_id === query.uid) {
              combinedQueryObject.hasAlert = true;
              combinedQueryObject.alertId = alert.uid;
              combinedQueryObject.isActive = alert.active;
            }
          });

          combinedQueryObject.notifications = result.notifications.filter(notification => {
            return ( (notification.payload.query_id === query.uid) && (notification.seen === false) );
          });

          combinedQueryObject.howMany = combinedQueryObject.notifications.length;

          if (params.id === query.uid) {
            combinedQueryObject.selected = true;
            this.articlesList = combinedQueryObject.notifications.map(element => {
              return {
                id: element.payload.news_id,
                title: element.payload.news_title,
                timestamp: element.timestamp
              };
            });
          }

          return combinedQueryObject;

        });

        if (params.article && (params.article === result.article.id)) {
          this.articleSelected = result.article;
        } else {
          this.articleSelected = {};
        }

      });

    });

  }

  onQuerySelect(paramsId, item) {
    if (paramsId === item.queryId) {
      this.router.navigate([], { queryParams: { id: null, alert: null, article: null }, queryParamsHandling: 'merge', replaceUrl: false });
      this.articlesList = [];
    } else {
      this.articlesList = item.notifications.map(element => {
        return {
          id: element.payload.news_id,
          title: element.payload.news_title,
          timestamp: element.timestamp
        };
      });
    }
  }

  onArticleSelect(paramsId, item) {
    this.articleSelected = {};
    if (paramsId === item.id) {
      this.router.navigate([], { queryParams: { alert: null, article: null }, queryParamsHandling: 'merge', replaceUrl: false });
    }
  }

  onMarkAllAsSeen() {
    this.auth.markNotifications().subscribe(result => {
      this.router.navigate([], { queryParams: { id: null, alert: 'seen', article: null }, queryParamsHandling: 'merge' });
    });
  }

  setAlert(item) {
    if (item.isActive) {
      this.auth.createQueryAlert(item.queryId).subscribe(result => {
        this.router.navigate([], { queryParams: { alert: 'created' }, queryParamsHandling: 'merge' });
      });
    } else {
      this.auth.deleteQueryAlert(item.alertId).subscribe(result => {
        this.router.navigate([], { queryParams: { alert: 'deleted' }, queryParamsHandling: 'merge' });
      });
    }
  }

}
