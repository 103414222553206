import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { first } from 'rxjs/operators';
import { marked } from 'marked';
import { RoutingService } from '../../../service/routing.service';
import { ActivatedRoute } from '@angular/router';


import { switchMap, map } from 'rxjs/operators';
import { iif, of } from 'rxjs';

import * as moment from 'moment';


@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
   
})
export class ChatComponent implements OnInit {

    headerText = 'YUKKA Lab News LLM';
    userAvatar = 'YOU';
    llmAvatar = 'YUKKA';
    chatInput: string; // question from user
    chatOutput = []; // answer from LLM stored in an array 
    chatHistory = []; // storing chatInput (called message) and answer (called history) in an array 
   
    loading = false;
    error = false;
    params: any;
    chatDetails: [{}];
    currentResponse: any;
   

    // properties for history sent in auth.llmChat
    chatIdQuestion = '';
    chatIdAnswer = '';
    chatIdHistory: [string, string][] = [];;
    chatTitle = '';


    // passing chatId and chatTitle to chatNavigation Component (child component)
    parentMessageChatTitle: string;
    parentMessageChatId: string;


    constructor(
      public auth: AuthService,
      public yukkApi: YukkApi,
      public snackBar: MatSnackBar,
      public routing: RoutingService,
      private route: ActivatedRoute, 

    ) {

      this.route.queryParams.subscribe(params => {
        this.params = params;
      });

    }
   

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        const chatId = params['chatId'];
        if (chatId) {
            this.loadConversation(chatId);
        }
    });
    }

    // loading conversation based on ChatId 
    loadConversation(chatId: string) {
      this.chatIdHistory = [];
      // get chat conversation based on chatId
      this.auth.getChatDetails(chatId).subscribe(chatDetails => {
        this.chatDetails = (chatDetails as any).messages.map(chat => {
          // determining which message belongs to ai or human 
          if (chat.actor === 'ai') {
            chat.message = this.parseJsonString(chat.message);
          if (chat.message.summary && chat.message.summary.text && chat.message.summary.text.length > 0) {
            this.chatIdAnswer = chat.message.summary.text;
          } else if (!chat.message.summary && chat.message.messages && chat.message.messages.length > 0) {
            this.chatIdAnswer = chat.message.messages[0];
          } else if (!chat.message.summary && (!chat.message.messages || chat.message.messages.length === 0) && chat.message.joinedFacts && chat.message.joinedFacts.length > 0) {
            this.chatIdAnswer = chat.message.joinedFacts;
          } else {
            console.log('No valid AI message found.');
          }
        }
          if (chat.actor === 'human') {
            this.chatIdQuestion = chat.message;
          }

          // Push the pair [question, answer] to chatIdHIstory array
          if (this.chatIdQuestion && this.chatIdAnswer) {
            this.chatIdHistory.push([this.chatIdQuestion, this.chatIdAnswer]);
            this.chatIdQuestion = '';
            this.chatIdAnswer = '';
            this.chatTitle = this.chatIdHistory[0][0];
            this.parentMessageChatTitle = this.chatTitle;
            this.parentMessageChatId = chatId;
          }
          return chat
        }); 
       
      }, error => {
        console.error('Error fetching chat details:', error);
      });
    }
  
    chatFeedback(chatId: string, messageId: string, feedback: string) {
     
      const chat = this.chatDetails.find((chat: any) => chat.message_id === messageId);
    
      if (!chat) {
        console.log(`Chat with message_id ${messageId} not found`);
        return;
      }

      if (chat) {
        const feedbackValue = feedback === 'pos' ? 1 : -1;
    
        // check if feedback already exists
        if (((chat as any).feedback ?? null) === feedbackValue) {
          this.auth.deleteChatFeedback(chatId, messageId).subscribe({
            next: (res) => {
              console.log('Feedback deleted', res);
              (chat as any).feedback = null;  // set back to null
            },
            error: (error) => {
              console.log('Failed to delete feedback', error);    
            }
          });
        } else {
          // Set feedback
          this.auth.postChatFeedback(chatId, messageId, feedback).subscribe({
            next: (res) => {
              console.log('Feedback sent', res);
              (chat as any).feedback = feedbackValue;
            },
            error: (error) => {
              console.log('Failed to send feedback', error);
              alert('Failed to send feedback, please try again')
            }
          });
        }
      } 
    }

    // post request into chatId conversation
    postChatConversation(chatId: string, actor: string, message: string): void {
      this.auth.postChatConversation(chatId, actor, message).subscribe({
        next: (response) => {
          this.loadConversation(chatId);
        },
        error: (err) => console.error('Error posting message:', err)
      });
    }

    onSubmit(event) {
        if (this.chatInput && (typeof this.chatInput === 'string') && this.chatInput.trim() && !this.loading) { 
          setTimeout(()=> {
            const target = document.getElementById('llmContainer');
            target.scrollTo(0, target.scrollHeight);
          }, 500);
          this.loading = true;
          const message = this.chatInput.trim(); //question
          const history = JSON.parse(JSON.stringify(this.chatIdHistory)); //answer
          const chatId = this.params.chatId; // chatId
          this.chatInput = ''; 
          if (!this.error) { 
            this.chatHistory.push([marked.parse(message), '']); 
            this.chatOutput.push({}); 
          } else {
            this.chatHistory[this.chatHistory.length-1][0] = message;
          }
          this.error = false;
          this.postChatConversation(chatId, 'human', message);
 

          // post message and history into v2/chat endpoint
          this.auth.chatLLM(message, history).pipe(
            first(),
            switchMap(res => {
              const copyRes = JSON.parse(JSON.stringify(res));
              const copyRes2 = JSON.parse(JSON.stringify(res));
              let companyName = '';
              let companyType = '';
              if (copyRes2.requested_entities && copyRes2.requested_entities.length > 0) {
                const firstEntity = copyRes2.requested_entities[0];
                const [type, name] = firstEntity.split('.');
                companyName = name;
                companyType = type;
              }
              this.chatHistory[this.chatHistory.length-1][1] = marked.parse(copyRes.history[copyRes.history.length-1][1]);
              const addedArticleIds = new Set();
              const relevantArticlesWithEvents = copyRes2.source_documents?.reduce((acc, article) => {
                const matchingEvents = copyRes2.events?.filter(event =>
                  event.document_ids.includes(article.id)
                ).map(event => event.name);
    
                if (matchingEvents && matchingEvents.length > 0 && !addedArticleIds.has(article.id)) {
                  acc.push({
                    ...article,
                    eventNames: matchingEvents
                  });
                  addedArticleIds.add(article.id);
                }
                return acc;
              }, []).sort((a: any, b: any) => {
                return new Date(b.publish_time).getTime() - new Date(a.publish_time).getTime();
              });
    
              if (relevantArticlesWithEvents?.length < 3) {
                for (let article of copyRes2.source_documents) {
                  if (!addedArticleIds.has(article.id)) {
                    relevantArticlesWithEvents.push(article);
                    addedArticleIds.add(article.id);
    
                    if (relevantArticlesWithEvents.length >= 3) break;
                  }
                }
              }
    
              const articlesToUse = relevantArticlesWithEvents?.length > 0 ? relevantArticlesWithEvents : [];
              return iif(
                () => copyRes2.requested_entities && copyRes2.requested_entities.length > 0,
                this.yukkApi.getEntitiesInfo(copyRes2.requested_entities ? [copyRes2.requested_entities[0]] : [], 'array').pipe(
                  map(entitiesRes => {
                    const entityName = entitiesRes[0].name;
                    return { entityName, copyRes, copyRes2, companyName, companyType, articlesToUse };
                  })
                ),
                of({ entityName: null, copyRes, copyRes2, companyName: null, companyType: null, articlesToUse: [] })
              );
            })
          ).subscribe(({ entityName, copyRes, copyRes2, companyName, companyType, articlesToUse, }) => {
            
            const aiMessage: any = marked.parse(copyRes.history[copyRes.history.length - 1][1]);
    
            if (!copyRes2.facts || copyRes2.facts.length === 0) {
              this.chatOutput[this.chatOutput.length - 1] = Object.assign({}, copyRes2, {
                joinedFacts: copyRes2.messages?.map(message2 => message2.trim())
                  .join(' ')
                  .replace(/\n\n/g, '<br><br>')
                  .replace(/\n/g, '<br>'),
                relevant_articles: articlesToUse,
                companyName,
                companyType,
                entityName
              });
            } else {
              this.chatOutput[this.chatOutput.length - 1] = Object.assign({}, copyRes2, {
                joinedFacts: copyRes2.facts?.map(fact => fact.fact.trim())
                  .join(' ')
                  .replace(/\n\n/g, '<br><br>')
                  .replace(/\n/g, '<br>'),
                relevant_articles: articlesToUse,
                companyName,
                companyType,
                entityName
              });
            }
    
            this.loading = false;
    
            // Post the AI response after it has been processed
            if (chatId) {
               const stringifiedAiMessage = JSON.stringify(this.chatOutput[this.chatOutput.length - 1])
              this.postChatConversation(chatId, 'ai', stringifiedAiMessage);
            }
          }, error => {
            this.error = true;
            this.loading = false;
            this.snackBar.open('Something went wrong. Please try again later.', 'OK', { duration: 10000 });
          });        
      }}

    navigateToArticle(companyName: string, companyType: string, articleId: any, date:any): void{
      const publishedDate = date.split('T')[0];
      const dateFrom = publishedDate;
      const dateTo = moment(dateFrom).add(1, 'days');
      const formattedDateStringTo = dateTo.format('YYYY-MM-DD');
      if (articleId) {
      const url = `/cockpit/news/market/chart?id=${companyName}&type=${companyType}&news=${articleId}&time=${dateFrom}--${formattedDateStringTo}`
      window.open(url, '_blank');
      } else {
        console.error('No articleId provided');
      }
    }

    navigateToCompany(companyName: string, companyType: string): void {
      const url = `/cockpit/news/market/chart?id=${companyName}&type=${companyType}`;
      window.open(url, '_blank');
    }


    parseJsonString(jsonString: string): any {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error('Error parsing JSON string:', e);
        return jsonString; // Return the original string if it's not a valid JSON
      }
    }

}
