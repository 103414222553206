import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StepsWelcomeComponent } from './steps-welcome/steps-welcome.component';
import { RoutingService } from '../../service/routing.service';
import { ConfigService } from '../../service/config.service';
import { steps } from './steps';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-main-steps',
  templateUrl: './main-steps.component.html',
  styleUrls: ['./main-steps.component.scss'],
})
export class MainStepsComponent {

  tutorial: string;
  step: number;
  istep: any;
  steps: any;
  link: string;
  params: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public routing: RoutingService,
    public config: ConfigService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.tutorial = params.tutorial;
      this.steps = steps;
      if (params.tutorial_steps) {
        const sortsteps = params.tutorial_steps.split(',');
        this.steps = this.steps.filter(step => sortsteps.includes(step.myid));
        const newsteps = [];
        sortsteps.forEach(step => newsteps.push(this.steps.filter(stepo => stepo.myid === step)[0]));
        this.steps = newsteps;
      }

      if (params.tutorial) {
        this.steps.forEach((step, i) => {
          if (step.myid === params.tutorial) {
            this.istep = step;
            this.step = i;
            return false;
          }
        });
      }

    });

    if (this.config.appConfig.setup.useStorage) {
      if (localStorage.getItem('firstlogin') !== 'true') {
        localStorage.setItem('firstlogin', 'true');
        this.dialog.open(StepsWelcomeComponent, { disableClose: false, data: {} });
      }
    }

  }

  goStep(value) {
    if (this.params.tutorial_steps) {
      this.router.navigate([], {
        queryParams: {
          tutorial: this.steps[value].myid,
        },
        queryParamsHandling: 'merge',
        relativeTo: this.route,
      });
    } else {
      const path = this.steps[value].urlo.split('?')[0];
      const url = new URL('http://' + this.steps[value].urlo);
      this.router.navigate([path], {
        queryParams: {
          tutorial: this.steps[value].myid,
          type: url.searchParams.get('type'),
          id: url.searchParams.get('id'),
          news: url.searchParams.get('news'),
          time: url.searchParams.get('time'),
          period: url.searchParams.get('period')
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  exitSteps() {
    this.router.navigate([], { relativeTo: this.route, queryParams: { tutorial: null }, queryParamsHandling: null });
  }

}

