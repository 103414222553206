import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-portfolio-import',
  templateUrl: './portfolio-import.component.html',
  styleUrls: ['./portfolio-import.component.scss']
})
export class PortfolioImportComponent implements OnInit {

  import: string;
  ready: boolean;
  tooltip = 'Please paste your list of ISINs here, they can be copied directly from an excel column or you can paste them manually, separated by a space or break Eg. US0378331005 DE4778931005';
  isins: any;
  nones: any;
  namefolio: UntypedFormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    public auth: AuthService,
    public yukkapi: YukkApi,
    public snackBar: MatSnackBar,
  ) {
    this.namefolio = new UntypedFormControl('', [Validators.required, Validators.minLength(3)]);
    if (!this.data.create) {
      this.namefolio.setValue(this.auth.folio.name);
    }
  }

  ngOnInit() { }

  iParse() {
    const array = this.import.split(/\s+/).filter(Boolean);
    this.isins = [];
    this.nones = [];
    this.yukkapi.isinParse(array).subscribe(result => {
      for (const property in result) {
        const entity = result[property];
        if (entity) {
          this.isins.push(entity);
        } else {
          this.nones.push(property);
        }
      }
      this.ready = true;
    });
  }

  // convert_id(entity) {
  //   let [entity_type, ...rest] = entity.split(".")
  //   return entity_type + ':' + rest.join(".")
  // }

  iSubmit() {
    if (this.data.create) {
      if (this.namefolio.valid) {
        if (this.auth.folios && ((this.auth.folios.length + 1) > this.auth.featureFlags.portfoliosLimit)) {
          this.snackBar.open('Number of portfolios is limited to ' + this.auth.featureFlags.portfoliosLimit + '.', 'OK', { duration: 5000 });
        } else {
          this.auth.newFolio(this.namefolio.value).subscribe(result => {
            if (this.isins && (this.isins.length > this.auth.featureFlags.entitiesPerPortfoliosLimit)) {
              this.snackBar.open('Number of entities per portfolio is limited to ' + this.auth.featureFlags.entitiesPerPortfoliosLimit + '.', 'OK', { duration: 5000 });
            } else {
              this.auth.addEntitiesToPortfolios(this.isins.map(item => item.compound_key), [result.id]).subscribe(result2 => {
                this.dialog.closeAll();
                this.router.navigate([], {
                  queryParams: {
                    id: result.id,
                    update: Math.random()
                  }, queryParamsHandling: 'merge'
                });
              });
            }
          });
        }
      }
    } else {
      if (this.isins && (this.isins.length > this.auth.featureFlags.entitiesPerPortfoliosLimit)) {
        this.snackBar.open('Number of entities per portfolio is limited to ' + this.auth.featureFlags.entitiesPerPortfoliosLimit + '.', 'OK', { duration: 5000 });
      } else {
        this.auth.addEntity(this.isins.map(item => item.compound_key)).subscribe(() => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              update: Math.random()
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        });
      }
    }
  }

}
