import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { forkJoin } from 'rxjs';
import { Chart } from 'angular-highcharts';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-homepage-insights',
  templateUrl: './homepage-insights.component.html',
  styleUrls: ['./homepage-insights.component.scss']
})
export class HomepageInsightsComponent {

  params: any;
  previousValue: any;
  portfolio: any;
  entities = [];
  events = [];
  noEventsArray = [];
  activeEvent: string;
  activeTag: string;
  loading: boolean;
  nodata: boolean;

  colorsPalette = [
    'rgb(228,26,28)',
    'rgb(55,126,184)',
    'rgb(77,175,74)',
    'rgb(152,78,163)',
    'rgb(255,127,0)',
    'rgb(255,255,51)',
    'rgb(166,86,40)',
    'rgb(247,129,191)',
    'rgb(153,153,153)',
    'rgb(255,234,216)',
    'rgb(126,0,47)',
    'rgb(202,186,95)',
    'rgb(134,122,0)',
    'rgb(237,166,147)',
    'rgb(129,103,106)',
    'rgb(199,91,113)',
    'rgb(175,137,90)',
    'rgb(160,21,0)',
    'rgb(255,111,97)',
    'rgb(188,0,72)',
    'rgb(255,232,141)',
    'rgb(255,20,106)',
    'rgb(197,192,170)',
    'rgb(145,67,76)',
    'rgb(187,136,143)',
    'rgb(205,144,0)',
    'rgb(121,116,78)',
    'rgb(255,200,0)',
    'rgb(219,87,3)',
    'rgb(255,175,89)',
    'rgb(202,114,87)',
    'rgb(172,111,1)',
    'rgb(255,200,207)',
    'rgb(186,63,56)',
    'rgb(162,152,62)'
  ];

  // highcharts related parameters
  options: any;
  chart: Chart;
  chartRef: any;
  series: any;
  theme: string;
  chartCategoriesTags: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    public config: ConfigService,
  ) {

    this.theme = this.config.appConfig.routing.theme;

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.activeEvent = decodeURI(params.eventid).split('|')[0];
      this.activeTag = decodeURI(params.tag).split('|')[0];
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        if (this.chartRef) {
          setTimeout(() => {
            this.chartRef.source.value.reflow();
          }, 500);
        }
      }
      if (params.id && routing.reFresh(params, this.previousValue, ['id', 'update', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'period', 'theme'])) {

        this.loading = true;
        this.nodata = false;

        this.series = [];

        this.portfolio = this.auth.folio;

        const requestList = [];
        const requestPortfolio = this.yukkApi.relatedEvents(params, 'sub_events');
        requestList.push(requestPortfolio);

        if (this.portfolio.contentTop) {
          this.portfolio.contentTop.forEach(item => {
            const type = item.split(':')[0];
            const id = item.split(':')[1];
            const request = this.yukkApi.relatedEvents(Object.assign({}, params, {
              type: type,
              id: id
            }), 'sub_events');
            requestList.push(request);
          });

          forkJoin(requestList).subscribe(resp => {

            this.events = resp.map(array => {
              const sortedArray = JSON.parse(JSON.stringify(array));
              sortedArray.sort((a, b) => {
                return b.count - a.count;
              });
              return sortedArray.slice(0, 5);
            });

            this.noEventsArray = [];
            this.events.forEach(array => {
              let noEvents = true;
              array.forEach(el => {
                if (el.count > 0) {
                  noEvents = false;
                }
              });
              this.noEventsArray.push(noEvents);
            });

            this.setSeries();
            this.getOptions();
            if (this.chartRef) {
              setTimeout(() => {
                this.chartRef.source.value.reflow();
              }, 1000);
            }

          }, error => {
            this.loading = false;
            this.nodata = true;
          });
        }

      }
      this.previousValue = params;
    });

  }

  setSeries() {

    const allEvents = [];
    this.events.forEach(array => {
      array.forEach(item => {
        if (item.count > 0) {
          allEvents.push(JSON.stringify({
            name: item.event.name,
            id: item.event.id,
            type: item.event.type
          }));
        }
      });
    });
    const uniqueEvents = [...new Set(allEvents)].map((item, index) => {
      return Object.assign({}, JSON.parse(item), {
        color: this.colorsPalette[index],
        isPresent: false
      });
    });
    let currentIndex = 0;
    const howMany = this.noEventsArray.filter(item => {
      return !item;
    }).length;
    this.events.forEach((array, index) => {
      if (!this.noEventsArray[index]) {
        array.reverse().forEach(item => {
          if (item.count > 0) {
            const data = Array(howMany).fill(0);
            data[currentIndex] = {
              y: item.count,
              pointWidth: (index === 0) ? 40 : 20,
              name: item.event.name
            };
            let color;
            let linked = false;
            uniqueEvents.forEach((event, eventIndex) => {
              if (event.id === item.event.id) {
                color = event.color;
                if (event.isPresent) {
                  linked = true;
                } else {
                  uniqueEvents[eventIndex].isPresent = true;
                }
              }
            });
            const currentSeries = {
              name: item.event.name,
              data: data,
              infoEvent: {
                name: item.event.name,
                id: item.event.id,
                type: item.event.type,
              },
              color: color,
              events: {
                click: event => {
                  const tag = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI('m:' + this.chartCategoriesTags[event.point.category]);
                  const type = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI(event.point.series.userOptions.infoEvent.type);
                  const id = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI(event.point.series.userOptions.infoEvent.id);
                  this.router.navigate([], {
                    queryParams: {
                      eventid: id,
                      eventype: type,
                      tag: tag
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: false
                  });
                }
              }
            };
            if (linked) {
              currentSeries['linkedTo'] = item.event.id;
            } else {
              currentSeries['id'] = item.event.id;
            }
            this.series.push(currentSeries);
          }
        });
        currentIndex += 1;
      }
    });

    /*this.series = uniqueEvents.map((el, elIndex) => {
      const name = el.name;
      const data = [];
      this.events.forEach((array, index) => {
        if (!this.noEventsArray[index]) {
          let eventCounts = 0;
          array.forEach(item => {
            if (item.event.name === el.name) {
              eventCounts = item.count;
            }
          });
          data.push({
            y: eventCounts,
            pointWidth: (index === 0) ? 40 : 20,
            name: (eventCounts > 0) ? name : ''
          });
        }
      });
      return {
        name: name,
        data: data,
        infoEvent: {
          name: el.name,
          id: el.id,
          type: el.type,
        },
        color: this.colorsPalette[uniqueEvents.length - elIndex - 1],
        events: {
          click: event => {

            const tag = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI('m:' + this.chartCategoriesTags[event.point.category]);
            const type = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI(event.point.series.userOptions.infoEvent.type);
            const id = ((this.activeTag === 'm:' + this.chartCategoriesTags[event.point.category]) && (this.activeEvent === event.point.series.userOptions.infoEvent.id)) ? null : encodeURI(event.point.series.userOptions.infoEvent.id);

            this.router.navigate([], {
              queryParams: {
                eventid: id,
                eventype: type,
                tag: tag
              },
              queryParamsHandling: 'merge',
              replaceUrl: false
            });
          }
        }
      };
    });*/

  }

  getOptions() {

    const chartCategories = [];
    this.chartCategoriesTags = {};
    if (!this.noEventsArray[0]) {
      chartCategories.push(this.portfolio.name + ' (' + (this.portfolio.sentiment.sentiment * 100).toFixed(0) + '%)');
    }
    this.portfolio.namesTop.forEach((item, index) => {
      if (!this.noEventsArray[index + 1]) {
        chartCategories.push(item + ' (' + (this.portfolio.sentimentTop[index] * 100).toFixed(0) + '%)');
        this.chartCategoriesTags[item + ' (' + (this.portfolio.sentimentTop[index] * 100).toFixed(0) + '%)'] = this.portfolio.contentTop[index];
      }
    });

    const fontWeight = (item) => {
      if ((item === chartCategories[0]) && !this.noEventsArray[0]) {
        return 'bold';
      } else {
        return 'normal';
      }
    };

    const fontSize = (item) => {
      if ((item === chartCategories[0]) && !this.noEventsArray[0]) {
        return '14px';
      } else {
        return '11px';
      }
    };

    this.options = {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        plotBorderWidth: 0,
        plotBorderColor: '#707073',
        zoomType: 'xy',
        panning: true,
        panKey: 'ctrl'
      },
      credits: { enabled: false },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            width: 18,
            height: 18,
            symbolX: 9,
            symbolY: 9,
            symbolSize: 10,
            symbolStrokeWidth: 2,
            theme: {
              fill: 'none',
              stroke: 'none'
            }
          }
        }
      },
      title: { text: undefined },
      xAxis: {
        categories: chartCategories,
        title: { text: undefined },
        labels: {
          style: {
            // color: '#A0A0A3'
          },
          formatter () {
            return `<span style="color: ${'#A0A0A3'}; font-weight: ${fontWeight(this.value)}; font-size: ${fontSize(this.value)};">${this.value}</span>`;
          }
        },
        gridLineWidth: 0,
        gridLineColor: '#707073',
        lineColor: '#707073',
        lineWidth: 0,
        minorGridLineColor: '#505053',
        tickColor: '#707073',
      },
      yAxis: {
        min: 0,
        title: { text: undefined },
        labels: {
          enabled: false,
          format: '{value} %',
          style: {
            color: '#A0A0A3'
          }
        },
        gridLineWidth: 0,
        gridLineColor: '#707073',
        lineColor: '#707073',
        lineWidth: 0,
        minorGridLineColor: '#505053',
        tickColor: '#707073',
      },
      legend: {
        reversed: false,
        enabled: false,
        maxHeight: 69,
        itemStyle: {
          color: ((localStorage.getItem('theme') === 'light') || (this.params.theme === 'light')) ? '#606063' : '#E0E0E3'
        },
        itemHoverStyle: {
          color: ((localStorage.getItem('theme') === 'light') || (this.params.theme === 'light')) ? '#606063' : '#E0E0E3'
        },
        itemHiddenStyle: {
          color: ((localStorage.getItem('theme') === 'light') || (this.params.theme === 'light')) ? '#E0E0E3' : '#606063'
        },
        navigation: {
          style: {
            color: ((localStorage.getItem('theme') === 'light') || (this.params.theme === 'light')) ? '#606063' : '#E0E0E3'
          }
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        headerFormat: '<small>{point.x}</small><br>'
      },
      plotOptions: {
        series: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
          point: {
            events: {
              mouseOver: function() {
                const mainSeries = this.series;
                if (mainSeries.linkedParent && mainSeries.linkedParent.linkedSeries) {
                  mainSeries.linkedParent.linkedSeries.forEach(function(series) {
                    if (series !== mainSeries) {
                      series.setState('');
                    }
                  });
                }
              }
            }
          }
        }
      },
      series: this.series
    };

    this.chart = new Chart(this.options);
    this.chartRef = this.chart.ref$;
    this.loading = false;

  }

}
