<div class="news-radar" *ngIf="!loading && !nodata" [class.widget]="routing.isWidget()">
  <app-news-title [titolo]="titolo" *ngIf="!routing.isWidget()"></app-news-title>
  <div class="contchart">
    <div class="radar-axis" [chart]="chart1"></div>
    <div class="chartcont">
      <div class="radar-chart" [chart]="chart"></div>
    </div>
  </div>
</div>
<div class="loading" *ngIf="loading">
  <mat-spinner [diameter]="50" *ngIf="!nodata"></mat-spinner>
</div>
<div class="nodata" *ngIf="nodata">NOT ENOUGH DATA</div>
