<div class="main-trending">
  <div class="trending-lhs" [class.mobile]="routing.isMobile()">
    <div class="trending-header">

      <div class="trending-header-info">
        <p class="trending-header-info-header">Trending Board</p>
        <p>Filter & sort to identify the top 100 from 30k listed companies.</p>
      </div>

      <div class="trending-header-content">

        <mat-form-field class="trending-country">
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="selectedCountry" name="country" (selectionChange)="onSelectionChange('country')">
            <mat-select-trigger>
              {{(selectedCountry === 'all') ? selectedCountry : selectedCountry.toUpperCase()}}
            </mat-select-trigger>
            <mat-option *ngFor="let country of countries" [value]="country.value">
              {{(country.value === 'all') ? (country.value) : (country.value.toUpperCase() + ' - ' + country.label)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-index">
          <mat-label>Index</mat-label>
          <mat-select [(ngModel)]="selectedIndex" name="index" (selectionChange)="onSelectionChange('index')">
            <mat-option *ngFor="let index of indices" [value]="index">
              {{index}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-industry">
          <mat-label>Industry</mat-label>
          <mat-select [(ngModel)]="selectedIndustry" name="industry" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let industry of industries" [value]="industry.value">
              {{industry.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="trending-events">
          <mat-label>Events</mat-label>
          <mat-select [(ngModel)]="selectedEvents" multiple (openedChange)="onSelectionChange('event', $event)">
            <ng-container *ngFor="let superevent of events | orderBy:'entity.name'">
              <mat-optgroup class="middleevent" *ngFor="let event of superevent['children'] | orderBy:'entity.name'" [label]="superevent['entity']['name'] + ' - ' + event['entity']['name']">
                <mat-option class="subevent" *ngFor="let subevent of event.children | orderBy:'entity.name'" [value]="subevent.entity" (click)="$event.stopPropagation()">
                  {{subevent.entity.name}}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="trending-time" *ngIf="selectedEvents && (selectedEvents.length > 0)">
          <mat-label>Timeframe</mat-label>
          <mat-select [(ngModel)]="selectedTimeHorizon" name="time" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let time of time_horizon" [value]="time.value">
              {{time.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>
    <div class="trending-cont">
      <app-trending-column [trending]="entitiesList" [searchTerm]="searchTerm" [loading]="loading" [nodata]="nodata"></app-trending-column>
    </div>
  </div>
  <!--<div class="trending-rhs" *ngIf="!routing.isMobile()">
    <app-news-newsfeed></app-news-newsfeed>
  </div>-->
</div>
