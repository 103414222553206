import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { RoutingService } from '../../../service/routing.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-help-viewer',
  templateUrl: './help-viewer.component.html',
  styleUrls: ['./help-viewer.component.scss']
})
export class HelpViewerComponent implements OnInit {

  pdfSrc: SafeResourceUrl;

  constructor(
    public routing: RoutingService,
    private sanitizer: DomSanitizer,
    public auth: AuthService,
  ) {
    this.pdfSrc =  this.sanitizer.bypassSecurityTrustResourceUrl('https://yl-markcom.s3.eu-central-1.amazonaws.com/guides/YUKKA+ScoreLab+Help+Guide.pdf');
  }

  ngOnInit(): void {
  }

}
