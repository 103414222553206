import { Pipe, PipeTransform } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe defines the score color
 */

@Pipe({ name: 'colorScore' })
export class ColorScorePipe implements PipeTransform {
  constructor(
    public routing: RoutingService,
  ) { }
  transform(value: any): string {
    if (value) {
      let colors = [];
      if (this.routing.theme === 'light') {
        colors = ['#cccc33', '#cc3366'];
      } else {
        colors = ['#cccc33', '#cc3366'];
      }
      const colorScale = d3.scaleLinear()
        .domain([0, 10])
        .range(colors);
      return colorScale(value);
    } else {
      return 'rgb(120,120,120)';
    }
  }
}
