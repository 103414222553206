import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-homepage-main',
  templateUrl: './homepage-main.component.html',
  styleUrls: ['./homepage-main.component.scss']
})
export class HomepageMainComponent {

  params: any;
  previousValue: any;
  selectedPortfolio: any;
  defaultPortfolio: any;
  portfolios = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
  ) {
    this.route.queryParams.subscribe(params => {
      if (this.auth.customDefaultPortfolio) {
        this.defaultPortfolio = this.auth.customDefaultPortfolio;
      } else {
        this.defaultPortfolio = '';
      }
      this.params = params;
      this.portfolios = this.auth.folios;
      this.selectedPortfolio = this.auth.folio.uid;
      this.selectedPortfolio = {
        value: this.auth.folio.uid,
        name: this.auth.folio.name
      };
      if (params.id && routing.reFresh(params, this.previousValue, ['id', 'update', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks'])) {
        this.yukkApi.portfolio(params, this.auth.folio.content).subscribe(result => {
          const sortedEntities = result.treemap.children;
          sortedEntities.sort((a, b) => {
            return a.sentiment - b.sentiment;
          });
          const entityArray = sortedEntities.map(item => {
            return item.entity.compound_key;
          });
          const namesArray = sortedEntities.map(item => {
            return item.entity.name;
          });
          const sentimentArray = sortedEntities.map(item => {
            return item.sentiment;
          });
          this.auth.folio.contentTop = entityArray.slice(0, 6);
          this.auth.folio.namesTop = namesArray.slice(0, 6);
          this.auth.folio.sentimentTop = sentimentArray.slice(0, 6);
        });
      }
      this.previousValue = params;
    });
  }

  openPortfolioBoard() {
    this.router.navigate(['/cockpit/news/portfolio'], {
      replaceUrl: false
    });
  }

  onSelectionChange(uid) {
    this.portfolios.map(folio => {
      if (folio.uid === uid) {
        this.auth.folio = folio;
        this.selectedPortfolio.name = folio.name;
      }
    });
    this.router.navigate([], {
      queryParams: {
        id: uid,
        type: null,
        tag: null,
        eventid: null,
        eventype: null,
        defaultsort: null
      },
      queryParamsHandling: 'merge',
    });
  }

  setDefaultPortfolio(id) {
    const portfolio = this.portfolios.filter(item => {
      return (item.uid === id);
    })[0];
    if (window.confirm('Set ' + portfolio.name + ' as a default portfolio?')) {
      this.defaultPortfolio = portfolio.uid;
      this.auth.customDefaultPortfolio = portfolio.uid;
      const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
        customDefaultPortfolio: portfolio.uid
      });
      this.auth.cockpitSettings = newCustomSettings;
      this.auth.setCustomSettings(newCustomSettings).subscribe();
    }
  }

  resetDefaultPortfolio() {
    if (window.confirm('Reset default filter?')) {
      this.auth.customDefaultPortfolio = '';
      delete this.auth.cockpitSettings['customDefaultPortfolio'];
      const newCustomSettings = Object.assign({}, this.auth.cockpitSettings);
      this.auth.setCustomSettings(newCustomSettings).subscribe();
      this.defaultPortfolio = '';
    }
  }

}
