import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe define the color of the trend inside the chartpmap component
 */
@Pipe({ name: 'colorTrend' })
export class ColorTrendPipe implements PipeTransform {
  constructor(
    public routing: RoutingService,
  ) { }
  transform(value: any): string {
    const ivalue = Math.round(value * 100) / 100;
    if (ivalue < 0) { value = 0; }
    if (ivalue === 0) { value = .5; }
    if (ivalue > 0) { value = 1; }
    let colors = [];
    if (this.routing.theme === 'light') {
      colors = ['#a71717', '#f6d500', '#009e00'];
    } else {
      colors = ['#dd001a', '#f6d500', '#3dac00'];
    }
    const colorScale = d3.scaleLinear()
      .domain([0, .5, 1])
      .range(colors);
    return colorScale(value);
  }
}
