import { Component, OnInit } from '@angular/core';

/**
* This component is used only for the router to trigger the guard before load the rest
*/

@Component({
  selector: 'app-news-outlet',
  templateUrl: './news-outlet.component.html',
  styleUrls: ['./news-outlet.component.scss']
})
export class NewsOutletComponent implements OnInit {

  /**
  * Constructor
  */

  constructor() { }

  /**
  * ngOnInit
  */

  ngOnInit() {
  }

}
