<div class="help-main">
    <div mat-dialog-title>
        <div class="help-title">
            <mat-tab-group (selectedTabChange)="onSelect($event)">
                <mat-tab label="Tutorial"></mat-tab>
                <mat-tab label="Contact Us"></mat-tab>
            </mat-tab-group>
        </div>
        <mat-icon [mat-dialog-close]="true">close</mat-icon>
    </div>
    <mat-dialog-content *ngIf="selectedTab === 0">
        <div class="help-header" *ngIf="currentHelpData && currentHelpData.header">
            <span>{{currentHelpData.header}}</span>
            <div class="help-video" style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe [src]="safeSrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="content-container" *ngIf="currentHelpData && (currentHelpData.content.length > 0)">
            <mat-list>
                <mat-list-item *ngFor="let item of currentHelpData.content">{{'&#8226; ' + item}}</mat-list-item>
            </mat-list>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="selectedTab === 0" [class.help-empty]="!currentHelpData">
        <button mat-icon-button (click)="openManual()">
            <mat-icon svgIcon="book-open-variant"></mat-icon>
        </button>
        <div class="help-actions-info" (click)="openManual()">
            <span class="first">Help Manual</span>
            <span class="second">Our comprehensive Guide on using YUKKA Lab</span>
        </div>
    </mat-dialog-actions>
    <mat-dialog-content *ngIf="selectedTab === 1">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Title</mat-label>
            <textarea matInput rows="1" [(ngModel)]="feedbackTitle" cdkTextareaAutosize></textarea>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Message</mat-label>
            <textarea matInput rows="8" [(ngModel)]="feedbackMessage" cdkTextareaAutosize></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="selectedTab === 1">
        <button mat-stroked-button (click)="sendFeedback()">SUBMIT</button>
    </mat-dialog-actions>
</div>
